<template>
  <div class="app" id="app">
    <van-config-provider :theme="formatTheme">
      <router-view />
    </van-config-provider>

    <GlobalNotice />
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { computed,onMounted, onUnmounted } from 'vue'
import { LightVars, DarkVars } from './config/CssVar';
import { onLogin } from "@/utils/loginFunc";
import { webSocketInit } from "@/utils/imHandle";
import { getGameListCode,getUnLoginGameList } from "@/utils/handleGameList";
import { getCountry } from "@/api/user";
import { getConfig,saveConfig,base64DecodeUnicode,isLogin } from '@/utils/function';
import { useRoute, useRouter } from 'vue-router';
import GlobalNotice from '@/components/Notice/Notice.vue';
import { getJsUrl,handleChannel,uploadTrackSDK,getTrackSDK } from '@/utils/function';
import { apkInit } from '@/utils/reportToAndroid';



const router = useRouter();
const store = useStore();

// Vant格式化主题值
const formatTheme = computed(() => {
  return store.state.setting.isDark ? 'dark' : ''
});

onMounted(() => {
  // 设置主题
  const html = document.documentElement;
  const vars = store.state.setting.isDark ? DarkVars : LightVars;
  Object.entries(vars).forEach(([ key, value ]) => {
    html.style.setProperty(key,value)
  });

  // 监听页面显示隐藏
  document.addEventListener('visibilitychange', onHandleVisibility);

  if(window.performance.navigation.type === 1) {  // 页面刷新进入
    console.log('页面刷新进入');
  }else{  // 页面首次进入
    console.log('页面首次进入');
    if(!localStorage.getItem('first_launch')){
      // OKSPIN首次启动
      window._okTag.push({
          "eid":"EVENT_KEY_INAPP_EVENT_1",
          "data":{
              "content_id":"first_launch"
          }
      });
      localStorage.setItem('first_launch','1');
    };
    // 处理渠道信息
    handleChannel();
    // 上传追踪SDK信息
    setTimeout(()=>{
      uploadTrackSDK();
    },2000);
  };

  // apk上报初始化
  apkInit();
  // 重置玩游戏地址
  store.commit('game/setPlayGameUrl');
  // 判断是否在apk环境
  onIsApk();
  // 首次或刷新指定进入HOME
  onRedirectHome();



  // 获取当前IP国家 获取游戏列表
  if(!getConfig()){
    getCountry().then((res:any)=>{
      saveConfig(res.data.data.country);
      if(isLogin()){
        onLogin();
        getGameListCode();
      }else{
        getUnLoginGameList();
      }
    });
  }else{
    if(isLogin()){
      onLogin();
      getGameListCode();
    }else{
      getUnLoginGameList();
    }
  };
  
});

onUnmounted(() => {
  document.removeEventListener('visibilitychange', onHandleVisibility);
});



// 页面显示隐藏操作
const onHandleVisibility = () => {
  if (document.visibilityState === 'hidden') {
    console.log('页面隐藏');
  }else{
    console.log('页面显示');
    webSocketInit();
  }
}

// 首次或刷新指定进入HOME
const onRedirectHome = () =>{
  let querys = {};
  if(getJsUrl(location.href)){
    querys = getJsUrl(location.href);
  };

  router.push({
    name: 'home',
    query: {
      ...querys
    }
  });
}

// 判断是否在apk环境
const onIsApk = () => {
    let viewer:any = window.navigator.userAgent.toLowerCase();	
    if(viewer.match(/webview7win/i) != 'webview7win'){
      store.commit('user/setIsShowDownApk',true); // 不是在apk环境就显示下载apk条
    }
}

</script>

<style lang="less" scoped>
.app {
  max-width: 750px;
  height: 100%;
  background: #201F3D !important;
}
</style>