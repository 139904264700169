import { getConfig,getLoginInfo,getZoneInfo,jsonToArrayBuffer } from "@/utils/function";
import { getImei } from "@/utils/function";
import md5 from 'md5';



var gameid:any,zoneid:any,uid:any,platkey:any,unigame_plat_key:any,gatewayurlws:any,lobbyid:any,appKey:any,country:any,areaService:any;



var IMWS:any = null;   // WebSocket实例



// Webstocket创建连接
function createWS(wsurl:string){
    IMWS = new WebSocket(wsurl);
}

// Webstocket发送消息
function socketSend(message:any){
    IMWS.send(message);
}

// Webstocket关闭连接
function socketClose(){
    if(!IMWS){
        return;
    }
    IMWS.close();
    IMWS = null;
}



// ------------------------------------------------------------------------------



// 获取基本信息
function getInfo() {
    var { gameid:mgameid,zoneid:mzoneid,lobbyid:mlobbyid,appKey:mappKey,country:mcountry,areaService:marreaservice } = getConfig();
    var { uid:muid,platkey:mplatkey,unigame_plat_key:m_unigame_plat_key } = getLoginInfo();
    var { gatewayurlws:mgatewayurlws } = getZoneInfo();

    gameid = mgameid;
    zoneid = mzoneid;
    lobbyid = mlobbyid;
    appKey = mappKey;
    country = mcountry;
    areaService = marreaservice;
    uid = muid;
    platkey = mplatkey;
    unigame_plat_key = m_unigame_plat_key;
    gatewayurlws = mgatewayurlws;
}

// 获取用户详细信息
function IMGetUserInfo() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    getInfo();
    let time = Math.floor(new Date().getTime() / 1000);
    let loginData = {
        "gameid": gameid,
        "zoneid": zoneid,
        "accountid": Number(uid),
        "logintempid": 0,
        "timestamp": time,
        "tokenmd5": "",
        "compress": "flate",
        "version": 20160805,
        "url": gatewayurlws + "/json",
        "cmd_name": "Pmd.UserLoginTokenLoginUserPmd_C"
    };
    
    loginData.tokenmd5 = md5(uid.toString() + loginData.logintempid.toString() + time.toString() + (platkey ? platkey : unigame_plat_key));

    socketSend(JSON.stringify(loginData));
}

// 发送获取用户信息
function IMSendGetUserInfo(fid?:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    getInfo();
    let params = {
        "lobbyId": lobbyid,
        "imei": getImei(),
        "appKey": appKey,
        "country": country,
        "areaService": areaService,
        "sysLanguage": navigator.language,
        "fid": fid ? Number(fid) : undefined,
        "fkchannel": sessionStorage.getItem('m_channel') ? sessionStorage.getItem('m_channel') : undefined,
        "facebook_fbc": sessionStorage.getItem('fbc') ? sessionStorage.getItem('fbc') : undefined,
        "facebook_fbp": sessionStorage.getItem('fbp') ? sessionStorage.getItem('fbp') : undefined,
    };

    let data = {
        "do": 'Cmd.UserInfoSynLobbyCmd_C',
        "data": params
    };
    
    socketSend(jsonToArrayBuffer(data));

    if(fid){
       sessionStorage.removeItem('fid');
    };
}

// 主动拉取用户信息
function IMGetUserInfoActve(gameId?:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let params: any = {
        lobbyId: lobbyid,
        uid: uid,
        gameId: gameId ? gameId : undefined
    };

    let data = {
        "do": 'Cmd.UserInfoGetLobbyCmd_C',
        "data": params
    };

    socketSend(jsonToArrayBuffer(data));
}

// 清除userinfo里的充值记录
function IMClearRechargeList() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let params: any = {
        lobbyId: lobbyid,
        uid: uid,
        clearRechargeList: 1
    };

    let data = {
        "do": 'Cmd.UserInfoGetLobbyCmd_C',
        "data": params
    };

    socketSend(jsonToArrayBuffer(data));
}

// 发送心跳
function IMSendHeart(){
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    console.log('心跳');
    getInfo();
    let heart = {
        "requesttime": Math.floor(Date.now() / 1000),
        "mytime": Math.floor(Date.now() / 1000),
        "cmd_name": "Pmd.TickReturnNullUserPmd_CS"
    };
    
    socketSend(jsonToArrayBuffer(heart));
}

// 请求进入房间
function IMEnterRoom(gameid:any,level:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    getInfo();

    let demokReq = {
        lobbyId: lobbyid,
        level: level,
        gameId: gameid,
    };

    let data = {
        "do": 'Cmd.GetPracticeGameInfoRoomCmd_C',
        "data": demokReq
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取VIP信息
function IMGetVipInfo() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };
    let data = {
        "do": 'Cmd.OperateVIPCmd_CS',
        "data": {
            "opType": 1 // 1:请求vip信息; 2:请求vip信息 获取vip奖励
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取VIP奖励 - 获取VIP信息
function IMGetVipReward(level:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateVIPCmd_CS',
        "data": {
            "opType": 2, // 1:请求vip信息; 2:请求vip信息 获取vip奖励
            "vipData": {
                curLevel : level,
            }
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 更换用户名称
function IMChangeUserName(name:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.UserInfoModifyRequestLobyCmd_C',
        "data": {
            "nickName": name
        }
    };

    socketSend(jsonToArrayBuffer(data));
}

// 更换用户邮箱
function IMChangeUserEmail(email:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.SystemSettingsLobbyCmd_CS',
        "data": {
            "email": email
        }
    };

    socketSend(jsonToArrayBuffer(data));
}

// 获取邮件
function IMGetMail() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.GetListMailCmd_CS',
        "data": {}
    };

    socketSend(jsonToArrayBuffer(data));
}

// 标记邮件已读
function IMReadMail(arr_ids:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ReadMailCmd_CS',
        "data": {
            "ids": arr_ids
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取邮件
function IMGetMailReward(arr_ids:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.GetItemMailCmd_CS',
        "data": {
            "ids": arr_ids
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取活动数据
function IMGetInfoActivite() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ActivityOperationCmd_CS',
        "data": {
            "opType": 1
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取活动
function IMGetRechargeReward(activity_id:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ActivityOperationCmd_CS',
        "data": {
            "opType": 2,
            "id": activity_id
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取对应活动数据
function IMGetInfoActiviteById(activity_id:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ActivityOperationCmd_CS',
        "data": {
            "opType": 3,
            "id": activity_id
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取代理数据
function IMGetAgentInfo() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 1
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取代理邀请人数列表
function IMGetAgentInviteList() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 7,
            "begin": 1,
            "len": 50,
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取代理未充值下级列表
function IMGetAgentNoRechargeList() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 11,
            "begin": 1,
            "len": 50,
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取邀请阶梯奖励
function IMGetAgentInviteReward() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 6
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取邀请奖励
function IMGetInviteReward() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 5
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取佣金
function IMGetCommission() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 2
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取佣金领取周记录
function IMGetCommissionWeekRecord() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 8
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取佣金领取记录
function IMGetCommissionRecord() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperateInfinityPromotionDataCmd_CS',
        "data": {
            "opType": 10
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取充值数据
function IMGetRecharge() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.GetRechargePlatData_CS',
        "data": {}
    }

    socketSend(jsonToArrayBuffer(data));
}

// 请求充值
function IMRecharge(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Pmd.CreatePlatOrderRequestSdkPmd_C',
        "data": value
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取提现数据
function IMGetWithdraw() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperationDrawCashLobbyCmd_CS',
        "data": {
            "opType": 3
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 绑定银行卡
function IMBindBankCard(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperationDrawCashLobbyCmd_CS',
        "data": {
            "opType": 2,
            "cardList": [value]
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 发起提现
function IMWithdraw(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Pmd.RequestDrawcashSdkPmd_CS',
        "data": value
    }

    socketSend(jsonToArrayBuffer(data));
}

// 换绑银行卡 验证密码
function IMChangeBankCardVerifyPassword(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperationDrawCashLobbyCmd_CS',
        "data": {
            "opType": 7,
            "password": value
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 忘记提现密码 发送验证码
function IMForgetWithdrawPassword(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    getInfo();

    let data = {
        "do": 'Cmd.GetBindAccountIdentifyCodeLobbyCmd_C',
        "data": {
            "opType": 3,
            "phoneNumber": value,
            "platId": 131,
            "lobbyId": lobbyid,
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 验证验证码
function IMVerifyCode(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperationDrawCashLobbyCmd_CS',
        "data": {
            "opType": 8,
            "code": value
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取充值记录
function IMGetRechargeRewardList(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperationDrawCashLobbyCmd_CS',
        "data": {
            "opType": 5,
            "curPage": value,
            "perPage": 10
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取提现记录
function IMGetWithdrawRewardList(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.OperationDrawCashLobbyCmd_CS',
        "data": {
            "opType": 4,
            "curPage": value,
            "perPage": 10
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 绑定手机号 发送验证码
function IMRegisterBindPhoneNumber(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.GetBindAccountIdentifyCodeLobbyCmd_C',
        "data": {
            "opType": 1,
            "phoneNumber": value,
            "platId": 131,
            "lobbyId": lobbyid,
        }
    }

    console.log('发送验证码',data);

    socketSend(jsonToArrayBuffer(data));
}

// 账号注册
function IMRegister(value:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let { platAccount, passwd, code } = value;

    let data = {
        "do": 'Cmd.BindAccountLobbyCmd_C',
        "data": {
            "opType": 1,
            "platId": 131,
            "platAccount": platAccount,
            "passwd": passwd,
            "code": code,
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 开始每日签到
function IMSignIn(activity_id:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ActivityOperationCmd_CS',
        "data": {
            "opType": 3,
            "spined": 1,
            "id": activity_id
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 点击每日签到Menu，判断是打开弹窗还是进到活动页面
function IMIsSignIn(activity_id:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ActivityOperationCmd_CS',
        "data": {
            "opType": 3,
            "showLuckySignPanel": 1,
            "id": activity_id
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 领取每日签到免费金币
function IMGetSignInFreeReward(activity_id:any) {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.ActivityOperationCmd_CS',
        "data": {
            "opType": 2,
            "id": activity_id
        }
    }

    socketSend(jsonToArrayBuffer(data));
}

// 获取TG分享地址
function IMGetTGShareUrl() {
    if(!IMWS){
        console.log('通讯未连接');
        return;
    };

    let data = {
        "do": 'Cmd.TGPlatOperationCmd_CS',
        "data": {
            "opType": 1
        }
    }

    socketSend(jsonToArrayBuffer(data));
}



export {
    IMWS,
    createWS,
    socketSend,
    socketClose,

    IMGetUserInfo,
    IMSendGetUserInfo,
    IMGetUserInfoActve,
    IMClearRechargeList,
    IMSendHeart,
    IMEnterRoom,
    IMGetVipInfo,
    IMGetVipReward,
    IMChangeUserName,
    IMChangeUserEmail,
    IMGetMail,
    IMReadMail,
    IMGetMailReward,
    IMGetInfoActivite,
    IMGetRechargeReward,
    IMGetInfoActiviteById,
    IMGetAgentInfo,
    IMGetAgentInviteList,
    IMGetAgentNoRechargeList,
    IMGetAgentInviteReward,
    IMGetInviteReward,
    IMGetCommission,
    IMGetCommissionWeekRecord,
    IMGetCommissionRecord,
    IMGetRecharge,
    IMRecharge,
    IMGetWithdraw,
    IMBindBankCard,
    IMWithdraw,
    IMChangeBankCardVerifyPassword,
    IMForgetWithdrawPassword,
    IMVerifyCode,
    IMGetRechargeRewardList,
    IMGetWithdrawRewardList,
    IMRegisterBindPhoneNumber,
    IMRegister,
    IMSignIn,
    IMIsSignIn,
    IMGetSignInFreeReward,
    IMGetTGShareUrl
}