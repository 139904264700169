// @ts-nocheck



import { IMWS,createWS,socketClose,IMGetUserInfo,IMSendGetUserInfo,IMSendHeart,IMGetInfoActivite,IMClearRechargeList} from './im';
import { getLoginInfo,getZoneInfo,arrayBufferToJson,checkRegistrationTime } from "@/utils/function";
import { getJsUrl,getConfig } from './function';
import evbus from '@/utils/mitt';
import store from '@/store/index';
import { showLoadingToast,closeToast } from 'vant';
import router from '@/router/index';
import { clearUserInfo } from './loginFunc';
import i18n from '@/locale/index';
import { apkFirstRecharge,apkRecharge,apk24hourRecharge,apk2dayRecharge,apk3dayRecharge,apk7dayRecharge,apk14dayRecharge } from './reportToAndroid';



const { t } = i18n.global;
const mstore:any = store;

var gatewayurlws:any,platkey:any,unigame_plat_key:any;

var WS_URL:any = null;      // 通讯连接地址
var retry:any = null;       // 重连定时器
var retryCounter:any = 0;   // 当前重连计数器
var heartBeat:any = 0;      // 心跳检测连接计数
var heartTimer:any = null;  // 心跳检测连计时器
var route:any = {};         // URL数据

var reprotTimer:any = null; // 上报定时器



// 获取URL数据
if(location.href.indexOf('?') !== -1){
    route = getJsUrl(location.href);
}



// 获取基本信息
function getInfo() {
    var { platkey:mplatkey,unigame_plat_key:m_unigame_plat_key } = getLoginInfo();
    var { gatewayurlws:mgatewayurlws } = getZoneInfo();
    
    platkey = mplatkey;
    unigame_plat_key = m_unigame_plat_key;
    gatewayurlws = mgatewayurlws;
}



// webSocket初始化
function webSocketInit(){
    getInfo();

    if(!platkey && !unigame_plat_key){
        console.log('未登录');
        // 清理重连定时器
        retryCounter = 0;
        if(retry){
            clearInterval(retry);
            retry = null;
        };
        closeToast();
        return;
    };

    console.log('webSocket初始化');

    showLoadingToast({
        message: '',
        forbidClick: true,
        duration: 0
    });

    socketClose();
    
    // 通讯连接地址
    WS_URL = `${gatewayurlws}/json`;

    // 建立连接
    createWS(WS_URL);

    IMWS.binaryType = 'arraybuffer';

    // 连接、关闭、错误
    IMWS.onopen = function(e:any){
        console.log('通讯已连接',e);
        IMGetUserInfo();
        setTimeout(()=>{
            IMGetInfoActivite();
        },600);
        closeToast();

        // 清理重连定时器
        retryCounter = 0;
        if(retry){
            clearInterval(retry);
            retry = null;
        };

        // 清除心跳定时器
        if(heartTimer){
            clearInterval(heartTimer);
            heartTimer = null;
        };
    };

    IMWS.onclose = function(e:any){
        console.log('通讯已关闭',e);
        showLoadingToast({
            message: '',
            forbidClick: true,
            duration: 0
        });
        if(!retry){ RetryConnect(); }
    };

    IMWS.onerror = function(e:any){
        console.log('通信错误',e);
    };

    IMWS.onmessage = async function(e:any){
        let message = undefined;
        try {
            message = arrayBufferToJson(e.data);
        }catch (error){
            message = JSON.parse(e.data);
        }

        if(Array.isArray(message)){
            message.forEach((msg)=>{
                HandleMessage(msg);
                // 透传消息
                evbus.emit('websoket_message',msg);
            });
        }else{
            HandleMessage(message);
            // 透传消息
            evbus.emit('websoket_message',message);
        }
        
    };
};



// 开始心跳
function StartHeart(){
    heartTimer = setInterval(()=>{
        IMSendHeart();
        heartBeat++;
    },6000);
}

// 重连机制
function RetryConnect(){
    retry = setInterval(()=>{
        retryCounter++;
        if(heartTimer){ clearInterval(heartTimer);heartTimer = null; }
        webSocketInit();
        console.log(`第${retryCounter}次重连中`);
    },3000);
}

// 处理Soket收到的消息
function HandleMessage(message:any){
    console.log('Socket收到消息:',message);

    // 登录成功
    if(message.cmd_name === 'Pmd.UserLoginReturnOkLoginUserPmd_S'){
        // 获取用户信息
        IMSendGetUserInfo();
        // 开始心跳
        StartHeart();
    };

    // 被挤下线
    if(message.cmd_name === 'Pmd.ReconnectKickoutLoginUserPmd_S'){
        clearUserInfo();
        router.push({
            name: 'login'
        });
    };

    // 收到用户信息
    if(message.do === 'Cmd.UserInfoSynLobbyCmd_S'){
        // 中间件配置不存在，禁止登录
        if(message.data.resultCode === 1){
            // store.commit('user/resetUserInfo');
            // store.commit('game/setPlayGameUrl');
            // localStorage.clear();
        };

        mstore.commit('user/setUserInfo',message.data);
        
        // 如果有登录后有m_redirect定向，处理跳转
        if(message.data?.userInfo?.phonenumber && router.currentRoute.value.query.m_redirect){
            router.replace({
               name: router.currentRoute.value.query.m_redirect as string
            });
        }
    };

    // 收到用户信息 - 增量更新 --- 主动获取用户信息 和 修改用户昵称 的 时候收到
    if(message.do === 'Cmd.UserInfoGetLobbyCmd_S' || message.do === 'Cmd.UserInfoModifyReturnLobyCmd_S'){
        if(mstore.state.user.userinfo){
            const userinfo = JSON.parse(JSON.stringify(mstore.state.user.userinfo));
            const userInfo = JSON.parse(JSON.stringify(userinfo.userInfo));

            for (const key in message.data.userInfo) {
                if (message.data.userInfo.hasOwnProperty(key)) {
                    if (userInfo.hasOwnProperty(key) && typeof userInfo[key] === typeof message.data.userInfo[key]) {
                        userInfo[key] = message.data.userInfo[key];
                    }
                }
            }

            userinfo.userInfo = userInfo;
            mstore.commit('user/setUserInfo',userinfo);

            // 上报充值记录
            if(!reprotTimer){
                reprotTimer = setInterval(()=>{
                    if(userinfo?.userInfo?.rechargeList?.length > 0){
                        // 保存上报的充值记录
                        let order_list:any = sessionStorage.getItem('order_list') ? JSON.parse(sessionStorage.getItem('order_list') as string) : [];
                        if(userinfo?.rechargeTimes === 1){  // 首次充值上报
                            if(order_list.includes(userinfo.userInfo.rechargeList[0].orderId)){
                                return false;
                            };
                            // OKSPIN上报
                            window._okTag.push({
                                "eid":"EVENT_FIRST_DEPOSIT",
                                "data":{
                                    "currency":getConfig().coinUtil + '',
                                    "value":userinfo.userInfo.rechargeList[0].amountActual + '',
                                }
                            });
                            window._okTag.push({
                                "eid":"EVENT_PURCHASE",
                                "data":{
                                    "currency":getConfig().coinUtil + '',
                                    "value":userinfo.userInfo.rechargeList[0].amountActual + '',
                                }
                            });
                            apkFirstRecharge(userinfo.userInfo.rechargeList[0].amountActual);
                            order_list.push(userinfo.userInfo.rechargeList[0].orderId);
                        }else if(userinfo?.rechargeTimes > 1){  // 多次充值上报
                            userinfo.userInfo.rechargeList.forEach((item:any) => {
                                if(order_list.includes(item.orderId)){
                                    return false;
                                };
                                // OKSPIN上报
                                window._okTag.push({
                                    "eid":"EVENT_PURCHASE",
                                    "data":{
                                        "currency":getConfig().coinUtil + '',
                                        "value":item.amountActual + '',
                                    }
                                });
                                apkRecharge(item.amountActual);
                                order_list.push(item.orderId);
                            });
                        };
                        sessionStorage.setItem('order_list',JSON.stringify(order_list));
        

        
                        // 保存已上报的对应天数充值记录
                        let day_order_list:any = sessionStorage.getItem('day_order_list') ? JSON.parse(sessionStorage.getItem('day_order_list') as string) : [];
                        userinfo.userInfo.rechargeList.forEach((item:any) => {
                            if(day_order_list.includes(item.orderId)){
                                return false;
                            };
        
                            let result = checkRegistrationTime(item.ctime);
                            if(result.within24Hours){        // 24小时内充值上报
                                apk24hourRecharge(item.amountActual);
                            }else if(result.secondDay){      // 第2天内充值上报
                                apk2dayRecharge(item.amountActual);
                            }else if(result.thirdDay){       // 第3天内充值上报
                                apk3dayRecharge(item.amountActual);
                            }else if(result.seventhDay){     // 第7天内充值上报
                                apk7dayRecharge(item.amountActual);
                            }else if(result.fourteenthDay){  // 第14天内充值上报
                                apk14dayRecharge(item.amountActual);
                            };
        
                            day_order_list.push(item.orderId);
                        });
                        sessionStorage.setItem('day_order_list',JSON.stringify(day_order_list));
   
                        IMClearRechargeList();
                        clearInterval(reprotTimer);
                        reprotTimer = null;
                    }else{
                        sessionStorage.removeItem('order_list');
                        sessionStorage.removeItem('day_order_list');
                    }
                },6000);
            };
        }
    }

    // 收到修改邮箱信息
    if(message.do === 'Cmd.SystemSettingsLobbyCmd_CS'){
        const userinfo = JSON.parse(JSON.stringify(mstore.state.user.userinfo));
        userinfo.email = message.data.email;
        mstore.commit('user/setUserInfo',userinfo);
    }

    // 收到活动信息
    if(message.do === 'Cmd.ActivityOperationCmd_CS'){
        const activite_list = JSON.parse(JSON.stringify(mstore.state.user.activityinfo));
        const ls_activite_list = JSON.parse(JSON.stringify(message.data.list));

        // 首先更新A数组中与B数组id相同的元素
        const new_activite = activite_list.map((a:any) => {
            const b = ls_activite_list.find((b:any) => b.id === a.id);
            return b ? b : a;
        });
  
        // 找出B数组中那些没有出现在A数组中的元素
        const new_arr = ls_activite_list.filter((b:any) => !activite_list.some((a:any) => a.id === b.id));
  
        // 合并更新后的A数组和B数组中的新元素
        const m_activite_list = new_activite.concat(new_arr);

        mstore.commit('user/setActivityInfo',m_activite_list);
    }
}



export {
    WS_URL,
    webSocketInit
}