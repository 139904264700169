export default {
  'acting.代理': 'Affiliate',
  'acting.详情': 'Details',
  'acting.奖励': 'Reward',
  'acting.佣金': 'Commission',
  'acting.规则': 'Rules',
  
  'acting.generalboard.邀请朋友来获取': 'Invite friends to get',
  'acting.generalboard.人': 'People',
  'acting.generalboard.邀请奖励': 'Invitation Reward',
  'acting.generalboard.高达': 'Up to',
  'acting.generalboard.佣金奖励': 'Commission Reward',
  'acting.generalboard.描述': 'Get {amount} for each friend you invite, plus a up to 90% commission on their wagers. Enjoy consistent commissions, whether they win or lose. Start earning now!',
  'acting.generalboard.邀请链接': 'Invitation Link',
  'acting.generalboard.分享到社交平台': 'Share to Social Media',
  'acting.generalboard.拉新表现': 'Promotion Performance',
  'acting.generalboard.今日拉新': 'Promotions Today',
  'acting.generalboard.本周拉新': 'Promotions This Week',
  'acting.generalboard.直属拉新表现': 'Direct Performance',
  'acting.generalboard.非直属拉新表现': 'Indirect Performance',
  'acting.generalboard.下级详情': 'Subordinate Details',
  'acting.generalboard.下级总人数': 'Total Number of Subordinates',
  'acting.generalboard.直属下级人数': 'Number of Direct Subordinates',
  'acting.generalboard.非直属下级人数': 'Number of Indirect Subordinates',
  'acting.generalboard.总奖励': 'Total Rewards',
  'acting.generalboard.总佣金': 'Total Commissions',
  'acting.generalboard.分享描述': 'Become an agent, earn millions a month! Invitation reward {amount}, commission up to 90%!',
  'acting.generalboard.tip.复制成功': 'Copy Successful',
  'acting.generalboard.二维码标题': 'Start changing your life from now',
  'acting.generalboard.二维码描述': 'Recruit agents, join us, and get super rebates every day',
  'acting.generalboard.保存': 'Save',
  'acting.generalboard.如何获得拉新奖励': 'How to earn invitation rewards + commission?',
  'acting.generalboard.如何获得拉新奖励标题1': 'Share with your friends',
  'acting.generalboard.如何获得拉新奖励高亮文字1': 'Share',
  'acting.generalboard.如何获得拉新奖励描述1': 'Share the download link or QR code with your friends',
  'acting.generalboard.如何获得拉新奖励标题2': 'Friends Download and Register',
  'acting.generalboard.如何获得拉新奖励高亮文字2_1': 'Download',
  'acting.generalboard.如何获得拉新奖励高亮文字2_2': 'Register',
  'acting.generalboard.如何获得拉新奖励描述2': 'Invite friends to register for the app and become members',
  'acting.generalboard.如何获得拉新奖励标题3': 'Friends Place bets',
  'acting.generalboard.如何获得拉新奖励高亮文字3': 'Place bets',
  'acting.generalboard.如何获得拉新奖励描述3': 'Successfully bind subordinate users + earn invitation rewards',
  'acting.generalboard.如何获得拉新奖励标题4': 'Friends Recharge and Place bets',
  'acting.generalboard.如何获得拉新奖励高亮文字4_1': 'Recharge',
  'acting.generalboard.如何获得拉新奖励高亮文字4_2': 'Place bets',
  'acting.generalboard.如何获得拉新奖励描述4': 'Unlock invitation rewards + enjoy agent commissions',
  'acting.generalboard.保存成功': 'Successfully saved',

  'acting.reward.邀请里程碑': 'Invitation Milestone',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Reach the required number of invited friends to receive rewards',
  'acting.reward.邀请': 'Invite',
  'acting.reward.人': 'People',
  'acting.reward.领取': 'Claim',
  'acting.reward.直属下级人数': 'Number of Direct Subordinates',
  'acting.reward.邀请奖励': 'Invitation Reward',
  'acting.reward.直属下级绑定成功可获得': 'Earn rewards when direct subordinates are successfully bound',
  'acting.reward.今日邀请': 'Invitations Today',
  'acting.reward.总邀请': 'Total Invitations',
  'acting.reward.今日奖励': "Today Reward",
  'acting.reward.邀请总奖励': 'Total Invitation Rewards',
  'acting.reward.今日邀请人数': 'Number of Invitations Today',
  'acting.reward.邀请总人数': 'Total Number of Invitations',
  'acting.reward.用户ID': 'User ID',
  'acting.reward.昵称': 'Nickname',
  'acting.reward.奖励': 'Reward',
  'acting.reward.展示前50条信息': 'Displaying the first 50 entries',
  'acting.reward.暂无记录': 'No Records',
  'acting.reward.领取成功': 'Claim Successful',
  // ----------------------------------------------------------------
  'acting.reward.未充值下级': 'Unrecharged Subordinate',
  'acting.reward.解锁明细': 'Unlock Details',
  'acting.reward.拉新奖励领取规则': 'Invitation Reward Claim Rules',
  'acting.reward.充值金额': 'Recharge Amount',
  'acting.reward.解锁金额': 'Unlock Amount',
  'acting.reward.手机号': 'Phone Number',
  'acting.reward.拉新奖励': 'Invitation Reward',
  'acting.reward.充值': 'Recharge',
  'acting.reward.解锁': 'Unlock',
  'acting.reward.实际': 'Actual',
  'acting.reward.锁定奖励': 'Locked Reward',
  'acting.reward.可解锁奖励': 'Unlockable Reward',
  'acting.reward.可领取': 'Claimable',
  'acting.reward.最高解锁': 'Maximum Unlock',
  'acting.reward.解锁公式': 'How To Unlock',
  'acting.reward.解锁公式内容': 'Single Subordinate Recharge Amount * Unlock Rate',
  'acting.reward.下级充值总人数': 'Number of subordinates (recharge)',
  'acting.reward.当前速率': 'Current Rate',
  'acting.reward.邀请下级获取拉新奖励': 'Invite subordinate to get {symbol}{amount} Invitation Reward',
  'acting.reward.邀请新的代理绑定无需充值可直接解锁': 'Bind new players; no recharge needed to unlock.',
  'acting.reward.下级充值可解锁奖励': 'Subordinate recharge can unlock rewards',
  'acting.reward.下级充值人数越多解锁速率越高': 'The more subordinates recharge, the higher the unlocking speed',

  'acting.commission.本周佣金信息': 'This Week Commission',
  'acting.commission.下级总充值': 'Total Subordinate Recharges',
  'acting.commission.直属下级佣金': 'Direct Subordinate Commissions',
  'acting.commission.非直属下级佣金': 'Indirect Subordinate Commissions',
  'acting.commission.未领取佣金': 'Unclaimed Commissions',
  'acting.commission.领取佣金': 'Claim Commissions',
  'acting.commission.领取记录': 'Claim Records',
  'acting.commission.佣金总和': 'Total Commission',
  'acting.commission.领取时间': 'Claim Time',
  'acting.commission.直属佣金': 'Direct Commission',
  'acting.commission.非直属佣金': 'Indirect Commission',
  'acting.commission.总和': 'Total',
  'acting.commission.展示前50条信息': 'Displaying the first 50 entries',
  'acting.commission.暂无记录': 'No Records',
  'acting.commission.领取成功': 'Claim Successful',
  // ----------------------------------------------------------
  'acting.commission.领取': 'Claim',
  'acting.commission.最多展示前三个月的信息': 'Show up to Last Three Months',
  'acting.commission.佣金规则': 'Commission Rules',
  'acting.commission.上周下级充值流水': 'Last Week Wagers',
  'acting.commission.上周邀请人数': 'Last Week Invited',
  'acting.commission.佣金比例': 'Commission Ratio',
  'acting.commission.本周结佣比例依据上周下级表现计算': 'This Week’s Commission Ratio Based on Last Week’s Subordinate Performance',
  'acting.commission.本周详情': 'This Week Details',
  'acting.commission.本周下级充值流水': 'This Week Subordinate Recharge Wagers',
  'acting.commission.本周下级充值金流水贡献': 'This Week Subordinate Recharge Contributions',
  'acting.commission.拉新人数': 'Number of Invitees',
  'acting.commission.当前您享受最高佣金比例': 'Currently Enjoying the Highest Commission Rate {rate}%',
  'acting.commission.完成以上条件下周可享受': 'Fulfill Conditions, Next Week Enjoy {rate}% Commission Rate',
  'acting.commission.本周佣金比例': 'This Week Commission Rate',
  'acting.commission.上周拉新人数': 'Last Week Number of Invitees',
  'acting.commission.返佣比例': 'Return Commission Ratio',
  'acting.commission.本周直属下级佣金': 'This Week Direct Subordinate Commission',
  'acting.commission.本周非直属下级佣金': 'This Week Indirect Subordinate Commission',
  'acting.commission.之前未领取佣金': 'Previous Unclaimed Commissions',
  'acting.commission.本周佣金': 'This Week Commission',
  'acting.commission.周记录': 'Weekly Record',
  'acting.commission.时间': 'Time',
  'acting.commission.下级充值': 'Subordinate Recharge',
  'acting.commission.邀请人数': 'Invited Persons',
  'acting.commission.佣金': 'Commission',
}