//CryptoJS
(function () {
    window.CryptoJS = window.CryptoJS || (function (Math, undefined) {
        /**
         * CryptoJS namespace.
         */
        var C = {};

        /**
         * Library namespace.
         */
        var C_lib = C.lib = {};

        /**
         * Base object for prototypal inheritance.
         */
        var Base = C_lib.Base = (function () {
            function F() { }

            return {
                /**
                 * Creates a new object that inherits from this object.
                 *
                 * @param {Object} overrides Properties to copy into the new object.
                 *
                 * @return {Object} The new object.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var MyType = CryptoJS.lib.Base.extend({
                 *         field: 'value',
                 *
                 *         method: function () {
                 *         }
                 *     });
                 */
                extend: function (overrides) {
                    // Spawn
                    F.prototype = this;
                    var subtype = new F();

                    // Augment
                    if (overrides) {
                        subtype.mixIn(overrides);
                    }

                    // Create default initializer
                    if (!subtype.hasOwnProperty('init')) {
                        subtype.init = function () {
                            subtype.$super.init.apply(this, arguments);
                        };
                    }

                    // Initializer's prototype is the subtype object
                    subtype.init.prototype = subtype;

                    // Reference supertype
                    subtype.$super = this;

                    return subtype;
                },

                /**
                 * Extends this object and runs the init method.
                 * Arguments to create() will be passed to init().
                 *
                 * @return {Object} The new object.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var instance = MyType.create();
                 */
                create: function () {
                    var instance = this.extend();
                    instance.init.apply(instance, arguments);

                    return instance;
                },

                /**
                 * Initializes a newly created object.
                 * Override this method to add some logic when your objects are created.
                 *
                 * @example
                 *
                 *     var MyType = CryptoJS.lib.Base.extend({
                 *         init: function () {
                 *             // ...
                 *         }
                 *     });
                 */
                init: function () {
                },

                /**
                 * Copies properties into this object.
                 *
                 * @param {Object} properties The properties to mix in.
                 *
                 * @example
                 *
                 *     MyType.mixIn({
                 *         field: 'value'
                 *     });
                 */
                mixIn: function (properties) {
                    for (var propertyName in properties) {
                        if (properties.hasOwnProperty(propertyName)) {
                            this[propertyName] = properties[propertyName];
                        }
                    }

                    // IE won't copy toString using the loop above
                    if (properties.hasOwnProperty('toString')) {
                        this.toString = properties.toString;
                    }
                },

                /**
                 * Creates a copy of this object.
                 *
                 * @return {Object} The clone.
                 *
                 * @example
                 *
                 *     var clone = instance.clone();
                 */
                clone: function () {
                    return this.init.prototype.extend(this);
                }
            };
        }());

        /**
         * An array of 32-bit words.
         *
         * @property {Array} words The array of 32-bit words.
         * @property {number} sigBytes The number of significant bytes in this word array.
         */
        var WordArray = C_lib.WordArray = Base.extend({
            /**
             * Initializes a newly created word array.
             *
             * @param {Array} words (Optional) An array of 32-bit words.
             * @param {number} sigBytes (Optional) The number of significant bytes in the words.
             *
             * @example
             *
             *     var wordArray = CryptoJS.lib.WordArray.create();
             *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607]);
             *     var wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607], 6);
             */
            init: function (words, sigBytes) {
                words = this.words = words || [];

                if (sigBytes != undefined) {
                    this.sigBytes = sigBytes;
                } else {
                    this.sigBytes = words.length * 4;
                }
            },

            /**
             * Converts this word array to a string.
             *
             * @param {Encoder} encoder (Optional) The encoding strategy to use. Default: CryptoJS.enc.Hex
             *
             * @return {string} The stringified word array.
             *
             * @example
             *
             *     var string = wordArray + '';
             *     var string = wordArray.toString();
             *     var string = wordArray.toString(CryptoJS.enc.Utf8);
             */
            toString: function (encoder) {
                return (encoder || Hex).stringify(this);
            },

            /**
             * Concatenates a word array to this word array.
             *
             * @param {WordArray} wordArray The word array to append.
             *
             * @return {WordArray} This word array.
             *
             * @example
             *
             *     wordArray1.concat(wordArray2);
             */
            concat: function (wordArray) {
                // Shortcuts
                var thisWords = this.words;
                var thatWords = wordArray.words;
                var thisSigBytes = this.sigBytes;
                var thatSigBytes = wordArray.sigBytes;

                // Clamp excess bits
                this.clamp();

                // Concat
                if (thisSigBytes % 4) {
                    // Copy one byte at a time
                    for (var i = 0; i < thatSigBytes; i++) {
                        var thatByte = (thatWords[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
                        thisWords[(thisSigBytes + i) >>> 2] |= thatByte << (24 - ((thisSigBytes + i) % 4) * 8);
                    }
                } else if (thatWords.length > 0xffff) {
                    // Copy one word at a time
                    for (var i = 0; i < thatSigBytes; i += 4) {
                        thisWords[(thisSigBytes + i) >>> 2] = thatWords[i >>> 2];
                    }
                } else {
                    // Copy all words at once
                    thisWords.push.apply(thisWords, thatWords);
                }
                this.sigBytes += thatSigBytes;

                // Chainable
                return this;
            },

            /**
             * Removes insignificant bits.
             *
             * @example
             *
             *     wordArray.clamp();
             */
            clamp: function () {
                // Shortcuts
                var words = this.words;
                var sigBytes = this.sigBytes;

                // Clamp
                words[sigBytes >>> 2] &= 0xffffffff << (32 - (sigBytes % 4) * 8);
                words.length = Math.ceil(sigBytes / 4);
            },

            /**
             * Creates a copy of this word array.
             *
             * @return {WordArray} The clone.
             *
             * @example
             *
             *     var clone = wordArray.clone();
             */
            clone: function () {
                var clone = Base.clone.call(this);
                clone.words = this.words.slice(0);

                return clone;
            },

            /**
             * Creates a word array filled with random bytes.
             *
             * @param {number} nBytes The number of random bytes to generate.
             *
             * @return {WordArray} The random word array.
             *
             * @static
             *
             * @example
             *
             *     var wordArray = CryptoJS.lib.WordArray.random(16);
             */
            random: function (nBytes) {
                var words = [];
                for (var i = 0; i < nBytes; i += 4) {
                    words.push((Math.random() * 0x100000000) | 0);
                }

                return new WordArray.init(words, nBytes);
            }
        });

        /**
         * Encoder namespace.
         */
        var C_enc = C.enc = {};

        /**
         * Hex encoding strategy.
         */
        var Hex = C_enc.Hex = {
            /**
             * Converts a word array to a hex string.
             *
             * @param {WordArray} wordArray The word array.
             *
             * @return {string} The hex string.
             *
             * @static
             *
             * @example
             *
             *     var hexString = CryptoJS.enc.Hex.stringify(wordArray);
             */
            stringify: function (wordArray) {
                // Shortcuts
                var words = wordArray.words;
                var sigBytes = wordArray.sigBytes;

                // Convert
                var hexChars = [];
                for (var i = 0; i < sigBytes; i++) {
                    var bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
                    hexChars.push((bite >>> 4).toString(16));
                    hexChars.push((bite & 0x0f).toString(16));
                }

                return hexChars.join('');
            },

            /**
             * Converts a hex string to a word array.
             *
             * @param {string} hexStr The hex string.
             *
             * @return {WordArray} The word array.
             *
             * @static
             *
             * @example
             *
             *     var wordArray = CryptoJS.enc.Hex.parse(hexString);
             */
            parse: function (hexStr) {
                // Shortcut
                var hexStrLength = hexStr.length;

                // Convert
                var words = [];
                for (var i = 0; i < hexStrLength; i += 2) {
                    words[i >>> 3] |= parseInt(hexStr.substr(i, 2), 16) << (24 - (i % 8) * 4);
                }

                return new WordArray.init(words, hexStrLength / 2);
            }
        };

        /**
         * Latin1 encoding strategy.
         */
        var Latin1 = C_enc.Latin1 = {
            /**
             * Converts a word array to a Latin1 string.
             *
             * @param {WordArray} wordArray The word array.
             *
             * @return {string} The Latin1 string.
             *
             * @static
             *
             * @example
             *
             *     var latin1String = CryptoJS.enc.Latin1.stringify(wordArray);
             */
            stringify: function (wordArray) {
                // Shortcuts
                var words = wordArray.words;
                var sigBytes = wordArray.sigBytes;

                // Convert
                var latin1Chars = [];
                for (var i = 0; i < sigBytes; i++) {
                    var bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
                    latin1Chars.push(String.fromCharCode(bite));
                }

                return latin1Chars.join('');
            },

            /**
             * Converts a Latin1 string to a word array.
             *
             * @param {string} latin1Str The Latin1 string.
             *
             * @return {WordArray} The word array.
             *
             * @static
             *
             * @example
             *
             *     var wordArray = CryptoJS.enc.Latin1.parse(latin1String);
             */
            parse: function (latin1Str) {
                // Shortcut
                var latin1StrLength = latin1Str.length;

                // Convert
                var words = [];
                for (var i = 0; i < latin1StrLength; i++) {
                    words[i >>> 2] |= (latin1Str.charCodeAt(i) & 0xff) << (24 - (i % 4) * 8);
                }

                return new WordArray.init(words, latin1StrLength);
            }
        };

        /**
         * UTF-8 encoding strategy.
         */
        var Utf8 = C_enc.Utf8 = {
            /**
             * Converts a word array to a UTF-8 string.
             *
             * @param {WordArray} wordArray The word array.
             *
             * @return {string} The UTF-8 string.
             *
             * @static
             *
             * @example
             *
             *     var utf8String = CryptoJS.enc.Utf8.stringify(wordArray);
             */
            stringify: function (wordArray) {
                try {
                    return decodeURIComponent(escape(Latin1.stringify(wordArray)));
                } catch (e) {
                    throw new Error('Malformed UTF-8 data');
                }
            },

            /**
             * Converts a UTF-8 string to a word array.
             *
             * @param {string} utf8Str The UTF-8 string.
             *
             * @return {WordArray} The word array.
             *
             * @static
             *
             * @example
             *
             *     var wordArray = CryptoJS.enc.Utf8.parse(utf8String);
             */
            parse: function (utf8Str) {
                return Latin1.parse(unescape(encodeURIComponent(utf8Str)));
            }
        };

        /**
         * Abstract buffered block algorithm template.
         *
         * The property blockSize must be implemented in a concrete subtype.
         *
         * @property {number} _minBufferSize The number of blocks that should be kept unprocessed in the buffer. Default: 0
         */
        var BufferedBlockAlgorithm = C_lib.BufferedBlockAlgorithm = Base.extend({
            /**
             * Resets this block algorithm's data buffer to its initial state.
             *
             * @example
             *
             *     bufferedBlockAlgorithm.reset();
             */
            reset: function () {
                // Initial values
                this._data = new WordArray.init();
                this._nDataBytes = 0;
            },

            /**
             * Adds new data to this block algorithm's buffer.
             *
             * @param {WordArray|string} data The data to append. Strings are converted to a WordArray using UTF-8.
             *
             * @example
             *
             *     bufferedBlockAlgorithm._append('data');
             *     bufferedBlockAlgorithm._append(wordArray);
             */
            _append: function (data) {
                // Convert string to WordArray, else assume WordArray already
                if (typeof data == 'string') {
                    data = Utf8.parse(data);
                }

                // Append
                this._data.concat(data);
                this._nDataBytes += data.sigBytes;
            },

            /**
             * Processes available data blocks.
             *
             * This method invokes _doProcessBlock(offset), which must be implemented by a concrete subtype.
             *
             * @param {boolean} doFlush Whether all blocks and partial blocks should be processed.
             *
             * @return {WordArray} The processed data.
             *
             * @example
             *
             *     var processedData = bufferedBlockAlgorithm._process();
             *     var processedData = bufferedBlockAlgorithm._process(!!'flush');
             */
            _process: function (doFlush) {
                // Shortcuts
                var data = this._data;
                var dataWords = data.words;
                var dataSigBytes = data.sigBytes;
                var blockSize = this.blockSize;
                var blockSizeBytes = blockSize * 4;

                // Count blocks ready
                var nBlocksReady = dataSigBytes / blockSizeBytes;
                if (doFlush) {
                    // Round up to include partial blocks
                    nBlocksReady = Math.ceil(nBlocksReady);
                } else {
                    // Round down to include only full blocks,
                    // less the number of blocks that must remain in the buffer
                    nBlocksReady = Math.max((nBlocksReady | 0) - this._minBufferSize, 0);
                }

                // Count words ready
                var nWordsReady = nBlocksReady * blockSize;

                // Count bytes ready
                var nBytesReady = Math.min(nWordsReady * 4, dataSigBytes);

                // Process blocks
                if (nWordsReady) {
                    for (var offset = 0; offset < nWordsReady; offset += blockSize) {
                        // Perform concrete-algorithm logic
                        this._doProcessBlock(dataWords, offset);
                    }

                    // Remove processed words
                    var processedWords = dataWords.splice(0, nWordsReady);
                    data.sigBytes -= nBytesReady;
                }

                // Return processed words
                return new WordArray.init(processedWords, nBytesReady);
            },

            /**
             * Creates a copy of this object.
             *
             * @return {Object} The clone.
             *
             * @example
             *
             *     var clone = bufferedBlockAlgorithm.clone();
             */
            clone: function () {
                var clone = Base.clone.call(this);
                clone._data = this._data.clone();

                return clone;
            },

            _minBufferSize: 0
        });

        /**
         * Abstract hasher template.
         *
         * @property {number} blockSize The number of 32-bit words this hasher operates on. Default: 16 (512 bits)
         */
        var Hasher = C_lib.Hasher = BufferedBlockAlgorithm.extend({
            /**
             * Configuration options.
             */
            cfg: Base.extend(),

            /**
             * Initializes a newly created hasher.
             *
             * @param {Object} cfg (Optional) The configuration options to use for this hash computation.
             *
             * @example
             *
             *     var hasher = CryptoJS.algo.SHA256.create();
             */
            init: function (cfg) {
                // Apply config defaults
                this.cfg = this.cfg.extend(cfg);

                // Set initial values
                this.reset();
            },

            /**
             * Resets this hasher to its initial state.
             *
             * @example
             *
             *     hasher.reset();
             */
            reset: function () {
                // Reset data buffer
                BufferedBlockAlgorithm.reset.call(this);

                // Perform concrete-hasher logic
                this._doReset();
            },

            /**
             * Updates this hasher with a message.
             *
             * @param {WordArray|string} messageUpdate The message to append.
             *
             * @return {Hasher} This hasher.
             *
             * @example
             *
             *     hasher.update('message');
             *     hasher.update(wordArray);
             */
            update: function (messageUpdate) {
                // Append
                this._append(messageUpdate);

                // Update the hash
                this._process();

                // Chainable
                return this;
            },

            /**
             * Finalizes the hash computation.
             * Note that the finalize operation is effectively a destructive, read-once operation.
             *
             * @param {WordArray|string} messageUpdate (Optional) A final message update.
             *
             * @return {WordArray} The hash.
             *
             * @example
             *
             *     var hash = hasher.finalize();
             *     var hash = hasher.finalize('message');
             *     var hash = hasher.finalize(wordArray);
             */
            finalize: function (messageUpdate) {
                // Final message update
                if (messageUpdate) {
                    this._append(messageUpdate);
                }

                // Perform concrete-hasher logic
                var hash = this._doFinalize();

                return hash;
            },

            blockSize: 512 / 32,

            /**
             * Creates a shortcut function to a hasher's object interface.
             *
             * @param {Hasher} hasher The hasher to create a helper for.
             *
             * @return {Function} The shortcut function.
             *
             * @static
             *
             * @example
             *
             *     var SHA256 = CryptoJS.lib.Hasher._createHelper(CryptoJS.algo.SHA256);
             */
            _createHelper: function (hasher) {
                return function (message, cfg) {
                    return new hasher.init(cfg).finalize(message);
                };
            },

            /**
             * Creates a shortcut function to the HMAC's object interface.
             *
             * @param {Hasher} hasher The hasher to use in this HMAC helper.
             *
             * @return {Function} The shortcut function.
             *
             * @static
             *
             * @example
             *
             *     var HmacSHA256 = CryptoJS.lib.Hasher._createHmacHelper(CryptoJS.algo.SHA256);
             */
            _createHmacHelper: function (hasher) {
                return function (message, key) {
                    return new C_algo.HMAC.init(hasher, key).finalize(message);
                };
            }
        });

        /**
         * Algorithm namespace.
         */
        var C_algo = C.algo = {};

        return C;
    }(Math));

    (function (CryptoJS) {

        //lib-typedarrays
        (function () {
            // Check if typed arrays are supported
            if (typeof ArrayBuffer != 'function') {
                return;
            }

            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var WordArray = C_lib.WordArray;

            // Reference original init
            var superInit = WordArray.init;

            // Augment WordArray.init to handle typed arrays
            var subInit = WordArray.init = function (typedArray) {
                // Convert buffers to uint8
                if (typedArray instanceof ArrayBuffer) {
                    typedArray = new Uint8Array(typedArray);
                }

                // Convert other array views to uint8
                if (
                    typedArray instanceof Int8Array ||
                    typedArray instanceof Uint8ClampedArray ||
                    typedArray instanceof Int16Array ||
                    typedArray instanceof Uint16Array ||
                    typedArray instanceof Int32Array ||
                    typedArray instanceof Uint32Array ||
                    typedArray instanceof Float32Array ||
                    typedArray instanceof Float64Array
                ) {
                    typedArray = new Uint8Array(typedArray.buffer, typedArray.byteOffset, typedArray.byteLength);
                }

                // Handle Uint8Array
                if (typedArray instanceof Uint8Array) {
                    // Shortcut
                    var typedArrayByteLength = typedArray.byteLength;

                    // Extract bytes
                    var words = [];
                    for (var i = 0; i < typedArrayByteLength; i++) {
                        words[i >>> 2] |= typedArray[i] << (24 - (i % 4) * 8);
                    }

                    // Initialize this word array
                    superInit.call(this, words, typedArrayByteLength);
                } else {
                    // Else call normal init
                    superInit.apply(this, arguments);
                }
            };

            subInit.prototype = WordArray;
        }());

        //base64
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var WordArray = C_lib.WordArray;
            var C_enc = C.enc;

            /**
             * Base64 encoding strategy.
             */
            var Base64 = C_enc.Base64 = {
                /**
                 * Converts a word array to a Base64 string.
                 *
                 * @param {WordArray} wordArray The word array.
                 *
                 * @return {string} The Base64 string.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var base64String = CryptoJS.enc.Base64.stringify(wordArray);
                 */
                stringify: function (wordArray) {
                    // Shortcuts
                    var words = wordArray.words;
                    var sigBytes = wordArray.sigBytes;
                    var map = this._map;

                    // Clamp excess bits
                    wordArray.clamp();

                    // Convert
                    var base64Chars = [];
                    for (var i = 0; i < sigBytes; i += 3) {
                        var byte1 = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
                        var byte2 = (words[(i + 1) >>> 2] >>> (24 - ((i + 1) % 4) * 8)) & 0xff;
                        var byte3 = (words[(i + 2) >>> 2] >>> (24 - ((i + 2) % 4) * 8)) & 0xff;

                        var triplet = (byte1 << 16) | (byte2 << 8) | byte3;

                        for (var j = 0; (j < 4) && (i + j * 0.75 < sigBytes); j++) {
                            base64Chars.push(map.charAt((triplet >>> (6 * (3 - j))) & 0x3f));
                        }
                    }

                    // Add padding
                    var paddingChar = map.charAt(64);
                    if (paddingChar) {
                        while (base64Chars.length % 4) {
                            base64Chars.push(paddingChar);
                        }
                    }

                    return base64Chars.join('');
                },

                /**
                 * Converts a Base64 string to a word array.
                 *
                 * @param {string} base64Str The Base64 string.
                 *
                 * @return {WordArray} The word array.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var wordArray = CryptoJS.enc.Base64.parse(base64String);
                 */
                parse: function (base64Str) {
                    // Shortcuts
                    var base64StrLength = base64Str.length;
                    var map = this._map;

                    // Ignore padding
                    var paddingChar = map.charAt(64);
                    if (paddingChar) {
                        var paddingIndex = base64Str.indexOf(paddingChar);
                        if (paddingIndex != -1) {
                            base64StrLength = paddingIndex;
                        }
                    }

                    // Convert
                    var words = [];
                    var nBytes = 0;
                    for (var i = 0; i < base64StrLength; i++) {
                        if (i % 4) {
                            var bits1 = map.indexOf(base64Str.charAt(i - 1)) << ((i % 4) * 2);
                            var bits2 = map.indexOf(base64Str.charAt(i)) >>> (6 - (i % 4) * 2);
                            words[nBytes >>> 2] |= (bits1 | bits2) << (24 - (nBytes % 4) * 8);
                            nBytes++;
                        }
                    }

                    return WordArray.create(words, nBytes);
                },

                _map: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
            };
        }());

        //utf16
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var WordArray = C_lib.WordArray;
            var C_enc = C.enc;

            /**
             * UTF-16 BE encoding strategy.
             */
            var Utf16BE = C_enc.Utf16 = C_enc.Utf16BE = {
                /**
                 * Converts a word array to a UTF-16 BE string.
                 *
                 * @param {WordArray} wordArray The word array.
                 *
                 * @return {string} The UTF-16 BE string.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var utf16String = CryptoJS.enc.Utf16.stringify(wordArray);
                 */
                stringify: function (wordArray) {
                    // Shortcuts
                    var words = wordArray.words;
                    var sigBytes = wordArray.sigBytes;

                    // Convert
                    var utf16Chars = [];
                    for (var i = 0; i < sigBytes; i += 2) {
                        var codePoint = (words[i >>> 2] >>> (16 - (i % 4) * 8)) & 0xffff;
                        utf16Chars.push(String.fromCharCode(codePoint));
                    }

                    return utf16Chars.join('');
                },

                /**
                 * Converts a UTF-16 BE string to a word array.
                 *
                 * @param {string} utf16Str The UTF-16 BE string.
                 *
                 * @return {WordArray} The word array.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var wordArray = CryptoJS.enc.Utf16.parse(utf16String);
                 */
                parse: function (utf16Str) {
                    // Shortcut
                    var utf16StrLength = utf16Str.length;

                    // Convert
                    var words = [];
                    for (var i = 0; i < utf16StrLength; i++) {
                        words[i >>> 1] |= utf16Str.charCodeAt(i) << (16 - (i % 2) * 16);
                    }

                    return WordArray.create(words, utf16StrLength * 2);
                }
            };

            /**
             * UTF-16 LE encoding strategy.
             */
            C_enc.Utf16LE = {
                /**
                 * Converts a word array to a UTF-16 LE string.
                 *
                 * @param {WordArray} wordArray The word array.
                 *
                 * @return {string} The UTF-16 LE string.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var utf16Str = CryptoJS.enc.Utf16LE.stringify(wordArray);
                 */
                stringify: function (wordArray) {
                    // Shortcuts
                    var words = wordArray.words;
                    var sigBytes = wordArray.sigBytes;

                    // Convert
                    var utf16Chars = [];
                    for (var i = 0; i < sigBytes; i += 2) {
                        var codePoint = swapEndian((words[i >>> 2] >>> (16 - (i % 4) * 8)) & 0xffff);
                        utf16Chars.push(String.fromCharCode(codePoint));
                    }

                    return utf16Chars.join('');
                },

                /**
                 * Converts a UTF-16 LE string to a word array.
                 *
                 * @param {string} utf16Str The UTF-16 LE string.
                 *
                 * @return {WordArray} The word array.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var wordArray = CryptoJS.enc.Utf16LE.parse(utf16Str);
                 */
                parse: function (utf16Str) {
                    // Shortcut
                    var utf16StrLength = utf16Str.length;

                    // Convert
                    var words = [];
                    for (var i = 0; i < utf16StrLength; i++) {
                        words[i >>> 1] |= swapEndian(utf16Str.charCodeAt(i) << (16 - (i % 2) * 16));
                    }

                    return WordArray.create(words, utf16StrLength * 2);
                }
            };

            function swapEndian(word) {
                return ((word << 8) & 0xff00ff00) | ((word >>> 8) & 0x00ff00ff);
            }
        }());

        //x64-core
        (function (undefined) {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var Base = C_lib.Base;
            var X32WordArray = C_lib.WordArray;

            /**
             * x64 namespace.
             */
            var C_x64 = C.x64 = {};

            /**
             * A 64-bit word.
             */
            var X64Word = C_x64.Word = Base.extend({
                /**
                 * Initializes a newly created 64-bit word.
                 *
                 * @param {number} high The high 32 bits.
                 * @param {number} low The low 32 bits.
                 *
                 * @example
                 *
                 *     var x64Word = CryptoJS.x64.Word.create(0x00010203, 0x04050607);
                 */
                init: function (high, low) {
                    this.high = high;
                    this.low = low;
                }

                /**
                 * Bitwise NOTs this word.
                 *
                 * @return {X64Word} A new x64-Word object after negating.
                 *
                 * @example
                 *
                 *     var negated = x64Word.not();
                 */
                // not: function () {
                // var high = ~this.high;
                // var low = ~this.low;

                // return X64Word.create(high, low);
                // },

                /**
                 * Bitwise ANDs this word with the passed word.
                 *
                 * @param {X64Word} word The x64-Word to AND with this word.
                 *
                 * @return {X64Word} A new x64-Word object after ANDing.
                 *
                 * @example
                 *
                 *     var anded = x64Word.and(anotherX64Word);
                 */
                // and: function (word) {
                // var high = this.high & word.high;
                // var low = this.low & word.low;

                // return X64Word.create(high, low);
                // },

                /**
                 * Bitwise ORs this word with the passed word.
                 *
                 * @param {X64Word} word The x64-Word to OR with this word.
                 *
                 * @return {X64Word} A new x64-Word object after ORing.
                 *
                 * @example
                 *
                 *     var ored = x64Word.or(anotherX64Word);
                 */
                // or: function (word) {
                // var high = this.high | word.high;
                // var low = this.low | word.low;

                // return X64Word.create(high, low);
                // },

                /**
                 * Bitwise XORs this word with the passed word.
                 *
                 * @param {X64Word} word The x64-Word to XOR with this word.
                 *
                 * @return {X64Word} A new x64-Word object after XORing.
                 *
                 * @example
                 *
                 *     var xored = x64Word.xor(anotherX64Word);
                 */
                // xor: function (word) {
                // var high = this.high ^ word.high;
                // var low = this.low ^ word.low;

                // return X64Word.create(high, low);
                // },

                /**
                 * Shifts this word n bits to the left.
                 *
                 * @param {number} n The number of bits to shift.
                 *
                 * @return {X64Word} A new x64-Word object after shifting.
                 *
                 * @example
                 *
                 *     var shifted = x64Word.shiftL(25);
                 */
                // shiftL: function (n) {
                // if (n < 32) {
                // var high = (this.high << n) | (this.low >>> (32 - n));
                // var low = this.low << n;
                // } else {
                // var high = this.low << (n - 32);
                // var low = 0;
                // }

                // return X64Word.create(high, low);
                // },

                /**
                 * Shifts this word n bits to the right.
                 *
                 * @param {number} n The number of bits to shift.
                 *
                 * @return {X64Word} A new x64-Word object after shifting.
                 *
                 * @example
                 *
                 *     var shifted = x64Word.shiftR(7);
                 */
                // shiftR: function (n) {
                // if (n < 32) {
                // var low = (this.low >>> n) | (this.high << (32 - n));
                // var high = this.high >>> n;
                // } else {
                // var low = this.high >>> (n - 32);
                // var high = 0;
                // }

                // return X64Word.create(high, low);
                // },

                /**
                 * Rotates this word n bits to the left.
                 *
                 * @param {number} n The number of bits to rotate.
                 *
                 * @return {X64Word} A new x64-Word object after rotating.
                 *
                 * @example
                 *
                 *     var rotated = x64Word.rotL(25);
                 */
                // rotL: function (n) {
                // return this.shiftL(n).or(this.shiftR(64 - n));
                // },

                /**
                 * Rotates this word n bits to the right.
                 *
                 * @param {number} n The number of bits to rotate.
                 *
                 * @return {X64Word} A new x64-Word object after rotating.
                 *
                 * @example
                 *
                 *     var rotated = x64Word.rotR(7);
                 */
                // rotR: function (n) {
                // return this.shiftR(n).or(this.shiftL(64 - n));
                // },

                /**
                 * Adds this word with the passed word.
                 *
                 * @param {X64Word} word The x64-Word to add with this word.
                 *
                 * @return {X64Word} A new x64-Word object after adding.
                 *
                 * @example
                 *
                 *     var added = x64Word.add(anotherX64Word);
                 */
                // add: function (word) {
                // var low = (this.low + word.low) | 0;
                // var carry = (low >>> 0) < (this.low >>> 0) ? 1 : 0;
                // var high = (this.high + word.high + carry) | 0;

                // return X64Word.create(high, low);
                // }
            });

            /**
             * An array of 64-bit words.
             *
             * @property {Array} words The array of CryptoJS.x64.Word objects.
             * @property {number} sigBytes The number of significant bytes in this word array.
             */
            var X64WordArray = C_x64.WordArray = Base.extend({
                /**
                 * Initializes a newly created word array.
                 *
                 * @param {Array} words (Optional) An array of CryptoJS.x64.Word objects.
                 * @param {number} sigBytes (Optional) The number of significant bytes in the words.
                 *
                 * @example
                 *
                 *     var wordArray = CryptoJS.x64.WordArray.create();
                 *
                 *     var wordArray = CryptoJS.x64.WordArray.create([
                 *         CryptoJS.x64.Word.create(0x00010203, 0x04050607),
                 *         CryptoJS.x64.Word.create(0x18191a1b, 0x1c1d1e1f)
                 *     ]);
                 *
                 *     var wordArray = CryptoJS.x64.WordArray.create([
                 *         CryptoJS.x64.Word.create(0x00010203, 0x04050607),
                 *         CryptoJS.x64.Word.create(0x18191a1b, 0x1c1d1e1f)
                 *     ], 10);
                 */
                init: function (words, sigBytes) {
                    words = this.words = words || [];

                    if (sigBytes != undefined) {
                        this.sigBytes = sigBytes;
                    } else {
                        this.sigBytes = words.length * 8;
                    }
                },

                /**
                 * Converts this 64-bit word array to a 32-bit word array.
                 *
                 * @return {CryptoJS.lib.WordArray} This word array's data as a 32-bit word array.
                 *
                 * @example
                 *
                 *     var x32WordArray = x64WordArray.toX32();
                 */
                toX32: function () {
                    // Shortcuts
                    var x64Words = this.words;
                    var x64WordsLength = x64Words.length;

                    // Convert
                    var x32Words = [];
                    for (var i = 0; i < x64WordsLength; i++) {
                        var x64Word = x64Words[i];
                        x32Words.push(x64Word.high);
                        x32Words.push(x64Word.low);
                    }

                    return X32WordArray.create(x32Words, this.sigBytes);
                },

                /**
                 * Creates a copy of this word array.
                 *
                 * @return {X64WordArray} The clone.
                 *
                 * @example
                 *
                 *     var clone = x64WordArray.clone();
                 */
                clone: function () {
                    var clone = Base.clone.call(this);

                    // Clone "words" array
                    var words = clone.words = this.words.slice(0);

                    // Clone each X64Word object
                    var wordsLength = words.length;
                    for (var i = 0; i < wordsLength; i++) {
                        words[i] = words[i].clone();
                    }

                    return clone;
                }
            });
        }());

        //md5
        (function (Math) {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var WordArray = C_lib.WordArray;
            var Hasher = C_lib.Hasher;
            var C_algo = C.algo;

            // Constants table
            var T = [];

            // Compute constants
            (function () {
                for (var i = 0; i < 64; i++) {
                    T[i] = (Math.abs(Math.sin(i + 1)) * 0x100000000) | 0;
                }
            }());

            /**
             * MD5 hash algorithm.
             */
            var MD5 = C_algo.MD5 = Hasher.extend({
                _doReset: function () {
                    this._hash = new WordArray.init([
                        0x67452301, 0xefcdab89,
                        0x98badcfe, 0x10325476
                    ]);
                },

                _doProcessBlock: function (M, offset) {
                    // Swap endian
                    for (var i = 0; i < 16; i++) {
                        // Shortcuts
                        var offset_i = offset + i;
                        var M_offset_i = M[offset_i];

                        M[offset_i] = (
                            (((M_offset_i << 8) | (M_offset_i >>> 24)) & 0x00ff00ff) |
                            (((M_offset_i << 24) | (M_offset_i >>> 8)) & 0xff00ff00)
                        );
                    }

                    // Shortcuts
                    var H = this._hash.words;

                    var M_offset_0 = M[offset + 0];
                    var M_offset_1 = M[offset + 1];
                    var M_offset_2 = M[offset + 2];
                    var M_offset_3 = M[offset + 3];
                    var M_offset_4 = M[offset + 4];
                    var M_offset_5 = M[offset + 5];
                    var M_offset_6 = M[offset + 6];
                    var M_offset_7 = M[offset + 7];
                    var M_offset_8 = M[offset + 8];
                    var M_offset_9 = M[offset + 9];
                    var M_offset_10 = M[offset + 10];
                    var M_offset_11 = M[offset + 11];
                    var M_offset_12 = M[offset + 12];
                    var M_offset_13 = M[offset + 13];
                    var M_offset_14 = M[offset + 14];
                    var M_offset_15 = M[offset + 15];

                    // Working varialbes
                    var a = H[0];
                    var b = H[1];
                    var c = H[2];
                    var d = H[3];

                    // Computation
                    a = FF(a, b, c, d, M_offset_0, 7, T[0]);
                    d = FF(d, a, b, c, M_offset_1, 12, T[1]);
                    c = FF(c, d, a, b, M_offset_2, 17, T[2]);
                    b = FF(b, c, d, a, M_offset_3, 22, T[3]);
                    a = FF(a, b, c, d, M_offset_4, 7, T[4]);
                    d = FF(d, a, b, c, M_offset_5, 12, T[5]);
                    c = FF(c, d, a, b, M_offset_6, 17, T[6]);
                    b = FF(b, c, d, a, M_offset_7, 22, T[7]);
                    a = FF(a, b, c, d, M_offset_8, 7, T[8]);
                    d = FF(d, a, b, c, M_offset_9, 12, T[9]);
                    c = FF(c, d, a, b, M_offset_10, 17, T[10]);
                    b = FF(b, c, d, a, M_offset_11, 22, T[11]);
                    a = FF(a, b, c, d, M_offset_12, 7, T[12]);
                    d = FF(d, a, b, c, M_offset_13, 12, T[13]);
                    c = FF(c, d, a, b, M_offset_14, 17, T[14]);
                    b = FF(b, c, d, a, M_offset_15, 22, T[15]);

                    a = GG(a, b, c, d, M_offset_1, 5, T[16]);
                    d = GG(d, a, b, c, M_offset_6, 9, T[17]);
                    c = GG(c, d, a, b, M_offset_11, 14, T[18]);
                    b = GG(b, c, d, a, M_offset_0, 20, T[19]);
                    a = GG(a, b, c, d, M_offset_5, 5, T[20]);
                    d = GG(d, a, b, c, M_offset_10, 9, T[21]);
                    c = GG(c, d, a, b, M_offset_15, 14, T[22]);
                    b = GG(b, c, d, a, M_offset_4, 20, T[23]);
                    a = GG(a, b, c, d, M_offset_9, 5, T[24]);
                    d = GG(d, a, b, c, M_offset_14, 9, T[25]);
                    c = GG(c, d, a, b, M_offset_3, 14, T[26]);
                    b = GG(b, c, d, a, M_offset_8, 20, T[27]);
                    a = GG(a, b, c, d, M_offset_13, 5, T[28]);
                    d = GG(d, a, b, c, M_offset_2, 9, T[29]);
                    c = GG(c, d, a, b, M_offset_7, 14, T[30]);
                    b = GG(b, c, d, a, M_offset_12, 20, T[31]);

                    a = HH(a, b, c, d, M_offset_5, 4, T[32]);
                    d = HH(d, a, b, c, M_offset_8, 11, T[33]);
                    c = HH(c, d, a, b, M_offset_11, 16, T[34]);
                    b = HH(b, c, d, a, M_offset_14, 23, T[35]);
                    a = HH(a, b, c, d, M_offset_1, 4, T[36]);
                    d = HH(d, a, b, c, M_offset_4, 11, T[37]);
                    c = HH(c, d, a, b, M_offset_7, 16, T[38]);
                    b = HH(b, c, d, a, M_offset_10, 23, T[39]);
                    a = HH(a, b, c, d, M_offset_13, 4, T[40]);
                    d = HH(d, a, b, c, M_offset_0, 11, T[41]);
                    c = HH(c, d, a, b, M_offset_3, 16, T[42]);
                    b = HH(b, c, d, a, M_offset_6, 23, T[43]);
                    a = HH(a, b, c, d, M_offset_9, 4, T[44]);
                    d = HH(d, a, b, c, M_offset_12, 11, T[45]);
                    c = HH(c, d, a, b, M_offset_15, 16, T[46]);
                    b = HH(b, c, d, a, M_offset_2, 23, T[47]);

                    a = II(a, b, c, d, M_offset_0, 6, T[48]);
                    d = II(d, a, b, c, M_offset_7, 10, T[49]);
                    c = II(c, d, a, b, M_offset_14, 15, T[50]);
                    b = II(b, c, d, a, M_offset_5, 21, T[51]);
                    a = II(a, b, c, d, M_offset_12, 6, T[52]);
                    d = II(d, a, b, c, M_offset_3, 10, T[53]);
                    c = II(c, d, a, b, M_offset_10, 15, T[54]);
                    b = II(b, c, d, a, M_offset_1, 21, T[55]);
                    a = II(a, b, c, d, M_offset_8, 6, T[56]);
                    d = II(d, a, b, c, M_offset_15, 10, T[57]);
                    c = II(c, d, a, b, M_offset_6, 15, T[58]);
                    b = II(b, c, d, a, M_offset_13, 21, T[59]);
                    a = II(a, b, c, d, M_offset_4, 6, T[60]);
                    d = II(d, a, b, c, M_offset_11, 10, T[61]);
                    c = II(c, d, a, b, M_offset_2, 15, T[62]);
                    b = II(b, c, d, a, M_offset_9, 21, T[63]);

                    // Intermediate hash value
                    H[0] = (H[0] + a) | 0;
                    H[1] = (H[1] + b) | 0;
                    H[2] = (H[2] + c) | 0;
                    H[3] = (H[3] + d) | 0;
                },

                _doFinalize: function () {
                    // Shortcuts
                    var data = this._data;
                    var dataWords = data.words;

                    var nBitsTotal = this._nDataBytes * 8;
                    var nBitsLeft = data.sigBytes * 8;

                    // Add padding
                    dataWords[nBitsLeft >>> 5] |= 0x80 << (24 - nBitsLeft % 32);

                    var nBitsTotalH = Math.floor(nBitsTotal / 0x100000000);
                    var nBitsTotalL = nBitsTotal;
                    dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 15] = (
                        (((nBitsTotalH << 8) | (nBitsTotalH >>> 24)) & 0x00ff00ff) |
                        (((nBitsTotalH << 24) | (nBitsTotalH >>> 8)) & 0xff00ff00)
                    );
                    dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 14] = (
                        (((nBitsTotalL << 8) | (nBitsTotalL >>> 24)) & 0x00ff00ff) |
                        (((nBitsTotalL << 24) | (nBitsTotalL >>> 8)) & 0xff00ff00)
                    );

                    data.sigBytes = (dataWords.length + 1) * 4;

                    // Hash final blocks
                    this._process();

                    // Shortcuts
                    var hash = this._hash;
                    var H = hash.words;

                    // Swap endian
                    for (var i = 0; i < 4; i++) {
                        // Shortcut
                        var H_i = H[i];

                        H[i] = (((H_i << 8) | (H_i >>> 24)) & 0x00ff00ff) |
                            (((H_i << 24) | (H_i >>> 8)) & 0xff00ff00);
                    }

                    // Return final computed hash
                    return hash;
                },

                clone: function () {
                    var clone = Hasher.clone.call(this);
                    clone._hash = this._hash.clone();

                    return clone;
                }
            });

            function FF(a, b, c, d, x, s, t) {
                var n = a + ((b & c) | (~b & d)) + x + t;
                return ((n << s) | (n >>> (32 - s))) + b;
            }

            function GG(a, b, c, d, x, s, t) {
                var n = a + ((b & d) | (c & ~d)) + x + t;
                return ((n << s) | (n >>> (32 - s))) + b;
            }

            function HH(a, b, c, d, x, s, t) {
                var n = a + (b ^ c ^ d) + x + t;
                return ((n << s) | (n >>> (32 - s))) + b;
            }

            function II(a, b, c, d, x, s, t) {
                var n = a + (c ^ (b | ~d)) + x + t;
                return ((n << s) | (n >>> (32 - s))) + b;
            }

            /**
             * Shortcut function to the hasher's object interface.
             *
             * @param {WordArray|string} message The message to hash.
             *
             * @return {WordArray} The hash.
             *
             * @static
             *
             * @example
             *
             *     var hash = CryptoJS.MD5('message');
             *     var hash = CryptoJS.MD5(wordArray);
             */
            C.MD5 = Hasher._createHelper(MD5);

            /**
             * Shortcut function to the HMAC's object interface.
             *
             * @param {WordArray|string} message The message to hash.
             * @param {WordArray|string} key The secret key.
             *
             * @return {WordArray} The HMAC.
             *
             * @static
             *
             * @example
             *
             *     var hmac = CryptoJS.HmacMD5(message, key);
             */
            C.HmacMD5 = Hasher._createHmacHelper(MD5);
        }(Math));

        //evpkdf
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var Base = C_lib.Base;
            var WordArray = C_lib.WordArray;
            var C_algo = C.algo;
            var MD5 = C_algo.MD5;

            /**
             * This key derivation function is meant to conform with EVP_BytesToKey.
             * www.openssl.org/docs/crypto/EVP_BytesToKey.html
             */
            var EvpKDF = C_algo.EvpKDF = Base.extend({
                /**
                 * Configuration options.
                 *
                 * @property {number} keySize The key size in words to generate. Default: 4 (128 bits)
                 * @property {Hasher} hasher The hash algorithm to use. Default: MD5
                 * @property {number} iterations The number of iterations to perform. Default: 1
                 */
                cfg: Base.extend({
                    keySize: 128 / 32,
                    hasher: MD5,
                    iterations: 1
                }),

                /**
                 * Initializes a newly created key derivation function.
                 *
                 * @param {Object} cfg (Optional) The configuration options to use for the derivation.
                 *
                 * @example
                 *
                 *     var kdf = CryptoJS.algo.EvpKDF.create();
                 *     var kdf = CryptoJS.algo.EvpKDF.create({ keySize: 8 });
                 *     var kdf = CryptoJS.algo.EvpKDF.create({ keySize: 8, iterations: 1000 });
                 */
                init: function (cfg) {
                    this.cfg = this.cfg.extend(cfg);
                },

                /**
                 * Derives a key from a password.
                 *
                 * @param {WordArray|string} password The password.
                 * @param {WordArray|string} salt A salt.
                 *
                 * @return {WordArray} The derived key.
                 *
                 * @example
                 *
                 *     var key = kdf.compute(password, salt);
                 */
                compute: function (password, salt) {
                    // Shortcut
                    var cfg = this.cfg;

                    // Init hasher
                    var hasher = cfg.hasher.create();

                    // Initial values
                    var derivedKey = WordArray.create();

                    // Shortcuts
                    var derivedKeyWords = derivedKey.words;
                    var keySize = cfg.keySize;
                    var iterations = cfg.iterations;

                    // Generate key
                    while (derivedKeyWords.length < keySize) {
                        if (block) {
                            hasher.update(block);
                        }
                        var block = hasher.update(password).finalize(salt);
                        hasher.reset();

                        // Iterations
                        for (var i = 1; i < iterations; i++) {
                            block = hasher.finalize(block);
                            hasher.reset();
                        }

                        derivedKey.concat(block);
                    }
                    derivedKey.sigBytes = keySize * 4;

                    return derivedKey;
                }
            });

            /**
             * Derives a key from a password.
             *
             * @param {WordArray|string} password The password.
             * @param {WordArray|string} salt A salt.
             * @param {Object} cfg (Optional) The configuration options to use for this computation.
             *
             * @return {WordArray} The derived key.
             *
             * @static
             *
             * @example
             *
             *     var key = CryptoJS.EvpKDF(password, salt);
             *     var key = CryptoJS.EvpKDF(password, salt, { keySize: 8 });
             *     var key = CryptoJS.EvpKDF(password, salt, { keySize: 8, iterations: 1000 });
             */
            C.EvpKDF = function (password, salt, cfg) {
                return EvpKDF.create(cfg).compute(password, salt);
            };
        }());

        //cipher-core
        (function (undefined) {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var Base = C_lib.Base;
            var WordArray = C_lib.WordArray;
            var BufferedBlockAlgorithm = C_lib.BufferedBlockAlgorithm;
            var C_enc = C.enc;
            var Utf8 = C_enc.Utf8;
            var Base64 = C_enc.Base64;
            var C_algo = C.algo;
            var EvpKDF = C_algo.EvpKDF;

            /**
             * Abstract base cipher template.
             *
             * @property {number} keySize This cipher's key size. Default: 4 (128 bits)
             * @property {number} ivSize This cipher's IV size. Default: 4 (128 bits)
             * @property {number} _ENC_XFORM_MODE A constant representing encryption mode.
             * @property {number} _DEC_XFORM_MODE A constant representing decryption mode.
             */
            var Cipher = C_lib.Cipher = BufferedBlockAlgorithm.extend({
                /**
                 * Configuration options.
                 *
                 * @property {WordArray} iv The IV to use for this operation.
                 */
                cfg: Base.extend(),

                /**
                 * Creates this cipher in encryption mode.
                 *
                 * @param {WordArray} key The key.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @return {Cipher} A cipher instance.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var cipher = CryptoJS.algo.AES.createEncryptor(keyWordArray, { iv: ivWordArray });
                 */
                createEncryptor: function (key, cfg) {
                    return this.create(this._ENC_XFORM_MODE, key, cfg);
                },

                /**
                 * Creates this cipher in decryption mode.
                 *
                 * @param {WordArray} key The key.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @return {Cipher} A cipher instance.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var cipher = CryptoJS.algo.AES.createDecryptor(keyWordArray, { iv: ivWordArray });
                 */
                createDecryptor: function (key, cfg) {
                    return this.create(this._DEC_XFORM_MODE, key, cfg);
                },

                /**
                 * Initializes a newly created cipher.
                 *
                 * @param {number} xformMode Either the encryption or decryption transormation mode constant.
                 * @param {WordArray} key The key.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @example
                 *
                 *     var cipher = CryptoJS.algo.AES.create(CryptoJS.algo.AES._ENC_XFORM_MODE, keyWordArray, { iv: ivWordArray });
                 */
                init: function (xformMode, key, cfg) {
                    // Apply config defaults
                    this.cfg = this.cfg.extend(cfg);

                    // Store transform mode and key
                    this._xformMode = xformMode;
                    this._key = key;

                    // Set initial values
                    this.reset();
                },

                /**
                 * Resets this cipher to its initial state.
                 *
                 * @example
                 *
                 *     cipher.reset();
                 */
                reset: function () {
                    // Reset data buffer
                    BufferedBlockAlgorithm.reset.call(this);

                    // Perform concrete-cipher logic
                    this._doReset();
                },

                /**
                 * Adds data to be encrypted or decrypted.
                 *
                 * @param {WordArray|string} dataUpdate The data to encrypt or decrypt.
                 *
                 * @return {WordArray} The data after processing.
                 *
                 * @example
                 *
                 *     var encrypted = cipher.process('data');
                 *     var encrypted = cipher.process(wordArray);
                 */
                process: function (dataUpdate) {
                    // Append
                    this._append(dataUpdate);

                    // Process available blocks
                    return this._process();
                },

                /**
                 * Finalizes the encryption or decryption process.
                 * Note that the finalize operation is effectively a destructive, read-once operation.
                 *
                 * @param {WordArray|string} dataUpdate The final data to encrypt or decrypt.
                 *
                 * @return {WordArray} The data after final processing.
                 *
                 * @example
                 *
                 *     var encrypted = cipher.finalize();
                 *     var encrypted = cipher.finalize('data');
                 *     var encrypted = cipher.finalize(wordArray);
                 */
                finalize: function (dataUpdate) {
                    // Final data update
                    if (dataUpdate) {
                        this._append(dataUpdate);
                    }

                    // Perform concrete-cipher logic
                    var finalProcessedData = this._doFinalize();

                    return finalProcessedData;
                },

                keySize: 128 / 32,

                ivSize: 128 / 32,

                _ENC_XFORM_MODE: 1,

                _DEC_XFORM_MODE: 2,

                /**
                 * Creates shortcut functions to a cipher's object interface.
                 *
                 * @param {Cipher} cipher The cipher to create a helper for.
                 *
                 * @return {Object} An object with encrypt and decrypt shortcut functions.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var AES = CryptoJS.lib.Cipher._createHelper(CryptoJS.algo.AES);
                 */
                _createHelper: (function () {
                    function selectCipherStrategy(key) {
                        if (typeof key == 'string') {
                            return PasswordBasedCipher;
                        } else {
                            return SerializableCipher;
                        }
                    }

                    return function (cipher) {
                        return {
                            encrypt: function (message, key, cfg) {
                                return selectCipherStrategy(key).encrypt(cipher, message, key, cfg);
                            },

                            decrypt: function (ciphertext, key, cfg) {
                                return selectCipherStrategy(key).decrypt(cipher, ciphertext, key, cfg);
                            }
                        };
                    };
                }())
            });

            /**
             * Abstract base stream cipher template.
             *
             * @property {number} blockSize The number of 32-bit words this cipher operates on. Default: 1 (32 bits)
             */
            var StreamCipher = C_lib.StreamCipher = Cipher.extend({
                _doFinalize: function () {
                    // Process partial blocks
                    var finalProcessedBlocks = this._process(!!'flush');

                    return finalProcessedBlocks;
                },

                blockSize: 1
            });

            /**
             * Mode namespace.
             */
            var C_mode = C.mode = {};

            /**
             * Abstract base block cipher mode template.
             */
            var BlockCipherMode = C_lib.BlockCipherMode = Base.extend({
                /**
                 * Creates this mode for encryption.
                 *
                 * @param {Cipher} cipher A block cipher instance.
                 * @param {Array} iv The IV words.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var mode = CryptoJS.mode.CBC.createEncryptor(cipher, iv.words);
                 */
                createEncryptor: function (cipher, iv) {
                    return this.Encryptor.create(cipher, iv);
                },

                /**
                 * Creates this mode for decryption.
                 *
                 * @param {Cipher} cipher A block cipher instance.
                 * @param {Array} iv The IV words.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var mode = CryptoJS.mode.CBC.createDecryptor(cipher, iv.words);
                 */
                createDecryptor: function (cipher, iv) {
                    return this.Decryptor.create(cipher, iv);
                },

                /**
                 * Initializes a newly created mode.
                 *
                 * @param {Cipher} cipher A block cipher instance.
                 * @param {Array} iv The IV words.
                 *
                 * @example
                 *
                 *     var mode = CryptoJS.mode.CBC.Encryptor.create(cipher, iv.words);
                 */
                init: function (cipher, iv) {
                    this._cipher = cipher;
                    this._iv = iv;
                }
            });

            /**
             * Cipher Block Chaining mode.
             */
            var CBC = C_mode.CBC = (function () {
                /**
                 * Abstract base CBC mode.
                 */
                var CBC = BlockCipherMode.extend();

                /**
                 * CBC encryptor.
                 */
                CBC.Encryptor = CBC.extend({
                    /**
                     * Processes the data block at offset.
                     *
                     * @param {Array} words The data words to operate on.
                     * @param {number} offset The offset where the block starts.
                     *
                     * @example
                     *
                     *     mode.processBlock(data.words, offset);
                     */
                    processBlock: function (words, offset) {
                        // Shortcuts
                        var cipher = this._cipher;
                        var blockSize = cipher.blockSize;

                        // XOR and encrypt
                        xorBlock.call(this, words, offset, blockSize);
                        cipher.encryptBlock(words, offset);

                        // Remember this block to use with next block
                        this._prevBlock = words.slice(offset, offset + blockSize);
                    }
                });

                /**
                 * CBC decryptor.
                 */
                CBC.Decryptor = CBC.extend({
                    /**
                     * Processes the data block at offset.
                     *
                     * @param {Array} words The data words to operate on.
                     * @param {number} offset The offset where the block starts.
                     *
                     * @example
                     *
                     *     mode.processBlock(data.words, offset);
                     */
                    processBlock: function (words, offset) {
                        // Shortcuts
                        var cipher = this._cipher;
                        var blockSize = cipher.blockSize;

                        // Remember this block to use with next block
                        var thisBlock = words.slice(offset, offset + blockSize);

                        // Decrypt and XOR
                        cipher.decryptBlock(words, offset);
                        xorBlock.call(this, words, offset, blockSize);

                        // This block becomes the previous block
                        this._prevBlock = thisBlock;
                    }
                });

                function xorBlock(words, offset, blockSize) {
                    // Shortcut
                    var iv = this._iv;

                    // Choose mixing block
                    if (iv) {
                        var block = iv;

                        // Remove IV for subsequent blocks
                        this._iv = undefined;
                    } else {
                        var block = this._prevBlock;
                    }

                    // XOR blocks
                    for (var i = 0; i < blockSize; i++) {
                        words[offset + i] ^= block[i];
                    }
                }

                return CBC;
            }());

            /**
             * Padding namespace.
             */
            var C_pad = C.pad = {};

            /**
             * PKCS #5/7 padding strategy.
             */
            var Pkcs7 = C_pad.Pkcs7 = {
                /**
                 * Pads data using the algorithm defined in PKCS #5/7.
                 *
                 * @param {WordArray} data The data to pad.
                 * @param {number} blockSize The multiple that the data should be padded to.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     CryptoJS.pad.Pkcs7.pad(wordArray, 4);
                 */
                pad: function (data, blockSize) {
                    // Shortcut
                    var blockSizeBytes = blockSize * 4;

                    // Count padding bytes
                    var nPaddingBytes = blockSizeBytes - data.sigBytes % blockSizeBytes;

                    // Create padding word
                    var paddingWord = (nPaddingBytes << 24) | (nPaddingBytes << 16) | (nPaddingBytes << 8) | nPaddingBytes;

                    // Create padding
                    var paddingWords = [];
                    for (var i = 0; i < nPaddingBytes; i += 4) {
                        paddingWords.push(paddingWord);
                    }
                    var padding = WordArray.create(paddingWords, nPaddingBytes);

                    // Add padding
                    data.concat(padding);
                },

                /**
                 * Unpads data that had been padded using the algorithm defined in PKCS #5/7.
                 *
                 * @param {WordArray} data The data to unpad.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     CryptoJS.pad.Pkcs7.unpad(wordArray);
                 */
                unpad: function (data) {
                    // Get number of padding bytes from last byte
                    var nPaddingBytes = data.words[(data.sigBytes - 1) >>> 2] & 0xff;

                    // Remove padding
                    data.sigBytes -= nPaddingBytes;
                }
            };

            /**
             * Abstract base block cipher template.
             *
             * @property {number} blockSize The number of 32-bit words this cipher operates on. Default: 4 (128 bits)
             */
            var BlockCipher = C_lib.BlockCipher = Cipher.extend({
                /**
                 * Configuration options.
                 *
                 * @property {Mode} mode The block mode to use. Default: CBC
                 * @property {Padding} padding The padding strategy to use. Default: Pkcs7
                 */
                cfg: Cipher.cfg.extend({
                    mode: CBC,
                    padding: Pkcs7
                }),

                reset: function () {
                    // Reset cipher
                    Cipher.reset.call(this);

                    // Shortcuts
                    var cfg = this.cfg;
                    var iv = cfg.iv;
                    var mode = cfg.mode;

                    // Reset block mode
                    if (this._xformMode == this._ENC_XFORM_MODE) {
                        var modeCreator = mode.createEncryptor;
                    } else /* if (this._xformMode == this._DEC_XFORM_MODE) */ {
                        var modeCreator = mode.createDecryptor;

                        // Keep at least one block in the buffer for unpadding
                        this._minBufferSize = 1;
                    }
                    this._mode = modeCreator.call(mode, this, iv && iv.words);
                },

                _doProcessBlock: function (words, offset) {
                    this._mode.processBlock(words, offset);
                },

                _doFinalize: function () {
                    // Shortcut
                    var padding = this.cfg.padding;

                    // Finalize
                    if (this._xformMode == this._ENC_XFORM_MODE) {
                        // Pad data
                        padding.pad(this._data, this.blockSize);

                        // Process final blocks
                        var finalProcessedBlocks = this._process(!!'flush');
                    } else /* if (this._xformMode == this._DEC_XFORM_MODE) */ {
                        // Process final blocks
                        var finalProcessedBlocks = this._process(!!'flush');

                        // Unpad data
                        padding.unpad(finalProcessedBlocks);
                    }

                    return finalProcessedBlocks;
                },

                blockSize: 128 / 32
            });

            /**
             * A collection of cipher parameters.
             *
             * @property {WordArray} ciphertext The raw ciphertext.
             * @property {WordArray} key The key to this ciphertext.
             * @property {WordArray} iv The IV used in the ciphering operation.
             * @property {WordArray} salt The salt used with a key derivation function.
             * @property {Cipher} algorithm The cipher algorithm.
             * @property {Mode} mode The block mode used in the ciphering operation.
             * @property {Padding} padding The padding scheme used in the ciphering operation.
             * @property {number} blockSize The block size of the cipher.
             * @property {Format} formatter The default formatting strategy to convert this cipher params object to a string.
             */
            var CipherParams = C_lib.CipherParams = Base.extend({
                /**
                 * Initializes a newly created cipher params object.
                 *
                 * @param {Object} cipherParams An object with any of the possible cipher parameters.
                 *
                 * @example
                 *
                 *     var cipherParams = CryptoJS.lib.CipherParams.create({
                 *         ciphertext: ciphertextWordArray,
                 *         key: keyWordArray,
                 *         iv: ivWordArray,
                 *         salt: saltWordArray,
                 *         algorithm: CryptoJS.algo.AES,
                 *         mode: CryptoJS.mode.CBC,
                 *         padding: CryptoJS.pad.PKCS7,
                 *         blockSize: 4,
                 *         formatter: CryptoJS.format.OpenSSL
                 *     });
                 */
                init: function (cipherParams) {
                    this.mixIn(cipherParams);
                },

                /**
                 * Converts this cipher params object to a string.
                 *
                 * @param {Format} formatter (Optional) The formatting strategy to use.
                 *
                 * @return {string} The stringified cipher params.
                 *
                 * @throws Error If neither the formatter nor the default formatter is set.
                 *
                 * @example
                 *
                 *     var string = cipherParams + '';
                 *     var string = cipherParams.toString();
                 *     var string = cipherParams.toString(CryptoJS.format.OpenSSL);
                 */
                toString: function (formatter) {
                    return (formatter || this.formatter).stringify(this);
                }
            });

            /**
             * Format namespace.
             */
            var C_format = C.format = {};

            /**
             * OpenSSL formatting strategy.
             */
            var OpenSSLFormatter = C_format.OpenSSL = {
                /**
                 * Converts a cipher params object to an OpenSSL-compatible string.
                 *
                 * @param {CipherParams} cipherParams The cipher params object.
                 *
                 * @return {string} The OpenSSL-compatible string.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var openSSLString = CryptoJS.format.OpenSSL.stringify(cipherParams);
                 */
                stringify: function (cipherParams) {
                    // Shortcuts
                    var ciphertext = cipherParams.ciphertext;
                    var salt = cipherParams.salt;

                    // Format
                    if (salt) {
                        var wordArray = WordArray.create([0x53616c74, 0x65645f5f]).concat(salt).concat(ciphertext);
                    } else {
                        var wordArray = ciphertext;
                    }

                    return wordArray.toString(Base64);
                },

                /**
                 * Converts an OpenSSL-compatible string to a cipher params object.
                 *
                 * @param {string} openSSLStr The OpenSSL-compatible string.
                 *
                 * @return {CipherParams} The cipher params object.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var cipherParams = CryptoJS.format.OpenSSL.parse(openSSLString);
                 */
                parse: function (openSSLStr) {
                    // Parse base64
                    var ciphertext = Base64.parse(openSSLStr);

                    // Shortcut
                    var ciphertextWords = ciphertext.words;

                    // Test for salt
                    if (ciphertextWords[0] == 0x53616c74 && ciphertextWords[1] == 0x65645f5f) {
                        // Extract salt
                        var salt = WordArray.create(ciphertextWords.slice(2, 4));

                        // Remove salt from ciphertext
                        ciphertextWords.splice(0, 4);
                        ciphertext.sigBytes -= 16;
                    }

                    return CipherParams.create({ ciphertext: ciphertext, salt: salt });
                }
            };

            /**
             * A cipher wrapper that returns ciphertext as a serializable cipher params object.
             */
            var SerializableCipher = C_lib.SerializableCipher = Base.extend({
                /**
                 * Configuration options.
                 *
                 * @property {Formatter} format The formatting strategy to convert cipher param objects to and from a string. Default: OpenSSL
                 */
                cfg: Base.extend({
                    format: OpenSSLFormatter
                }),

                /**
                 * Encrypts a message.
                 *
                 * @param {Cipher} cipher The cipher algorithm to use.
                 * @param {WordArray|string} message The message to encrypt.
                 * @param {WordArray} key The key.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @return {CipherParams} A cipher params object.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var ciphertextParams = CryptoJS.lib.SerializableCipher.encrypt(CryptoJS.algo.AES, message, key);
                 *     var ciphertextParams = CryptoJS.lib.SerializableCipher.encrypt(CryptoJS.algo.AES, message, key, { iv: iv });
                 *     var ciphertextParams = CryptoJS.lib.SerializableCipher.encrypt(CryptoJS.algo.AES, message, key, { iv: iv, format: CryptoJS.format.OpenSSL });
                 */
                encrypt: function (cipher, message, key, cfg) {
                    // Apply config defaults
                    cfg = this.cfg.extend(cfg);

                    // Encrypt
                    var encryptor = cipher.createEncryptor(key, cfg);
                    var ciphertext = encryptor.finalize(message);

                    // Shortcut
                    var cipherCfg = encryptor.cfg;

                    // Create and return serializable cipher params
                    return CipherParams.create({
                        ciphertext: ciphertext,
                        key: key,
                        iv: cipherCfg.iv,
                        algorithm: cipher,
                        mode: cipherCfg.mode,
                        padding: cipherCfg.padding,
                        blockSize: cipher.blockSize,
                        formatter: cfg.format
                    });
                },

                /**
                 * Decrypts serialized ciphertext.
                 *
                 * @param {Cipher} cipher The cipher algorithm to use.
                 * @param {CipherParams|string} ciphertext The ciphertext to decrypt.
                 * @param {WordArray} key The key.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @return {WordArray} The plaintext.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var plaintext = CryptoJS.lib.SerializableCipher.decrypt(CryptoJS.algo.AES, formattedCiphertext, key, { iv: iv, format: CryptoJS.format.OpenSSL });
                 *     var plaintext = CryptoJS.lib.SerializableCipher.decrypt(CryptoJS.algo.AES, ciphertextParams, key, { iv: iv, format: CryptoJS.format.OpenSSL });
                 */
                decrypt: function (cipher, ciphertext, key, cfg) {
                    // Apply config defaults
                    cfg = this.cfg.extend(cfg);

                    // Convert string to CipherParams
                    ciphertext = this._parse(ciphertext, cfg.format);

                    // Decrypt
                    var plaintext = cipher.createDecryptor(key, cfg).finalize(ciphertext.ciphertext);

                    return plaintext;
                },

                /**
                 * Converts serialized ciphertext to CipherParams,
                 * else assumed CipherParams already and returns ciphertext unchanged.
                 *
                 * @param {CipherParams|string} ciphertext The ciphertext.
                 * @param {Formatter} format The formatting strategy to use to parse serialized ciphertext.
                 *
                 * @return {CipherParams} The unserialized ciphertext.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var ciphertextParams = CryptoJS.lib.SerializableCipher._parse(ciphertextStringOrParams, format);
                 */
                _parse: function (ciphertext, format) {
                    if (typeof ciphertext == 'string') {
                        return format.parse(ciphertext, this);
                    } else {
                        return ciphertext;
                    }
                }
            });

            /**
             * Key derivation function namespace.
             */
            var C_kdf = C.kdf = {};

            /**
             * OpenSSL key derivation function.
             */
            var OpenSSLKdf = C_kdf.OpenSSL = {
                /**
                 * Derives a key and IV from a password.
                 *
                 * @param {string} password The password to derive from.
                 * @param {number} keySize The size in words of the key to generate.
                 * @param {number} ivSize The size in words of the IV to generate.
                 * @param {WordArray|string} salt (Optional) A 64-bit salt to use. If omitted, a salt will be generated randomly.
                 *
                 * @return {CipherParams} A cipher params object with the key, IV, and salt.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var derivedParams = CryptoJS.kdf.OpenSSL.execute('Password', 256/32, 128/32);
                 *     var derivedParams = CryptoJS.kdf.OpenSSL.execute('Password', 256/32, 128/32, 'saltsalt');
                 */
                execute: function (password, keySize, ivSize, salt) {
                    // Generate random salt
                    if (!salt) {
                        salt = WordArray.random(64 / 8);
                    }

                    // Derive key and IV
                    var key = EvpKDF.create({ keySize: keySize + ivSize }).compute(password, salt);

                    // Separate key and IV
                    var iv = WordArray.create(key.words.slice(keySize), ivSize * 4);
                    key.sigBytes = keySize * 4;

                    // Return params
                    return CipherParams.create({ key: key, iv: iv, salt: salt });
                }
            };

            /**
             * A serializable cipher wrapper that derives the key from a password,
             * and returns ciphertext as a serializable cipher params object.
             */
            var PasswordBasedCipher = C_lib.PasswordBasedCipher = SerializableCipher.extend({
                /**
                 * Configuration options.
                 *
                 * @property {KDF} kdf The key derivation function to use to generate a key and IV from a password. Default: OpenSSL
                 */
                cfg: SerializableCipher.cfg.extend({
                    kdf: OpenSSLKdf
                }),

                /**
                 * Encrypts a message using a password.
                 *
                 * @param {Cipher} cipher The cipher algorithm to use.
                 * @param {WordArray|string} message The message to encrypt.
                 * @param {string} password The password.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @return {CipherParams} A cipher params object.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var ciphertextParams = CryptoJS.lib.PasswordBasedCipher.encrypt(CryptoJS.algo.AES, message, 'password');
                 *     var ciphertextParams = CryptoJS.lib.PasswordBasedCipher.encrypt(CryptoJS.algo.AES, message, 'password', { format: CryptoJS.format.OpenSSL });
                 */
                encrypt: function (cipher, message, password, cfg) {
                    // Apply config defaults
                    cfg = this.cfg.extend(cfg);

                    // Derive key and other params
                    var derivedParams = cfg.kdf.execute(password, cipher.keySize, cipher.ivSize);

                    // Add IV to config
                    cfg.iv = derivedParams.iv;

                    // Encrypt
                    var ciphertext = SerializableCipher.encrypt.call(this, cipher, message, derivedParams.key, cfg);

                    // Mix in derived params
                    ciphertext.mixIn(derivedParams);

                    return ciphertext;
                },

                /**
                 * Decrypts serialized ciphertext using a password.
                 *
                 * @param {Cipher} cipher The cipher algorithm to use.
                 * @param {CipherParams|string} ciphertext The ciphertext to decrypt.
                 * @param {string} password The password.
                 * @param {Object} cfg (Optional) The configuration options to use for this operation.
                 *
                 * @return {WordArray} The plaintext.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var plaintext = CryptoJS.lib.PasswordBasedCipher.decrypt(CryptoJS.algo.AES, formattedCiphertext, 'password', { format: CryptoJS.format.OpenSSL });
                 *     var plaintext = CryptoJS.lib.PasswordBasedCipher.decrypt(CryptoJS.algo.AES, ciphertextParams, 'password', { format: CryptoJS.format.OpenSSL });
                 */
                decrypt: function (cipher, ciphertext, password, cfg) {
                    // Apply config defaults
                    cfg = this.cfg.extend(cfg);

                    // Convert string to CipherParams
                    ciphertext = this._parse(ciphertext, cfg.format);

                    // Derive key and other params
                    var derivedParams = cfg.kdf.execute(password, cipher.keySize, cipher.ivSize, ciphertext.salt);

                    // Add IV to config
                    cfg.iv = derivedParams.iv;

                    // Decrypt
                    var plaintext = SerializableCipher.decrypt.call(this, cipher, ciphertext, derivedParams.key, cfg);

                    return plaintext;
                }
            });
        }());

        //format-hex
        (function (undefined) {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var CipherParams = C_lib.CipherParams;
            var C_enc = C.enc;
            var Hex = C_enc.Hex;
            var C_format = C.format;

            var HexFormatter = C_format.Hex = {
                /**
                 * Converts the ciphertext of a cipher params object to a hexadecimally encoded string.
                 *
                 * @param {CipherParams} cipherParams The cipher params object.
                 *
                 * @return {string} The hexadecimally encoded string.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var hexString = CryptoJS.format.Hex.stringify(cipherParams);
                 */
                stringify: function (cipherParams) {
                    return cipherParams.ciphertext.toString(Hex);
                },

                /**
                 * Converts a hexadecimally encoded ciphertext string to a cipher params object.
                 *
                 * @param {string} input The hexadecimally encoded string.
                 *
                 * @return {CipherParams} The cipher params object.
                 *
                 * @static
                 *
                 * @example
                 *
                 *     var cipherParams = CryptoJS.format.Hex.parse(hexString);
                 */
                parse: function (input) {
                    var ciphertext = Hex.parse(input);
                    return CipherParams.create({ ciphertext: ciphertext });
                }
            };
        }());

        //pbkdf2
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var Base = C_lib.Base;
            var WordArray = C_lib.WordArray;
            var C_algo = C.algo;
            var SHA1 = C_algo.SHA1;
            var HMAC = C_algo.HMAC;

            /**
             * Password-Based Key Derivation Function 2 algorithm.
             */
            var PBKDF2 = C_algo.PBKDF2 = Base.extend({
                /**
                 * Configuration options.
                 *
                 * @property {number} keySize The key size in words to generate. Default: 4 (128 bits)
                 * @property {Hasher} hasher The hasher to use. Default: SHA1
                 * @property {number} iterations The number of iterations to perform. Default: 1
                 */
                cfg: Base.extend({
                    keySize: 128 / 32,
                    hasher: SHA1,
                    iterations: 1
                }),

                /**
                 * Initializes a newly created key derivation function.
                 *
                 * @param {Object} cfg (Optional) The configuration options to use for the derivation.
                 *
                 * @example
                 *
                 *     var kdf = CryptoJS.algo.PBKDF2.create();
                 *     var kdf = CryptoJS.algo.PBKDF2.create({ keySize: 8 });
                 *     var kdf = CryptoJS.algo.PBKDF2.create({ keySize: 8, iterations: 1000 });
                 */
                init: function (cfg) {
                    this.cfg = this.cfg.extend(cfg);
                },

                /**
                 * Computes the Password-Based Key Derivation Function 2.
                 *
                 * @param {WordArray|string} password The password.
                 * @param {WordArray|string} salt A salt.
                 *
                 * @return {WordArray} The derived key.
                 *
                 * @example
                 *
                 *     var key = kdf.compute(password, salt);
                 */
                compute: function (password, salt) {
                    // Shortcut
                    var cfg = this.cfg;

                    // Init HMAC
                    var hmac = HMAC.create(cfg.hasher, password);

                    // Initial values
                    var derivedKey = WordArray.create();
                    var blockIndex = WordArray.create([0x00000001]);

                    // Shortcuts
                    var derivedKeyWords = derivedKey.words;
                    var blockIndexWords = blockIndex.words;
                    var keySize = cfg.keySize;
                    var iterations = cfg.iterations;

                    // Generate key
                    while (derivedKeyWords.length < keySize) {
                        var block = hmac.update(salt).finalize(blockIndex);
                        hmac.reset();

                        // Shortcuts
                        var blockWords = block.words;
                        var blockWordsLength = blockWords.length;

                        // Iterations
                        var intermediate = block;
                        for (var i = 1; i < iterations; i++) {
                            intermediate = hmac.finalize(intermediate);
                            hmac.reset();

                            // Shortcut
                            var intermediateWords = intermediate.words;

                            // XOR intermediate with block
                            for (var j = 0; j < blockWordsLength; j++) {
                                blockWords[j] ^= intermediateWords[j];
                            }
                        }

                        derivedKey.concat(block);
                        blockIndexWords[0]++;
                    }
                    derivedKey.sigBytes = keySize * 4;

                    return derivedKey;
                }
            });

            /**
             * Computes the Password-Based Key Derivation Function 2.
             *
             * @param {WordArray|string} password The password.
             * @param {WordArray|string} salt A salt.
             * @param {Object} cfg (Optional) The configuration options to use for this computation.
             *
             * @return {WordArray} The derived key.
             *
             * @static
             *
             * @example
             *
             *     var key = CryptoJS.PBKDF2(password, salt);
             *     var key = CryptoJS.PBKDF2(password, salt, { keySize: 8 });
             *     var key = CryptoJS.PBKDF2(password, salt, { keySize: 8, iterations: 1000 });
             */
            C.PBKDF2 = function (password, salt, cfg) {
                return PBKDF2.create(cfg).compute(password, salt);
            };
        }());

        //rabbit
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var StreamCipher = C_lib.StreamCipher;
            var C_algo = C.algo;

            // Reusable objects
            var S = [];
            var C_ = [];
            var G = [];

            /**
             * Rabbit stream cipher algorithm
             */
            var Rabbit = C_algo.Rabbit = StreamCipher.extend({
                _doReset: function () {
                    // Shortcuts
                    var K = this._key.words;
                    var iv = this.cfg.iv;

                    // Swap endian
                    for (var i = 0; i < 4; i++) {
                        K[i] = (((K[i] << 8) | (K[i] >>> 24)) & 0x00ff00ff) |
                            (((K[i] << 24) | (K[i] >>> 8)) & 0xff00ff00);
                    }

                    // Generate initial state values
                    var X = this._X = [
                        K[0], (K[3] << 16) | (K[2] >>> 16),
                        K[1], (K[0] << 16) | (K[3] >>> 16),
                        K[2], (K[1] << 16) | (K[0] >>> 16),
                        K[3], (K[2] << 16) | (K[1] >>> 16)
                    ];

                    // Generate initial counter values
                    var C = this._C = [
                        (K[2] << 16) | (K[2] >>> 16), (K[0] & 0xffff0000) | (K[1] & 0x0000ffff),
                        (K[3] << 16) | (K[3] >>> 16), (K[1] & 0xffff0000) | (K[2] & 0x0000ffff),
                        (K[0] << 16) | (K[0] >>> 16), (K[2] & 0xffff0000) | (K[3] & 0x0000ffff),
                        (K[1] << 16) | (K[1] >>> 16), (K[3] & 0xffff0000) | (K[0] & 0x0000ffff)
                    ];

                    // Carry bit
                    this._b = 0;

                    // Iterate the system four times
                    for (var i = 0; i < 4; i++) {
                        nextState.call(this);
                    }

                    // Modify the counters
                    for (var i = 0; i < 8; i++) {
                        C[i] ^= X[(i + 4) & 7];
                    }

                    // IV setup
                    if (iv) {
                        // Shortcuts
                        var IV = iv.words;
                        var IV_0 = IV[0];
                        var IV_1 = IV[1];

                        // Generate four subvectors
                        var i0 = (((IV_0 << 8) | (IV_0 >>> 24)) & 0x00ff00ff) | (((IV_0 << 24) | (IV_0 >>> 8)) & 0xff00ff00);
                        var i2 = (((IV_1 << 8) | (IV_1 >>> 24)) & 0x00ff00ff) | (((IV_1 << 24) | (IV_1 >>> 8)) & 0xff00ff00);
                        var i1 = (i0 >>> 16) | (i2 & 0xffff0000);
                        var i3 = (i2 << 16) | (i0 & 0x0000ffff);

                        // Modify counter values
                        C[0] ^= i0;
                        C[1] ^= i1;
                        C[2] ^= i2;
                        C[3] ^= i3;
                        C[4] ^= i0;
                        C[5] ^= i1;
                        C[6] ^= i2;
                        C[7] ^= i3;

                        // Iterate the system four times
                        for (var i = 0; i < 4; i++) {
                            nextState.call(this);
                        }
                    }
                },

                _doProcessBlock: function (M, offset) {
                    // Shortcut
                    var X = this._X;

                    // Iterate the system
                    nextState.call(this);

                    // Generate four keystream words
                    S[0] = X[0] ^ (X[5] >>> 16) ^ (X[3] << 16);
                    S[1] = X[2] ^ (X[7] >>> 16) ^ (X[5] << 16);
                    S[2] = X[4] ^ (X[1] >>> 16) ^ (X[7] << 16);
                    S[3] = X[6] ^ (X[3] >>> 16) ^ (X[1] << 16);

                    for (var i = 0; i < 4; i++) {
                        // Swap endian
                        S[i] = (((S[i] << 8) | (S[i] >>> 24)) & 0x00ff00ff) |
                            (((S[i] << 24) | (S[i] >>> 8)) & 0xff00ff00);

                        // Encrypt
                        M[offset + i] ^= S[i];
                    }
                },

                blockSize: 128 / 32,

                ivSize: 64 / 32
            });

            function nextState() {
                // Shortcuts
                var X = this._X;
                var C = this._C;

                // Save old counter values
                for (var i = 0; i < 8; i++) {
                    C_[i] = C[i];
                }

                // Calculate new counter values
                C[0] = (C[0] + 0x4d34d34d + this._b) | 0;
                C[1] = (C[1] + 0xd34d34d3 + ((C[0] >>> 0) < (C_[0] >>> 0) ? 1 : 0)) | 0;
                C[2] = (C[2] + 0x34d34d34 + ((C[1] >>> 0) < (C_[1] >>> 0) ? 1 : 0)) | 0;
                C[3] = (C[3] + 0x4d34d34d + ((C[2] >>> 0) < (C_[2] >>> 0) ? 1 : 0)) | 0;
                C[4] = (C[4] + 0xd34d34d3 + ((C[3] >>> 0) < (C_[3] >>> 0) ? 1 : 0)) | 0;
                C[5] = (C[5] + 0x34d34d34 + ((C[4] >>> 0) < (C_[4] >>> 0) ? 1 : 0)) | 0;
                C[6] = (C[6] + 0x4d34d34d + ((C[5] >>> 0) < (C_[5] >>> 0) ? 1 : 0)) | 0;
                C[7] = (C[7] + 0xd34d34d3 + ((C[6] >>> 0) < (C_[6] >>> 0) ? 1 : 0)) | 0;
                this._b = (C[7] >>> 0) < (C_[7] >>> 0) ? 1 : 0;

                // Calculate the g-values
                for (var i = 0; i < 8; i++) {
                    var gx = X[i] + C[i];

                    // Construct high and low argument for squaring
                    var ga = gx & 0xffff;
                    var gb = gx >>> 16;

                    // Calculate high and low result of squaring
                    var gh = ((((ga * ga) >>> 17) + ga * gb) >>> 15) + gb * gb;
                    var gl = (((gx & 0xffff0000) * gx) | 0) + (((gx & 0x0000ffff) * gx) | 0);

                    // High XOR low
                    G[i] = gh ^ gl;
                }

                // Calculate new state values
                X[0] = (G[0] + ((G[7] << 16) | (G[7] >>> 16)) + ((G[6] << 16) | (G[6] >>> 16))) | 0;
                X[1] = (G[1] + ((G[0] << 8) | (G[0] >>> 24)) + G[7]) | 0;
                X[2] = (G[2] + ((G[1] << 16) | (G[1] >>> 16)) + ((G[0] << 16) | (G[0] >>> 16))) | 0;
                X[3] = (G[3] + ((G[2] << 8) | (G[2] >>> 24)) + G[1]) | 0;
                X[4] = (G[4] + ((G[3] << 16) | (G[3] >>> 16)) + ((G[2] << 16) | (G[2] >>> 16))) | 0;
                X[5] = (G[5] + ((G[4] << 8) | (G[4] >>> 24)) + G[3]) | 0;
                X[6] = (G[6] + ((G[5] << 16) | (G[5] >>> 16)) + ((G[4] << 16) | (G[4] >>> 16))) | 0;
                X[7] = (G[7] + ((G[6] << 8) | (G[6] >>> 24)) + G[5]) | 0;
            }

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.Rabbit.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.Rabbit.decrypt(ciphertext, key, cfg);
             */
            C.Rabbit = StreamCipher._createHelper(Rabbit);
        }());

        //rabbit-legacy
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var StreamCipher = C_lib.StreamCipher;
            var C_algo = C.algo;

            // Reusable objects
            var S = [];
            var C_ = [];
            var G = [];

            /**
             * Rabbit stream cipher algorithm.
             *
             * This is a legacy version that neglected to convert the key to little-endian.
             * This error doesn't affect the cipher's security,
             * but it does affect its compatibility with other implementations.
             */
            var RabbitLegacy = C_algo.RabbitLegacy = StreamCipher.extend({
                _doReset: function () {
                    // Shortcuts
                    var K = this._key.words;
                    var iv = this.cfg.iv;

                    // Generate initial state values
                    var X = this._X = [
                        K[0], (K[3] << 16) | (K[2] >>> 16),
                        K[1], (K[0] << 16) | (K[3] >>> 16),
                        K[2], (K[1] << 16) | (K[0] >>> 16),
                        K[3], (K[2] << 16) | (K[1] >>> 16)
                    ];

                    // Generate initial counter values
                    var C = this._C = [
                        (K[2] << 16) | (K[2] >>> 16), (K[0] & 0xffff0000) | (K[1] & 0x0000ffff),
                        (K[3] << 16) | (K[3] >>> 16), (K[1] & 0xffff0000) | (K[2] & 0x0000ffff),
                        (K[0] << 16) | (K[0] >>> 16), (K[2] & 0xffff0000) | (K[3] & 0x0000ffff),
                        (K[1] << 16) | (K[1] >>> 16), (K[3] & 0xffff0000) | (K[0] & 0x0000ffff)
                    ];

                    // Carry bit
                    this._b = 0;

                    // Iterate the system four times
                    for (var i = 0; i < 4; i++) {
                        nextState.call(this);
                    }

                    // Modify the counters
                    for (var i = 0; i < 8; i++) {
                        C[i] ^= X[(i + 4) & 7];
                    }

                    // IV setup
                    if (iv) {
                        // Shortcuts
                        var IV = iv.words;
                        var IV_0 = IV[0];
                        var IV_1 = IV[1];

                        // Generate four subvectors
                        var i0 = (((IV_0 << 8) | (IV_0 >>> 24)) & 0x00ff00ff) | (((IV_0 << 24) | (IV_0 >>> 8)) & 0xff00ff00);
                        var i2 = (((IV_1 << 8) | (IV_1 >>> 24)) & 0x00ff00ff) | (((IV_1 << 24) | (IV_1 >>> 8)) & 0xff00ff00);
                        var i1 = (i0 >>> 16) | (i2 & 0xffff0000);
                        var i3 = (i2 << 16) | (i0 & 0x0000ffff);

                        // Modify counter values
                        C[0] ^= i0;
                        C[1] ^= i1;
                        C[2] ^= i2;
                        C[3] ^= i3;
                        C[4] ^= i0;
                        C[5] ^= i1;
                        C[6] ^= i2;
                        C[7] ^= i3;

                        // Iterate the system four times
                        for (var i = 0; i < 4; i++) {
                            nextState.call(this);
                        }
                    }
                },

                _doProcessBlock: function (M, offset) {
                    // Shortcut
                    var X = this._X;

                    // Iterate the system
                    nextState.call(this);

                    // Generate four keystream words
                    S[0] = X[0] ^ (X[5] >>> 16) ^ (X[3] << 16);
                    S[1] = X[2] ^ (X[7] >>> 16) ^ (X[5] << 16);
                    S[2] = X[4] ^ (X[1] >>> 16) ^ (X[7] << 16);
                    S[3] = X[6] ^ (X[3] >>> 16) ^ (X[1] << 16);

                    for (var i = 0; i < 4; i++) {
                        // Swap endian
                        S[i] = (((S[i] << 8) | (S[i] >>> 24)) & 0x00ff00ff) |
                            (((S[i] << 24) | (S[i] >>> 8)) & 0xff00ff00);

                        // Encrypt
                        M[offset + i] ^= S[i];
                    }
                },

                blockSize: 128 / 32,

                ivSize: 64 / 32
            });

            function nextState() {
                // Shortcuts
                var X = this._X;
                var C = this._C;

                // Save old counter values
                for (var i = 0; i < 8; i++) {
                    C_[i] = C[i];
                }

                // Calculate new counter values
                C[0] = (C[0] + 0x4d34d34d + this._b) | 0;
                C[1] = (C[1] + 0xd34d34d3 + ((C[0] >>> 0) < (C_[0] >>> 0) ? 1 : 0)) | 0;
                C[2] = (C[2] + 0x34d34d34 + ((C[1] >>> 0) < (C_[1] >>> 0) ? 1 : 0)) | 0;
                C[3] = (C[3] + 0x4d34d34d + ((C[2] >>> 0) < (C_[2] >>> 0) ? 1 : 0)) | 0;
                C[4] = (C[4] + 0xd34d34d3 + ((C[3] >>> 0) < (C_[3] >>> 0) ? 1 : 0)) | 0;
                C[5] = (C[5] + 0x34d34d34 + ((C[4] >>> 0) < (C_[4] >>> 0) ? 1 : 0)) | 0;
                C[6] = (C[6] + 0x4d34d34d + ((C[5] >>> 0) < (C_[5] >>> 0) ? 1 : 0)) | 0;
                C[7] = (C[7] + 0xd34d34d3 + ((C[6] >>> 0) < (C_[6] >>> 0) ? 1 : 0)) | 0;
                this._b = (C[7] >>> 0) < (C_[7] >>> 0) ? 1 : 0;

                // Calculate the g-values
                for (var i = 0; i < 8; i++) {
                    var gx = X[i] + C[i];

                    // Construct high and low argument for squaring
                    var ga = gx & 0xffff;
                    var gb = gx >>> 16;

                    // Calculate high and low result of squaring
                    var gh = ((((ga * ga) >>> 17) + ga * gb) >>> 15) + gb * gb;
                    var gl = (((gx & 0xffff0000) * gx) | 0) + (((gx & 0x0000ffff) * gx) | 0);

                    // High XOR low
                    G[i] = gh ^ gl;
                }

                // Calculate new state values
                X[0] = (G[0] + ((G[7] << 16) | (G[7] >>> 16)) + ((G[6] << 16) | (G[6] >>> 16))) | 0;
                X[1] = (G[1] + ((G[0] << 8) | (G[0] >>> 24)) + G[7]) | 0;
                X[2] = (G[2] + ((G[1] << 16) | (G[1] >>> 16)) + ((G[0] << 16) | (G[0] >>> 16))) | 0;
                X[3] = (G[3] + ((G[2] << 8) | (G[2] >>> 24)) + G[1]) | 0;
                X[4] = (G[4] + ((G[3] << 16) | (G[3] >>> 16)) + ((G[2] << 16) | (G[2] >>> 16))) | 0;
                X[5] = (G[5] + ((G[4] << 8) | (G[4] >>> 24)) + G[3]) | 0;
                X[6] = (G[6] + ((G[5] << 16) | (G[5] >>> 16)) + ((G[4] << 16) | (G[4] >>> 16))) | 0;
                X[7] = (G[7] + ((G[6] << 8) | (G[6] >>> 24)) + G[5]) | 0;
            }

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.RabbitLegacy.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.RabbitLegacy.decrypt(ciphertext, key, cfg);
             */
            C.RabbitLegacy = StreamCipher._createHelper(RabbitLegacy);
        }());

        //RC4
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var StreamCipher = C_lib.StreamCipher;
            var C_algo = C.algo;

            /**
             * RC4 stream cipher algorithm.
             */
            var RC4 = C_algo.RC4 = StreamCipher.extend({
                _doReset: function () {
                    // Shortcuts
                    var key = this._key;
                    var keyWords = key.words;
                    var keySigBytes = key.sigBytes;

                    // Init sbox
                    var S = this._S = [];
                    for (var i = 0; i < 256; i++) {
                        S[i] = i;
                    }

                    // Key setup
                    for (var i = 0, j = 0; i < 256; i++) {
                        var keyByteIndex = i % keySigBytes;
                        var keyByte = (keyWords[keyByteIndex >>> 2] >>> (24 - (keyByteIndex % 4) * 8)) & 0xff;

                        j = (j + S[i] + keyByte) % 256;

                        // Swap
                        var t = S[i];
                        S[i] = S[j];
                        S[j] = t;
                    }

                    // Counters
                    this._i = this._j = 0;
                },

                _doProcessBlock: function (M, offset) {
                    M[offset] ^= generateKeystreamWord.call(this);
                },

                keySize: 256 / 32,

                ivSize: 0
            });

            function generateKeystreamWord() {
                // Shortcuts
                var S = this._S;
                var i = this._i;
                var j = this._j;

                // Generate keystream word
                var keystreamWord = 0;
                for (var n = 0; n < 4; n++) {
                    i = (i + 1) % 256;
                    j = (j + S[i]) % 256;

                    // Swap
                    var t = S[i];
                    S[i] = S[j];
                    S[j] = t;

                    keystreamWord |= S[(S[i] + S[j]) % 256] << (24 - n * 8);
                }

                // Update counters
                this._i = i;
                this._j = j;

                return keystreamWord;
            }

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.RC4.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.RC4.decrypt(ciphertext, key, cfg);
             */
            C.RC4 = StreamCipher._createHelper(RC4);

            /**
             * Modified RC4 stream cipher algorithm.
             */
            var RC4Drop = C_algo.RC4Drop = RC4.extend({
                /**
                 * Configuration options.
                 *
                 * @property {number} drop The number of keystream words to drop. Default 192
                 */
                cfg: RC4.cfg.extend({
                    drop: 192
                }),

                _doReset: function () {
                    RC4._doReset.call(this);

                    // Drop
                    for (var i = this.cfg.drop; i > 0; i--) {
                        generateKeystreamWord.call(this);
                    }
                }
            });

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.RC4Drop.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.RC4Drop.decrypt(ciphertext, key, cfg);
             */
            C.RC4Drop = StreamCipher._createHelper(RC4Drop);
        }());

        //ripemd160
        (function (Math) {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var WordArray = C_lib.WordArray;
            var Hasher = C_lib.Hasher;
            var C_algo = C.algo;

            // Constants table
            var _zl = WordArray.create([
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                7, 4, 13, 1, 10, 6, 15, 3, 12, 0, 9, 5, 2, 14, 11, 8,
                3, 10, 14, 4, 9, 15, 8, 1, 2, 7, 0, 6, 13, 11, 5, 12,
                1, 9, 11, 10, 0, 8, 12, 4, 13, 3, 7, 15, 14, 5, 6, 2,
                4, 0, 5, 9, 7, 12, 2, 10, 14, 1, 3, 8, 11, 6, 15, 13]);
            var _zr = WordArray.create([
                5, 14, 7, 0, 9, 2, 11, 4, 13, 6, 15, 8, 1, 10, 3, 12,
                6, 11, 3, 7, 0, 13, 5, 10, 14, 15, 8, 12, 4, 9, 1, 2,
                15, 5, 1, 3, 7, 14, 6, 9, 11, 8, 12, 2, 10, 0, 4, 13,
                8, 6, 4, 1, 3, 11, 15, 0, 5, 12, 2, 13, 9, 7, 10, 14,
                12, 15, 10, 4, 1, 5, 8, 7, 6, 2, 13, 14, 0, 3, 9, 11]);
            var _sl = WordArray.create([
                11, 14, 15, 12, 5, 8, 7, 9, 11, 13, 14, 15, 6, 7, 9, 8,
                7, 6, 8, 13, 11, 9, 7, 15, 7, 12, 15, 9, 11, 7, 13, 12,
                11, 13, 6, 7, 14, 9, 13, 15, 14, 8, 13, 6, 5, 12, 7, 5,
                11, 12, 14, 15, 14, 15, 9, 8, 9, 14, 5, 6, 8, 6, 5, 12,
                9, 15, 5, 11, 6, 8, 13, 12, 5, 12, 13, 14, 11, 8, 5, 6]);
            var _sr = WordArray.create([
                8, 9, 9, 11, 13, 15, 15, 5, 7, 7, 8, 11, 14, 14, 12, 6,
                9, 13, 15, 7, 12, 8, 9, 11, 7, 7, 12, 7, 6, 15, 13, 11,
                9, 7, 15, 11, 8, 6, 6, 14, 12, 13, 5, 14, 13, 13, 7, 5,
                15, 5, 8, 11, 14, 14, 6, 14, 6, 9, 12, 9, 12, 5, 15, 8,
                8, 5, 12, 9, 12, 5, 14, 6, 8, 13, 6, 5, 15, 13, 11, 11]);

            var _hl = WordArray.create([0x00000000, 0x5A827999, 0x6ED9EBA1, 0x8F1BBCDC, 0xA953FD4E]);
            var _hr = WordArray.create([0x50A28BE6, 0x5C4DD124, 0x6D703EF3, 0x7A6D76E9, 0x00000000]);

            /**
             * RIPEMD160 hash algorithm.
             */
            var RIPEMD160 = C_algo.RIPEMD160 = Hasher.extend({
                _doReset: function () {
                    this._hash = WordArray.create([0x67452301, 0xEFCDAB89, 0x98BADCFE, 0x10325476, 0xC3D2E1F0]);
                },

                _doProcessBlock: function (M, offset) {

                    // Swap endian
                    for (var i = 0; i < 16; i++) {
                        // Shortcuts
                        var offset_i = offset + i;
                        var M_offset_i = M[offset_i];

                        // Swap
                        M[offset_i] = (
                            (((M_offset_i << 8) | (M_offset_i >>> 24)) & 0x00ff00ff) |
                            (((M_offset_i << 24) | (M_offset_i >>> 8)) & 0xff00ff00)
                        );
                    }
                    // Shortcut
                    var H = this._hash.words;
                    var hl = _hl.words;
                    var hr = _hr.words;
                    var zl = _zl.words;
                    var zr = _zr.words;
                    var sl = _sl.words;
                    var sr = _sr.words;

                    // Working variables
                    var al, bl, cl, dl, el;
                    var ar, br, cr, dr, er;

                    ar = al = H[0];
                    br = bl = H[1];
                    cr = cl = H[2];
                    dr = dl = H[3];
                    er = el = H[4];
                    // Computation
                    var t;
                    for (var i = 0; i < 80; i += 1) {
                        t = (al + M[offset + zl[i]]) | 0;
                        if (i < 16) {
                            t += f1(bl, cl, dl) + hl[0];
                        } else if (i < 32) {
                            t += f2(bl, cl, dl) + hl[1];
                        } else if (i < 48) {
                            t += f3(bl, cl, dl) + hl[2];
                        } else if (i < 64) {
                            t += f4(bl, cl, dl) + hl[3];
                        } else {// if (i<80) {
                            t += f5(bl, cl, dl) + hl[4];
                        }
                        t = t | 0;
                        t = rotl(t, sl[i]);
                        t = (t + el) | 0;
                        al = el;
                        el = dl;
                        dl = rotl(cl, 10);
                        cl = bl;
                        bl = t;

                        t = (ar + M[offset + zr[i]]) | 0;
                        if (i < 16) {
                            t += f5(br, cr, dr) + hr[0];
                        } else if (i < 32) {
                            t += f4(br, cr, dr) + hr[1];
                        } else if (i < 48) {
                            t += f3(br, cr, dr) + hr[2];
                        } else if (i < 64) {
                            t += f2(br, cr, dr) + hr[3];
                        } else {// if (i<80) {
                            t += f1(br, cr, dr) + hr[4];
                        }
                        t = t | 0;
                        t = rotl(t, sr[i]);
                        t = (t + er) | 0;
                        ar = er;
                        er = dr;
                        dr = rotl(cr, 10);
                        cr = br;
                        br = t;
                    }
                    // Intermediate hash value
                    t = (H[1] + cl + dr) | 0;
                    H[1] = (H[2] + dl + er) | 0;
                    H[2] = (H[3] + el + ar) | 0;
                    H[3] = (H[4] + al + br) | 0;
                    H[4] = (H[0] + bl + cr) | 0;
                    H[0] = t;
                },

                _doFinalize: function () {
                    // Shortcuts
                    var data = this._data;
                    var dataWords = data.words;

                    var nBitsTotal = this._nDataBytes * 8;
                    var nBitsLeft = data.sigBytes * 8;

                    // Add padding
                    dataWords[nBitsLeft >>> 5] |= 0x80 << (24 - nBitsLeft % 32);
                    dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 14] = (
                        (((nBitsTotal << 8) | (nBitsTotal >>> 24)) & 0x00ff00ff) |
                        (((nBitsTotal << 24) | (nBitsTotal >>> 8)) & 0xff00ff00)
                    );
                    data.sigBytes = (dataWords.length + 1) * 4;

                    // Hash final blocks
                    this._process();

                    // Shortcuts
                    var hash = this._hash;
                    var H = hash.words;

                    // Swap endian
                    for (var i = 0; i < 5; i++) {
                        // Shortcut
                        var H_i = H[i];

                        // Swap
                        H[i] = (((H_i << 8) | (H_i >>> 24)) & 0x00ff00ff) |
                            (((H_i << 24) | (H_i >>> 8)) & 0xff00ff00);
                    }

                    // Return final computed hash
                    return hash;
                },

                clone: function () {
                    var clone = Hasher.clone.call(this);
                    clone._hash = this._hash.clone();

                    return clone;
                }
            });


            function f1(x, y, z) {
                return ((x) ^ (y) ^ (z));

            }

            function f2(x, y, z) {
                return (((x) & (y)) | ((~x) & (z)));
            }

            function f3(x, y, z) {
                return (((x) | (~(y))) ^ (z));
            }

            function f4(x, y, z) {
                return (((x) & (z)) | ((y) & (~(z))));
            }

            function f5(x, y, z) {
                return ((x) ^ ((y) | (~(z))));

            }

            function rotl(x, n) {
                return (x << n) | (x >>> (32 - n));
            }


            /**
             * Shortcut function to the hasher's object interface.
             *
             * @param {WordArray|string} message The message to hash.
             *
             * @return {WordArray} The hash.
             *
             * @static
             *
             * @example
             *
             *     var hash = CryptoJS.RIPEMD160('message');
             *     var hash = CryptoJS.RIPEMD160(wordArray);
             */
            C.RIPEMD160 = Hasher._createHelper(RIPEMD160);

            /**
             * Shortcut function to the HMAC's object interface.
             *
             * @param {WordArray|string} message The message to hash.
             * @param {WordArray|string} key The secret key.
             *
             * @return {WordArray} The HMAC.
             *
             * @static
             *
             * @example
             *
             *     var hmac = CryptoJS.HmacRIPEMD160(message, key);
             */
            C.HmacRIPEMD160 = Hasher._createHmacHelper(RIPEMD160);
        }(Math));

        //mode-cfb
        CryptoJS.mode.CFB = (function () {
            var CFB = CryptoJS.lib.BlockCipherMode.extend();

            CFB.Encryptor = CFB.extend({
                processBlock: function (words, offset) {
                    // Shortcuts
                    var cipher = this._cipher;
                    var blockSize = cipher.blockSize;

                    generateKeystreamAndEncrypt.call(this, words, offset, blockSize, cipher);

                    // Remember this block to use with next block
                    this._prevBlock = words.slice(offset, offset + blockSize);
                }
            });

            CFB.Decryptor = CFB.extend({
                processBlock: function (words, offset) {
                    // Shortcuts
                    var cipher = this._cipher;
                    var blockSize = cipher.blockSize;

                    // Remember this block to use with next block
                    var thisBlock = words.slice(offset, offset + blockSize);

                    generateKeystreamAndEncrypt.call(this, words, offset, blockSize, cipher);

                    // This block becomes the previous block
                    this._prevBlock = thisBlock;
                }
            });

            function generateKeystreamAndEncrypt(words, offset, blockSize, cipher) {
                // Shortcut
                var iv = this._iv;

                // Generate keystream
                if (iv) {
                    var keystream = iv.slice(0);

                    // Remove IV for subsequent blocks
                    this._iv = undefined;
                } else {
                    var keystream = this._prevBlock;
                }
                cipher.encryptBlock(keystream, 0);

                // Encrypt
                for (var i = 0; i < blockSize; i++) {
                    words[offset + i] ^= keystream[i];
                }
            }

            return CFB;
        }());

        //mode-ctr
        CryptoJS.mode.CTR = (function () {
            var CTR = CryptoJS.lib.BlockCipherMode.extend();

            var Encryptor = CTR.Encryptor = CTR.extend({
                processBlock: function (words, offset) {
                    // Shortcuts
                    var cipher = this._cipher
                    var blockSize = cipher.blockSize;
                    var iv = this._iv;
                    var counter = this._counter;

                    // Generate keystream
                    if (iv) {
                        counter = this._counter = iv.slice(0);

                        // Remove IV for subsequent blocks
                        this._iv = undefined;
                    }
                    var keystream = counter.slice(0);
                    cipher.encryptBlock(keystream, 0);

                    // Increment counter
                    counter[blockSize - 1] = (counter[blockSize - 1] + 1) | 0

                    // Encrypt
                    for (var i = 0; i < blockSize; i++) {
                        words[offset + i] ^= keystream[i];
                    }
                }
            });

            CTR.Decryptor = Encryptor;

            return CTR;
        }());

        //mode-ctr-gladman
        CryptoJS.mode.CTRGladman = (function () {
            var CTRGladman = CryptoJS.lib.BlockCipherMode.extend();

            function incWord(word) {
                if (((word >> 24) & 0xff) === 0xff) { //overflow
                    var b1 = (word >> 16) & 0xff;
                    var b2 = (word >> 8) & 0xff;
                    var b3 = word & 0xff;

                    if (b1 === 0xff) // overflow b1
                    {
                        b1 = 0;
                        if (b2 === 0xff) {
                            b2 = 0;
                            if (b3 === 0xff) {
                                b3 = 0;
                            }
                            else {
                                ++b3;
                            }
                        }
                        else {
                            ++b2;
                        }
                    }
                    else {
                        ++b1;
                    }

                    word = 0;
                    word += (b1 << 16);
                    word += (b2 << 8);
                    word += b3;
                }
                else {
                    word += (0x01 << 24);
                }
                return word;
            }

            function incCounter(counter) {
                if ((counter[0] = incWord(counter[0])) === 0) {
                    // encr_data in fileenc.c from  Dr Brian Gladman's counts only with DWORD j < 8
                    counter[1] = incWord(counter[1]);
                }
                return counter;
            }

            var Encryptor = CTRGladman.Encryptor = CTRGladman.extend({
                processBlock: function (words, offset) {
                    // Shortcuts
                    var cipher = this._cipher
                    var blockSize = cipher.blockSize;
                    var iv = this._iv;
                    var counter = this._counter;

                    // Generate keystream
                    if (iv) {
                        counter = this._counter = iv.slice(0);

                        // Remove IV for subsequent blocks
                        this._iv = undefined;
                    }

                    incCounter(counter);

                    var keystream = counter.slice(0);
                    cipher.encryptBlock(keystream, 0);

                    // Encrypt
                    for (var i = 0; i < blockSize; i++) {
                        words[offset + i] ^= keystream[i];
                    }
                }
            });

            CTRGladman.Decryptor = Encryptor;

            return CTRGladman;
        }());

        //mode-ecb
        CryptoJS.mode.ECB = (function () {
            var ECB = CryptoJS.lib.BlockCipherMode.extend();

            ECB.Encryptor = ECB.extend({
                processBlock: function (words, offset) {
                    this._cipher.encryptBlock(words, offset);
                }
            });

            ECB.Decryptor = ECB.extend({
                processBlock: function (words, offset) {
                    this._cipher.decryptBlock(words, offset);
                }
            });

            return ECB;
        }());

        //mode-ofb
        CryptoJS.mode.OFB = (function () {
            var OFB = CryptoJS.lib.BlockCipherMode.extend();

            var Encryptor = OFB.Encryptor = OFB.extend({
                processBlock: function (words, offset) {
                    // Shortcuts
                    var cipher = this._cipher
                    var blockSize = cipher.blockSize;
                    var iv = this._iv;
                    var keystream = this._keystream;

                    // Generate keystream
                    if (iv) {
                        keystream = this._keystream = iv.slice(0);

                        // Remove IV for subsequent blocks
                        this._iv = undefined;
                    }
                    cipher.encryptBlock(keystream, 0);

                    // Encrypt
                    for (var i = 0; i < blockSize; i++) {
                        words[offset + i] ^= keystream[i];
                    }
                }
            });

            OFB.Decryptor = Encryptor;

            return OFB;
        }());

        //pad-zeropadding
        CryptoJS.pad.ZeroPadding = {
            pad: function (data, blockSize) {
                // Shortcut
                var blockSizeBytes = blockSize * 4;

                // Pad
                data.clamp();
                data.sigBytes += blockSizeBytes - ((data.sigBytes % blockSizeBytes) || blockSizeBytes);
            },

            unpad: function (data) {
                // Shortcut
                var dataWords = data.words;

                // Unpad
                var i = data.sigBytes - 1;
                while (!((dataWords[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff)) {
                    i--;
                }
                data.sigBytes = i + 1;
            }
        };

        //pad-nopadding
        CryptoJS.pad.NoPadding = {
            pad: function () {
            },

            unpad: function () {
            }
        };

        //pad-iso97971
        CryptoJS.pad.Iso97971 = {
            pad: function (data, blockSize) {
                // Add 0x80 byte
                data.concat(CryptoJS.lib.WordArray.create([0x80000000], 1));

                // Zero pad the rest
                CryptoJS.pad.ZeroPadding.pad(data, blockSize);
            },

            unpad: function (data) {
                // Remove zero padding
                CryptoJS.pad.ZeroPadding.unpad(data);

                // Remove one more byte -- the 0x80 byte
                data.sigBytes--;
            }
        };

        //pad-Iso10126
        CryptoJS.pad.Iso10126 = {
            pad: function (data, blockSize) {
                // Shortcut
                var blockSizeBytes = blockSize * 4;

                // Count padding bytes
                var nPaddingBytes = blockSizeBytes - data.sigBytes % blockSizeBytes;

                // Pad
                data.concat(CryptoJS.lib.WordArray.random(nPaddingBytes - 1)).
                    concat(CryptoJS.lib.WordArray.create([nPaddingBytes << 24], 1));
            },

            unpad: function (data) {
                // Get number of padding bytes from last byte
                var nPaddingBytes = data.words[(data.sigBytes - 1) >>> 2] & 0xff;

                // Remove padding
                data.sigBytes -= nPaddingBytes;
            }
        };

        //pad-ansix923
        CryptoJS.pad.AnsiX923 = {
            pad: function (data, blockSize) {
                // Shortcuts
                var dataSigBytes = data.sigBytes;
                var blockSizeBytes = blockSize * 4;

                // Count padding bytes
                var nPaddingBytes = blockSizeBytes - dataSigBytes % blockSizeBytes;

                // Compute last byte position
                var lastBytePos = dataSigBytes + nPaddingBytes - 1;

                // Pad
                data.clamp();
                data.words[lastBytePos >>> 2] |= nPaddingBytes << (24 - (lastBytePos % 4) * 8);
                data.sigBytes += nPaddingBytes;
            },

            unpad: function (data) {
                // Get number of padding bytes from last byte
                var nPaddingBytes = data.words[(data.sigBytes - 1) >>> 2] & 0xff;

                // Remove padding
                data.sigBytes -= nPaddingBytes;
            }
        };

        //tripledes
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var WordArray = C_lib.WordArray;
            var BlockCipher = C_lib.BlockCipher;
            var C_algo = C.algo;

            // Permuted Choice 1 constants
            var PC1 = [
                57, 49, 41, 33, 25, 17, 9, 1,
                58, 50, 42, 34, 26, 18, 10, 2,
                59, 51, 43, 35, 27, 19, 11, 3,
                60, 52, 44, 36, 63, 55, 47, 39,
                31, 23, 15, 7, 62, 54, 46, 38,
                30, 22, 14, 6, 61, 53, 45, 37,
                29, 21, 13, 5, 28, 20, 12, 4
            ];

            // Permuted Choice 2 constants
            var PC2 = [
                14, 17, 11, 24, 1, 5,
                3, 28, 15, 6, 21, 10,
                23, 19, 12, 4, 26, 8,
                16, 7, 27, 20, 13, 2,
                41, 52, 31, 37, 47, 55,
                30, 40, 51, 45, 33, 48,
                44, 49, 39, 56, 34, 53,
                46, 42, 50, 36, 29, 32
            ];

            // Cumulative bit shift constants
            var BIT_SHIFTS = [1, 2, 4, 6, 8, 10, 12, 14, 15, 17, 19, 21, 23, 25, 27, 28];

            // SBOXes and round permutation constants
            var SBOX_P = [
                {
                    0x0: 0x808200,
                    0x10000000: 0x8000,
                    0x20000000: 0x808002,
                    0x30000000: 0x2,
                    0x40000000: 0x200,
                    0x50000000: 0x808202,
                    0x60000000: 0x800202,
                    0x70000000: 0x800000,
                    0x80000000: 0x202,
                    0x90000000: 0x800200,
                    0xa0000000: 0x8200,
                    0xb0000000: 0x808000,
                    0xc0000000: 0x8002,
                    0xd0000000: 0x800002,
                    0xe0000000: 0x0,
                    0xf0000000: 0x8202,
                    0x8000000: 0x0,
                    0x18000000: 0x808202,
                    0x28000000: 0x8202,
                    0x38000000: 0x8000,
                    0x48000000: 0x808200,
                    0x58000000: 0x200,
                    0x68000000: 0x808002,
                    0x78000000: 0x2,
                    0x88000000: 0x800200,
                    0x98000000: 0x8200,
                    0xa8000000: 0x808000,
                    0xb8000000: 0x800202,
                    0xc8000000: 0x800002,
                    0xd8000000: 0x8002,
                    0xe8000000: 0x202,
                    0xf8000000: 0x800000,
                    0x1: 0x8000,
                    0x10000001: 0x2,
                    0x20000001: 0x808200,
                    0x30000001: 0x800000,
                    0x40000001: 0x808002,
                    0x50000001: 0x8200,
                    0x60000001: 0x200,
                    0x70000001: 0x800202,
                    0x80000001: 0x808202,
                    0x90000001: 0x808000,
                    0xa0000001: 0x800002,
                    0xb0000001: 0x8202,
                    0xc0000001: 0x202,
                    0xd0000001: 0x800200,
                    0xe0000001: 0x8002,
                    0xf0000001: 0x0,
                    0x8000001: 0x808202,
                    0x18000001: 0x808000,
                    0x28000001: 0x800000,
                    0x38000001: 0x200,
                    0x48000001: 0x8000,
                    0x58000001: 0x800002,
                    0x68000001: 0x2,
                    0x78000001: 0x8202,
                    0x88000001: 0x8002,
                    0x98000001: 0x800202,
                    0xa8000001: 0x202,
                    0xb8000001: 0x808200,
                    0xc8000001: 0x800200,
                    0xd8000001: 0x0,
                    0xe8000001: 0x8200,
                    0xf8000001: 0x808002
                },
                {
                    0x0: 0x40084010,
                    0x1000000: 0x4000,
                    0x2000000: 0x80000,
                    0x3000000: 0x40080010,
                    0x4000000: 0x40000010,
                    0x5000000: 0x40084000,
                    0x6000000: 0x40004000,
                    0x7000000: 0x10,
                    0x8000000: 0x84000,
                    0x9000000: 0x40004010,
                    0xa000000: 0x40000000,
                    0xb000000: 0x84010,
                    0xc000000: 0x80010,
                    0xd000000: 0x0,
                    0xe000000: 0x4010,
                    0xf000000: 0x40080000,
                    0x800000: 0x40004000,
                    0x1800000: 0x84010,
                    0x2800000: 0x10,
                    0x3800000: 0x40004010,
                    0x4800000: 0x40084010,
                    0x5800000: 0x40000000,
                    0x6800000: 0x80000,
                    0x7800000: 0x40080010,
                    0x8800000: 0x80010,
                    0x9800000: 0x0,
                    0xa800000: 0x4000,
                    0xb800000: 0x40080000,
                    0xc800000: 0x40000010,
                    0xd800000: 0x84000,
                    0xe800000: 0x40084000,
                    0xf800000: 0x4010,
                    0x10000000: 0x0,
                    0x11000000: 0x40080010,
                    0x12000000: 0x40004010,
                    0x13000000: 0x40084000,
                    0x14000000: 0x40080000,
                    0x15000000: 0x10,
                    0x16000000: 0x84010,
                    0x17000000: 0x4000,
                    0x18000000: 0x4010,
                    0x19000000: 0x80000,
                    0x1a000000: 0x80010,
                    0x1b000000: 0x40000010,
                    0x1c000000: 0x84000,
                    0x1d000000: 0x40004000,
                    0x1e000000: 0x40000000,
                    0x1f000000: 0x40084010,
                    0x10800000: 0x84010,
                    0x11800000: 0x80000,
                    0x12800000: 0x40080000,
                    0x13800000: 0x4000,
                    0x14800000: 0x40004000,
                    0x15800000: 0x40084010,
                    0x16800000: 0x10,
                    0x17800000: 0x40000000,
                    0x18800000: 0x40084000,
                    0x19800000: 0x40000010,
                    0x1a800000: 0x40004010,
                    0x1b800000: 0x80010,
                    0x1c800000: 0x0,
                    0x1d800000: 0x4010,
                    0x1e800000: 0x40080010,
                    0x1f800000: 0x84000
                },
                {
                    0x0: 0x104,
                    0x100000: 0x0,
                    0x200000: 0x4000100,
                    0x300000: 0x10104,
                    0x400000: 0x10004,
                    0x500000: 0x4000004,
                    0x600000: 0x4010104,
                    0x700000: 0x4010000,
                    0x800000: 0x4000000,
                    0x900000: 0x4010100,
                    0xa00000: 0x10100,
                    0xb00000: 0x4010004,
                    0xc00000: 0x4000104,
                    0xd00000: 0x10000,
                    0xe00000: 0x4,
                    0xf00000: 0x100,
                    0x80000: 0x4010100,
                    0x180000: 0x4010004,
                    0x280000: 0x0,
                    0x380000: 0x4000100,
                    0x480000: 0x4000004,
                    0x580000: 0x10000,
                    0x680000: 0x10004,
                    0x780000: 0x104,
                    0x880000: 0x4,
                    0x980000: 0x100,
                    0xa80000: 0x4010000,
                    0xb80000: 0x10104,
                    0xc80000: 0x10100,
                    0xd80000: 0x4000104,
                    0xe80000: 0x4010104,
                    0xf80000: 0x4000000,
                    0x1000000: 0x4010100,
                    0x1100000: 0x10004,
                    0x1200000: 0x10000,
                    0x1300000: 0x4000100,
                    0x1400000: 0x100,
                    0x1500000: 0x4010104,
                    0x1600000: 0x4000004,
                    0x1700000: 0x0,
                    0x1800000: 0x4000104,
                    0x1900000: 0x4000000,
                    0x1a00000: 0x4,
                    0x1b00000: 0x10100,
                    0x1c00000: 0x4010000,
                    0x1d00000: 0x104,
                    0x1e00000: 0x10104,
                    0x1f00000: 0x4010004,
                    0x1080000: 0x4000000,
                    0x1180000: 0x104,
                    0x1280000: 0x4010100,
                    0x1380000: 0x0,
                    0x1480000: 0x10004,
                    0x1580000: 0x4000100,
                    0x1680000: 0x100,
                    0x1780000: 0x4010004,
                    0x1880000: 0x10000,
                    0x1980000: 0x4010104,
                    0x1a80000: 0x10104,
                    0x1b80000: 0x4000004,
                    0x1c80000: 0x4000104,
                    0x1d80000: 0x4010000,
                    0x1e80000: 0x4,
                    0x1f80000: 0x10100
                },
                {
                    0x0: 0x80401000,
                    0x10000: 0x80001040,
                    0x20000: 0x401040,
                    0x30000: 0x80400000,
                    0x40000: 0x0,
                    0x50000: 0x401000,
                    0x60000: 0x80000040,
                    0x70000: 0x400040,
                    0x80000: 0x80000000,
                    0x90000: 0x400000,
                    0xa0000: 0x40,
                    0xb0000: 0x80001000,
                    0xc0000: 0x80400040,
                    0xd0000: 0x1040,
                    0xe0000: 0x1000,
                    0xf0000: 0x80401040,
                    0x8000: 0x80001040,
                    0x18000: 0x40,
                    0x28000: 0x80400040,
                    0x38000: 0x80001000,
                    0x48000: 0x401000,
                    0x58000: 0x80401040,
                    0x68000: 0x0,
                    0x78000: 0x80400000,
                    0x88000: 0x1000,
                    0x98000: 0x80401000,
                    0xa8000: 0x400000,
                    0xb8000: 0x1040,
                    0xc8000: 0x80000000,
                    0xd8000: 0x400040,
                    0xe8000: 0x401040,
                    0xf8000: 0x80000040,
                    0x100000: 0x400040,
                    0x110000: 0x401000,
                    0x120000: 0x80000040,
                    0x130000: 0x0,
                    0x140000: 0x1040,
                    0x150000: 0x80400040,
                    0x160000: 0x80401000,
                    0x170000: 0x80001040,
                    0x180000: 0x80401040,
                    0x190000: 0x80000000,
                    0x1a0000: 0x80400000,
                    0x1b0000: 0x401040,
                    0x1c0000: 0x80001000,
                    0x1d0000: 0x400000,
                    0x1e0000: 0x40,
                    0x1f0000: 0x1000,
                    0x108000: 0x80400000,
                    0x118000: 0x80401040,
                    0x128000: 0x0,
                    0x138000: 0x401000,
                    0x148000: 0x400040,
                    0x158000: 0x80000000,
                    0x168000: 0x80001040,
                    0x178000: 0x40,
                    0x188000: 0x80000040,
                    0x198000: 0x1000,
                    0x1a8000: 0x80001000,
                    0x1b8000: 0x80400040,
                    0x1c8000: 0x1040,
                    0x1d8000: 0x80401000,
                    0x1e8000: 0x400000,
                    0x1f8000: 0x401040
                },
                {
                    0x0: 0x80,
                    0x1000: 0x1040000,
                    0x2000: 0x40000,
                    0x3000: 0x20000000,
                    0x4000: 0x20040080,
                    0x5000: 0x1000080,
                    0x6000: 0x21000080,
                    0x7000: 0x40080,
                    0x8000: 0x1000000,
                    0x9000: 0x20040000,
                    0xa000: 0x20000080,
                    0xb000: 0x21040080,
                    0xc000: 0x21040000,
                    0xd000: 0x0,
                    0xe000: 0x1040080,
                    0xf000: 0x21000000,
                    0x800: 0x1040080,
                    0x1800: 0x21000080,
                    0x2800: 0x80,
                    0x3800: 0x1040000,
                    0x4800: 0x40000,
                    0x5800: 0x20040080,
                    0x6800: 0x21040000,
                    0x7800: 0x20000000,
                    0x8800: 0x20040000,
                    0x9800: 0x0,
                    0xa800: 0x21040080,
                    0xb800: 0x1000080,
                    0xc800: 0x20000080,
                    0xd800: 0x21000000,
                    0xe800: 0x1000000,
                    0xf800: 0x40080,
                    0x10000: 0x40000,
                    0x11000: 0x80,
                    0x12000: 0x20000000,
                    0x13000: 0x21000080,
                    0x14000: 0x1000080,
                    0x15000: 0x21040000,
                    0x16000: 0x20040080,
                    0x17000: 0x1000000,
                    0x18000: 0x21040080,
                    0x19000: 0x21000000,
                    0x1a000: 0x1040000,
                    0x1b000: 0x20040000,
                    0x1c000: 0x40080,
                    0x1d000: 0x20000080,
                    0x1e000: 0x0,
                    0x1f000: 0x1040080,
                    0x10800: 0x21000080,
                    0x11800: 0x1000000,
                    0x12800: 0x1040000,
                    0x13800: 0x20040080,
                    0x14800: 0x20000000,
                    0x15800: 0x1040080,
                    0x16800: 0x80,
                    0x17800: 0x21040000,
                    0x18800: 0x40080,
                    0x19800: 0x21040080,
                    0x1a800: 0x0,
                    0x1b800: 0x21000000,
                    0x1c800: 0x1000080,
                    0x1d800: 0x40000,
                    0x1e800: 0x20040000,
                    0x1f800: 0x20000080
                },
                {
                    0x0: 0x10000008,
                    0x100: 0x2000,
                    0x200: 0x10200000,
                    0x300: 0x10202008,
                    0x400: 0x10002000,
                    0x500: 0x200000,
                    0x600: 0x200008,
                    0x700: 0x10000000,
                    0x800: 0x0,
                    0x900: 0x10002008,
                    0xa00: 0x202000,
                    0xb00: 0x8,
                    0xc00: 0x10200008,
                    0xd00: 0x202008,
                    0xe00: 0x2008,
                    0xf00: 0x10202000,
                    0x80: 0x10200000,
                    0x180: 0x10202008,
                    0x280: 0x8,
                    0x380: 0x200000,
                    0x480: 0x202008,
                    0x580: 0x10000008,
                    0x680: 0x10002000,
                    0x780: 0x2008,
                    0x880: 0x200008,
                    0x980: 0x2000,
                    0xa80: 0x10002008,
                    0xb80: 0x10200008,
                    0xc80: 0x0,
                    0xd80: 0x10202000,
                    0xe80: 0x202000,
                    0xf80: 0x10000000,
                    0x1000: 0x10002000,
                    0x1100: 0x10200008,
                    0x1200: 0x10202008,
                    0x1300: 0x2008,
                    0x1400: 0x200000,
                    0x1500: 0x10000000,
                    0x1600: 0x10000008,
                    0x1700: 0x202000,
                    0x1800: 0x202008,
                    0x1900: 0x0,
                    0x1a00: 0x8,
                    0x1b00: 0x10200000,
                    0x1c00: 0x2000,
                    0x1d00: 0x10002008,
                    0x1e00: 0x10202000,
                    0x1f00: 0x200008,
                    0x1080: 0x8,
                    0x1180: 0x202000,
                    0x1280: 0x200000,
                    0x1380: 0x10000008,
                    0x1480: 0x10002000,
                    0x1580: 0x2008,
                    0x1680: 0x10202008,
                    0x1780: 0x10200000,
                    0x1880: 0x10202000,
                    0x1980: 0x10200008,
                    0x1a80: 0x2000,
                    0x1b80: 0x202008,
                    0x1c80: 0x200008,
                    0x1d80: 0x0,
                    0x1e80: 0x10000000,
                    0x1f80: 0x10002008
                },
                {
                    0x0: 0x100000,
                    0x10: 0x2000401,
                    0x20: 0x400,
                    0x30: 0x100401,
                    0x40: 0x2100401,
                    0x50: 0x0,
                    0x60: 0x1,
                    0x70: 0x2100001,
                    0x80: 0x2000400,
                    0x90: 0x100001,
                    0xa0: 0x2000001,
                    0xb0: 0x2100400,
                    0xc0: 0x2100000,
                    0xd0: 0x401,
                    0xe0: 0x100400,
                    0xf0: 0x2000000,
                    0x8: 0x2100001,
                    0x18: 0x0,
                    0x28: 0x2000401,
                    0x38: 0x2100400,
                    0x48: 0x100000,
                    0x58: 0x2000001,
                    0x68: 0x2000000,
                    0x78: 0x401,
                    0x88: 0x100401,
                    0x98: 0x2000400,
                    0xa8: 0x2100000,
                    0xb8: 0x100001,
                    0xc8: 0x400,
                    0xd8: 0x2100401,
                    0xe8: 0x1,
                    0xf8: 0x100400,
                    0x100: 0x2000000,
                    0x110: 0x100000,
                    0x120: 0x2000401,
                    0x130: 0x2100001,
                    0x140: 0x100001,
                    0x150: 0x2000400,
                    0x160: 0x2100400,
                    0x170: 0x100401,
                    0x180: 0x401,
                    0x190: 0x2100401,
                    0x1a0: 0x100400,
                    0x1b0: 0x1,
                    0x1c0: 0x0,
                    0x1d0: 0x2100000,
                    0x1e0: 0x2000001,
                    0x1f0: 0x400,
                    0x108: 0x100400,
                    0x118: 0x2000401,
                    0x128: 0x2100001,
                    0x138: 0x1,
                    0x148: 0x2000000,
                    0x158: 0x100000,
                    0x168: 0x401,
                    0x178: 0x2100400,
                    0x188: 0x2000001,
                    0x198: 0x2100000,
                    0x1a8: 0x0,
                    0x1b8: 0x2100401,
                    0x1c8: 0x100401,
                    0x1d8: 0x400,
                    0x1e8: 0x2000400,
                    0x1f8: 0x100001
                },
                {
                    0x0: 0x8000820,
                    0x1: 0x20000,
                    0x2: 0x8000000,
                    0x3: 0x20,
                    0x4: 0x20020,
                    0x5: 0x8020820,
                    0x6: 0x8020800,
                    0x7: 0x800,
                    0x8: 0x8020000,
                    0x9: 0x8000800,
                    0xa: 0x20800,
                    0xb: 0x8020020,
                    0xc: 0x820,
                    0xd: 0x0,
                    0xe: 0x8000020,
                    0xf: 0x20820,
                    0x80000000: 0x800,
                    0x80000001: 0x8020820,
                    0x80000002: 0x8000820,
                    0x80000003: 0x8000000,
                    0x80000004: 0x8020000,
                    0x80000005: 0x20800,
                    0x80000006: 0x20820,
                    0x80000007: 0x20,
                    0x80000008: 0x8000020,
                    0x80000009: 0x820,
                    0x8000000a: 0x20020,
                    0x8000000b: 0x8020800,
                    0x8000000c: 0x0,
                    0x8000000d: 0x8020020,
                    0x8000000e: 0x8000800,
                    0x8000000f: 0x20000,
                    0x10: 0x20820,
                    0x11: 0x8020800,
                    0x12: 0x20,
                    0x13: 0x800,
                    0x14: 0x8000800,
                    0x15: 0x8000020,
                    0x16: 0x8020020,
                    0x17: 0x20000,
                    0x18: 0x0,
                    0x19: 0x20020,
                    0x1a: 0x8020000,
                    0x1b: 0x8000820,
                    0x1c: 0x8020820,
                    0x1d: 0x20800,
                    0x1e: 0x820,
                    0x1f: 0x8000000,
                    0x80000010: 0x20000,
                    0x80000011: 0x800,
                    0x80000012: 0x8020020,
                    0x80000013: 0x20820,
                    0x80000014: 0x20,
                    0x80000015: 0x8020000,
                    0x80000016: 0x8000000,
                    0x80000017: 0x8000820,
                    0x80000018: 0x8020820,
                    0x80000019: 0x8000020,
                    0x8000001a: 0x8000800,
                    0x8000001b: 0x0,
                    0x8000001c: 0x20800,
                    0x8000001d: 0x820,
                    0x8000001e: 0x20020,
                    0x8000001f: 0x8020800
                }
            ];

            // Masks that select the SBOX input
            var SBOX_MASK = [
                0xf8000001, 0x1f800000, 0x01f80000, 0x001f8000,
                0x0001f800, 0x00001f80, 0x000001f8, 0x8000001f
            ];

            /**
             * DES block cipher algorithm.
             */
            var DES = C_algo.DES = BlockCipher.extend({
                _doReset: function () {
                    // Shortcuts
                    var key = this._key;
                    var keyWords = key.words;

                    // Select 56 bits according to PC1
                    var keyBits = [];
                    for (var i = 0; i < 56; i++) {
                        var keyBitPos = PC1[i] - 1;
                        keyBits[i] = (keyWords[keyBitPos >>> 5] >>> (31 - keyBitPos % 32)) & 1;
                    }

                    // Assemble 16 subkeys
                    var subKeys = this._subKeys = [];
                    for (var nSubKey = 0; nSubKey < 16; nSubKey++) {
                        // Create subkey
                        var subKey = subKeys[nSubKey] = [];

                        // Shortcut
                        var bitShift = BIT_SHIFTS[nSubKey];

                        // Select 48 bits according to PC2
                        for (var i = 0; i < 24; i++) {
                            // Select from the left 28 key bits
                            subKey[(i / 6) | 0] |= keyBits[((PC2[i] - 1) + bitShift) % 28] << (31 - i % 6);

                            // Select from the right 28 key bits
                            subKey[4 + ((i / 6) | 0)] |= keyBits[28 + (((PC2[i + 24] - 1) + bitShift) % 28)] << (31 - i % 6);
                        }

                        // Since each subkey is applied to an expanded 32-bit input,
                        // the subkey can be broken into 8 values scaled to 32-bits,
                        // which allows the key to be used without expansion
                        subKey[0] = (subKey[0] << 1) | (subKey[0] >>> 31);
                        for (var i = 1; i < 7; i++) {
                            subKey[i] = subKey[i] >>> ((i - 1) * 4 + 3);
                        }
                        subKey[7] = (subKey[7] << 5) | (subKey[7] >>> 27);
                    }

                    // Compute inverse subkeys
                    var invSubKeys = this._invSubKeys = [];
                    for (var i = 0; i < 16; i++) {
                        invSubKeys[i] = subKeys[15 - i];
                    }
                },

                encryptBlock: function (M, offset) {
                    this._doCryptBlock(M, offset, this._subKeys);
                },

                decryptBlock: function (M, offset) {
                    this._doCryptBlock(M, offset, this._invSubKeys);
                },

                _doCryptBlock: function (M, offset, subKeys) {
                    // Get input
                    this._lBlock = M[offset];
                    this._rBlock = M[offset + 1];

                    // Initial permutation
                    exchangeLR.call(this, 4, 0x0f0f0f0f);
                    exchangeLR.call(this, 16, 0x0000ffff);
                    exchangeRL.call(this, 2, 0x33333333);
                    exchangeRL.call(this, 8, 0x00ff00ff);
                    exchangeLR.call(this, 1, 0x55555555);

                    // Rounds
                    for (var round = 0; round < 16; round++) {
                        // Shortcuts
                        var subKey = subKeys[round];
                        var lBlock = this._lBlock;
                        var rBlock = this._rBlock;

                        // Feistel function
                        var f = 0;
                        for (var i = 0; i < 8; i++) {
                            f |= SBOX_P[i][((rBlock ^ subKey[i]) & SBOX_MASK[i]) >>> 0];
                        }
                        this._lBlock = rBlock;
                        this._rBlock = lBlock ^ f;
                    }

                    // Undo swap from last round
                    var t = this._lBlock;
                    this._lBlock = this._rBlock;
                    this._rBlock = t;

                    // Final permutation
                    exchangeLR.call(this, 1, 0x55555555);
                    exchangeRL.call(this, 8, 0x00ff00ff);
                    exchangeRL.call(this, 2, 0x33333333);
                    exchangeLR.call(this, 16, 0x0000ffff);
                    exchangeLR.call(this, 4, 0x0f0f0f0f);

                    // Set output
                    M[offset] = this._lBlock;
                    M[offset + 1] = this._rBlock;
                },

                keySize: 64 / 32,

                ivSize: 64 / 32,

                blockSize: 64 / 32
            });

            // Swap bits across the left and right words
            function exchangeLR(offset, mask) {
                var t = ((this._lBlock >>> offset) ^ this._rBlock) & mask;
                this._rBlock ^= t;
                this._lBlock ^= t << offset;
            }

            function exchangeRL(offset, mask) {
                var t = ((this._rBlock >>> offset) ^ this._lBlock) & mask;
                this._lBlock ^= t;
                this._rBlock ^= t << offset;
            }

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.DES.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.DES.decrypt(ciphertext, key, cfg);
             */
            C.DES = BlockCipher._createHelper(DES);

            /**
             * Triple-DES block cipher algorithm.
             */
            var TripleDES = C_algo.TripleDES = BlockCipher.extend({
                _doReset: function () {
                    // Shortcuts
                    var key = this._key;
                    var keyWords = key.words;

                    // Create DES instances
                    this._des1 = DES.createEncryptor(WordArray.create(keyWords.slice(0, 2)));
                    this._des2 = DES.createEncryptor(WordArray.create(keyWords.slice(2, 4)));
                    this._des3 = DES.createEncryptor(WordArray.create(keyWords.slice(4, 6)));
                },

                encryptBlock: function (M, offset) {
                    this._des1.encryptBlock(M, offset);
                    this._des2.decryptBlock(M, offset);
                    this._des3.encryptBlock(M, offset);
                },

                decryptBlock: function (M, offset) {
                    this._des3.decryptBlock(M, offset);
                    this._des2.encryptBlock(M, offset);
                    this._des1.decryptBlock(M, offset);
                },

                keySize: 192 / 32,

                ivSize: 64 / 32,

                blockSize: 64 / 32
            });

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.TripleDES.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.TripleDES.decrypt(ciphertext, key, cfg);
             */
            C.TripleDES = BlockCipher._createHelper(TripleDES);
        }());

        //AES
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var BlockCipher = C_lib.BlockCipher;
            var C_algo = C.algo;

            // Lookup tables
            var SBOX = [];
            var INV_SBOX = [];
            var SUB_MIX_0 = [];
            var SUB_MIX_1 = [];
            var SUB_MIX_2 = [];
            var SUB_MIX_3 = [];
            var INV_SUB_MIX_0 = [];
            var INV_SUB_MIX_1 = [];
            var INV_SUB_MIX_2 = [];
            var INV_SUB_MIX_3 = [];

            // Compute lookup tables
            (function () {
                // Compute double table
                var d = [];
                for (var i = 0; i < 256; i++) {
                    if (i < 128) {
                        d[i] = i << 1;
                    } else {
                        d[i] = (i << 1) ^ 0x11b;
                    }
                }

                // Walk GF(2^8)
                var x = 0;
                var xi = 0;
                for (var i = 0; i < 256; i++) {
                    // Compute sbox
                    var sx = xi ^ (xi << 1) ^ (xi << 2) ^ (xi << 3) ^ (xi << 4);
                    sx = (sx >>> 8) ^ (sx & 0xff) ^ 0x63;
                    SBOX[x] = sx;
                    INV_SBOX[sx] = x;

                    // Compute multiplication
                    var x2 = d[x];
                    var x4 = d[x2];
                    var x8 = d[x4];

                    // Compute sub bytes, mix columns tables
                    var t = (d[sx] * 0x101) ^ (sx * 0x1010100);
                    SUB_MIX_0[x] = (t << 24) | (t >>> 8);
                    SUB_MIX_1[x] = (t << 16) | (t >>> 16);
                    SUB_MIX_2[x] = (t << 8) | (t >>> 24);
                    SUB_MIX_3[x] = t;

                    // Compute inv sub bytes, inv mix columns tables
                    var t = (x8 * 0x1010101) ^ (x4 * 0x10001) ^ (x2 * 0x101) ^ (x * 0x1010100);
                    INV_SUB_MIX_0[sx] = (t << 24) | (t >>> 8);
                    INV_SUB_MIX_1[sx] = (t << 16) | (t >>> 16);
                    INV_SUB_MIX_2[sx] = (t << 8) | (t >>> 24);
                    INV_SUB_MIX_3[sx] = t;

                    // Compute next counter
                    if (!x) {
                        x = xi = 1;
                    } else {
                        x = x2 ^ d[d[d[x8 ^ x2]]];
                        xi ^= d[d[xi]];
                    }
                }
            }());

            // Precomputed Rcon lookup
            var RCON = [0x00, 0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x40, 0x80, 0x1b, 0x36];

            /**
             * AES block cipher algorithm.
             */
            var AES = C_algo.AES = BlockCipher.extend({
                _doReset: function () {
                    // Shortcuts
                    var key = this._key;
                    var keyWords = key.words;
                    var keySize = key.sigBytes / 4;

                    // Compute number of rounds
                    var nRounds = this._nRounds = keySize + 6

                    // Compute number of key schedule rows
                    var ksRows = (nRounds + 1) * 4;

                    // Compute key schedule
                    var keySchedule = this._keySchedule = [];
                    for (var ksRow = 0; ksRow < ksRows; ksRow++) {
                        if (ksRow < keySize) {
                            keySchedule[ksRow] = keyWords[ksRow];
                        } else {
                            var t = keySchedule[ksRow - 1];

                            if (!(ksRow % keySize)) {
                                // Rot word
                                t = (t << 8) | (t >>> 24);

                                // Sub word
                                t = (SBOX[t >>> 24] << 24) | (SBOX[(t >>> 16) & 0xff] << 16) | (SBOX[(t >>> 8) & 0xff] << 8) | SBOX[t & 0xff];

                                // Mix Rcon
                                t ^= RCON[(ksRow / keySize) | 0] << 24;
                            } else if (keySize > 6 && ksRow % keySize == 4) {
                                // Sub word
                                t = (SBOX[t >>> 24] << 24) | (SBOX[(t >>> 16) & 0xff] << 16) | (SBOX[(t >>> 8) & 0xff] << 8) | SBOX[t & 0xff];
                            }

                            keySchedule[ksRow] = keySchedule[ksRow - keySize] ^ t;
                        }
                    }

                    // Compute inv key schedule
                    var invKeySchedule = this._invKeySchedule = [];
                    for (var invKsRow = 0; invKsRow < ksRows; invKsRow++) {
                        var ksRow = ksRows - invKsRow;

                        if (invKsRow % 4) {
                            var t = keySchedule[ksRow];
                        } else {
                            var t = keySchedule[ksRow - 4];
                        }

                        if (invKsRow < 4 || ksRow <= 4) {
                            invKeySchedule[invKsRow] = t;
                        } else {
                            invKeySchedule[invKsRow] = INV_SUB_MIX_0[SBOX[t >>> 24]] ^ INV_SUB_MIX_1[SBOX[(t >>> 16) & 0xff]] ^
                                INV_SUB_MIX_2[SBOX[(t >>> 8) & 0xff]] ^ INV_SUB_MIX_3[SBOX[t & 0xff]];
                        }
                    }
                },

                encryptBlock: function (M, offset) {
                    this._doCryptBlock(M, offset, this._keySchedule, SUB_MIX_0, SUB_MIX_1, SUB_MIX_2, SUB_MIX_3, SBOX);
                },

                decryptBlock: function (M, offset) {
                    // Swap 2nd and 4th rows
                    var t = M[offset + 1];
                    M[offset + 1] = M[offset + 3];
                    M[offset + 3] = t;

                    this._doCryptBlock(M, offset, this._invKeySchedule, INV_SUB_MIX_0, INV_SUB_MIX_1, INV_SUB_MIX_2, INV_SUB_MIX_3, INV_SBOX);

                    // Inv swap 2nd and 4th rows
                    var t = M[offset + 1];
                    M[offset + 1] = M[offset + 3];
                    M[offset + 3] = t;
                },

                _doCryptBlock: function (M, offset, keySchedule, SUB_MIX_0, SUB_MIX_1, SUB_MIX_2, SUB_MIX_3, SBOX) {
                    // Shortcut
                    var nRounds = this._nRounds;

                    // Get input, add round key
                    var s0 = M[offset] ^ keySchedule[0];
                    var s1 = M[offset + 1] ^ keySchedule[1];
                    var s2 = M[offset + 2] ^ keySchedule[2];
                    var s3 = M[offset + 3] ^ keySchedule[3];

                    // Key schedule row counter
                    var ksRow = 4;

                    // Rounds
                    for (var round = 1; round < nRounds; round++) {
                        // Shift rows, sub bytes, mix columns, add round key
                        var t0 = SUB_MIX_0[s0 >>> 24] ^ SUB_MIX_1[(s1 >>> 16) & 0xff] ^ SUB_MIX_2[(s2 >>> 8) & 0xff] ^ SUB_MIX_3[s3 & 0xff] ^ keySchedule[ksRow++];
                        var t1 = SUB_MIX_0[s1 >>> 24] ^ SUB_MIX_1[(s2 >>> 16) & 0xff] ^ SUB_MIX_2[(s3 >>> 8) & 0xff] ^ SUB_MIX_3[s0 & 0xff] ^ keySchedule[ksRow++];
                        var t2 = SUB_MIX_0[s2 >>> 24] ^ SUB_MIX_1[(s3 >>> 16) & 0xff] ^ SUB_MIX_2[(s0 >>> 8) & 0xff] ^ SUB_MIX_3[s1 & 0xff] ^ keySchedule[ksRow++];
                        var t3 = SUB_MIX_0[s3 >>> 24] ^ SUB_MIX_1[(s0 >>> 16) & 0xff] ^ SUB_MIX_2[(s1 >>> 8) & 0xff] ^ SUB_MIX_3[s2 & 0xff] ^ keySchedule[ksRow++];

                        // Update state
                        s0 = t0;
                        s1 = t1;
                        s2 = t2;
                        s3 = t3;
                    }

                    // Shift rows, sub bytes, add round key
                    var t0 = ((SBOX[s0 >>> 24] << 24) | (SBOX[(s1 >>> 16) & 0xff] << 16) | (SBOX[(s2 >>> 8) & 0xff] << 8) | SBOX[s3 & 0xff]) ^ keySchedule[ksRow++];
                    var t1 = ((SBOX[s1 >>> 24] << 24) | (SBOX[(s2 >>> 16) & 0xff] << 16) | (SBOX[(s3 >>> 8) & 0xff] << 8) | SBOX[s0 & 0xff]) ^ keySchedule[ksRow++];
                    var t2 = ((SBOX[s2 >>> 24] << 24) | (SBOX[(s3 >>> 16) & 0xff] << 16) | (SBOX[(s0 >>> 8) & 0xff] << 8) | SBOX[s1 & 0xff]) ^ keySchedule[ksRow++];
                    var t3 = ((SBOX[s3 >>> 24] << 24) | (SBOX[(s0 >>> 16) & 0xff] << 16) | (SBOX[(s1 >>> 8) & 0xff] << 8) | SBOX[s2 & 0xff]) ^ keySchedule[ksRow++];

                    // Set output
                    M[offset] = t0;
                    M[offset + 1] = t1;
                    M[offset + 2] = t2;
                    M[offset + 3] = t3;
                },

                keySize: 256 / 32
            });

            /**
             * Shortcut functions to the cipher's object interface.
             *
             * @example
             *
             *     var ciphertext = CryptoJS.AES.encrypt(message, key, cfg);
             *     var plaintext  = CryptoJS.AES.decrypt(ciphertext, key, cfg);
             */
            C.AES = BlockCipher._createHelper(AES);
        }());

        //hmac
        (function () {
            // Shortcuts
            var C = CryptoJS;
            var C_lib = C.lib;
            var Base = C_lib.Base;
            var C_enc = C.enc;
            var Utf8 = C_enc.Utf8;
            var C_algo = C.algo;

            /**
             * HMAC algorithm.
             */
            var HMAC = C_algo.HMAC = Base.extend({
                /**
                 * Initializes a newly created HMAC.
                 *
                 * @param {Hasher} hasher The hash algorithm to use.
                 * @param {WordArray|string} key The secret key.
                 *
                 * @example
                 *
                 *     var hmacHasher = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, key);
                 */
                init: function (hasher, key) {
                    // Init hasher
                    hasher = this._hasher = new hasher.init();

                    // Convert string to WordArray, else assume WordArray already
                    if (typeof key == 'string') {
                        key = Utf8.parse(key);
                    }

                    // Shortcuts
                    var hasherBlockSize = hasher.blockSize;
                    var hasherBlockSizeBytes = hasherBlockSize * 4;

                    // Allow arbitrary length keys
                    if (key.sigBytes > hasherBlockSizeBytes) {
                        key = hasher.finalize(key);
                    }

                    // Clamp excess bits
                    key.clamp();

                    // Clone key for inner and outer pads
                    var oKey = this._oKey = key.clone();
                    var iKey = this._iKey = key.clone();

                    // Shortcuts
                    var oKeyWords = oKey.words;
                    var iKeyWords = iKey.words;

                    // XOR keys with pad constants
                    for (var i = 0; i < hasherBlockSize; i++) {
                        oKeyWords[i] ^= 0x5c5c5c5c;
                        iKeyWords[i] ^= 0x36363636;
                    }
                    oKey.sigBytes = iKey.sigBytes = hasherBlockSizeBytes;

                    // Set initial values
                    this.reset();
                },

                /**
                 * Resets this HMAC to its initial state.
                 *
                 * @example
                 *
                 *     hmacHasher.reset();
                 */
                reset: function () {
                    // Shortcut
                    var hasher = this._hasher;

                    // Reset
                    hasher.reset();
                    hasher.update(this._iKey);
                },

                /**
                 * Updates this HMAC with a message.
                 *
                 * @param {WordArray|string} messageUpdate The message to append.
                 *
                 * @return {HMAC} This HMAC instance.
                 *
                 * @example
                 *
                 *     hmacHasher.update('message');
                 *     hmacHasher.update(wordArray);
                 */
                update: function (messageUpdate) {
                    this._hasher.update(messageUpdate);

                    // Chainable
                    return this;
                },

                /**
                 * Finalizes the HMAC computation.
                 * Note that the finalize operation is effectively a destructive, read-once operation.
                 *
                 * @param {WordArray|string} messageUpdate (Optional) A final message update.
                 *
                 * @return {WordArray} The HMAC.
                 *
                 * @example
                 *
                 *     var hmac = hmacHasher.finalize();
                 *     var hmac = hmacHasher.finalize('message');
                 *     var hmac = hmacHasher.finalize(wordArray);
                 */
                finalize: function (messageUpdate) {
                    // Shortcut
                    var hasher = this._hasher;

                    // Compute HMAC
                    var innerHash = hasher.finalize(messageUpdate);
                    hasher.reset();
                    var hmac = hasher.finalize(this._oKey.clone().concat(innerHash));

                    return hmac;
                }
            });
        }());

    }(window.CryptoJS));
})();
