// @ts-nocheck



import { config } from '@/config/config';
import { LobbyEgretByte } from '@/utils/LobbyEgretByte';
import { LobbyCompressUtil } from "@/utils/LobbyCompressUtil";
import url_config from '@/config/url_config';
import axios from 'axios';
import { showToast,showLoadingToast,closeToast } from 'vant';
import store from '@/store/index';
import { computed } from 'vue';



const mstore:any = store;



// 将字符串转换为 Base64 编码
export function base64EncodeUnicode(str: string) {
    const encoder = new TextEncoder();
    const utf8Array = encoder.encode(str);
    let binaryString = "";
    utf8Array.forEach(byte => {
        binaryString += String.fromCharCode(byte);
    });
    return btoa(binaryString) as any;
}

// 解码 Base64 字符串为包含非 Latin1 字符的字符串
export function base64DecodeUnicode(base64: string) {
    if (!base64) return null as any;

    const binaryString = atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(bytes) as any;
}

// URL地址解析
export function getJsUrl (url: any) {
    if(!url || !url.includes('?')){
        return;
    };

    var str, parastr;
    var array: any = []
    str = location.href;
    parastr = str.split("?")[ 1 ];
    var arr = parastr.split("&");
    for (var i = 0; i < arr.length; i++) {
        array[ arr[ i ].split("=")[ 0 ] ] = arr[ i ].split("=")[ 1 ];
    }
    return array;
}

// 获取channel和fid和自己最终的channel名称
export const handleChannel = () => {
    const url_data:any = getJsUrl(location.href);
    let channel:any = '';
    let fid:any = '';
    let fbc:any = '';
    let fbp:any = '';

    channel = sessionStorage.getItem('channel');
    fid = sessionStorage.getItem('fid');
    fbc = sessionStorage.getItem('fbc');
    fbp = sessionStorage.getItem('fbp');
  
    if(!channel){
      channel = url_data?.channel;
    };
    if(!fid){
      fid = url_data?.fid;
    };
    if(!fbc){
      fbc = url_data?.fbc;
    };
    if(!fbp){
      fbp = url_data?.fbp;
    };
  
    // 储存
    if(channel){
      sessionStorage.setItem('channel',channel);
    };
    if(fid){
      sessionStorage.setItem('fid',fid);
    };
    if(fbc){
      sessionStorage.setItem('fbc',fbc);
    };
    if(fbp){
      sessionStorage.setItem('fbp',fbp);
    };
  
    // 储存自己的channel
    let os = getDeviceOS();
    if(channel){
        sessionStorage.setItem('m_channel',`${os}_${channel}`);
    }
    if(fid){
        sessionStorage.setItem('m_channel',`promoter_${os}_organic`);
    }
    if(channel && fid){
        sessionStorage.setItem('m_channel',`promoter_${os}_${channel}`);
    }
    if(channel && !fid){
        sessionStorage.setItem('m_channel',`${os}_${channel}`);
    }
    if(!channel && fid){
        sessionStorage.setItem('m_channel',`promoter_${os}_organic`);
    }
    if(!channel && !fid){
        sessionStorage.setItem('m_channel',`${os}_organic`);
    }
}

// 获取真实的channel/fid/fbc/fbp信息
export const getRealChannelInfo = () => {
    let channel = sessionStorage.getItem('channel');
    let fid = sessionStorage.getItem('fid');
    let fbc = sessionStorage.getItem('fbc');
    let fbp = sessionStorage.getItem('fbp');
    
    let copy_text = '';

    if(channel){
        copy_text += copy_text ? `&channel=${channel}` : `channel=${channel}`;
    };

    if(fid){
        copy_text += copy_text ? `&fid=${fid}` : `fid=${fid}`;
    };

    // if(fbc){
    //     copy_text += copy_text ? `&fbc=${fbc}` : `fbc=${fbc}`;
    // };

    // if(fbp){
    //     copy_text += copy_text ? `&fbp=${fbp}` : `fbp=${fbp}`;
    // };

    return copy_text;
}

// 追踪SDK上传channel和fid
export function uploadTrackSDK() {
    if(!window.location.search.substring(1).includes('channel') && !window.location.search.substring(1).includes('fid')){
        return;
    };

    let data = {
        pageUrl: location.href,
        refer: document.referrer,
        params: window.location.search.substring(1),
        app_key: 'H4PprcD19MAupBNLUIKvEsNAU8nmJtZA',
    };
    let jsonVar = {
        m: "2",
        p: JSON.stringify(data),
    };
    let ajaxObj = new XMLHttpRequest();
    ajaxObj.open("POST", "https://www.bigdomin.tel/getijjiu");
    ajaxObj.setRequestHeader("Content-type", "application/json");
    let str = JSON.stringify(jsonVar);
    ajaxObj.send(str);
    ajaxObj.onreadystatechange = function () {};
}

// 获取追踪SDK的channel和fid
export function getTrackSDK() {
    if(sessionStorage.getItem('channel') || sessionStorage.getItem('fid')){
        return;
    };

    let key = "MiliRisk20230518";
    let iv = "MiliRisk20230518";

    let data = {
        platform: 'ios',
        app_key: 'H4PprcD19MAupBNLUIKvEsNAU8nmJtZA',
        app_id: 'com.h5.lobby',
        app_version: '1.0.0',
        ex_data: {
            UAWeb: window.navigator.userAgent
        }
    };

    let post_data = {
        p: JSON.stringify(data),
        m: '4'
    };

    let jsonString = JSON.stringify(post_data);
    let sendText = _encryptText(jsonString, key, iv);

    const request = new XMLHttpRequest();
    request.open("POST", 'https://www.byytrnq.store/open/v1/risk/api', true);
    request.setRequestHeader("Content-Type", "application/json");
    request.send(sendText);
    request.onreadystatechange = () => {
        if (request.readyState === 4 && request.status === 200) {
            let res = JSON.parse(request.responseText);
            let parse_res = JSON.parse(_decryptText(res.data, key, iv) || '{}');
            console.log('获取追踪SDK信息',parse_res);

            // 储存
            if(parse_res?.EXTDATA?.channel){
                sessionStorage.setItem('channel',parse_res.EXTDATA.channel);
            };
            if(parse_res?.EXTDATA?.fid){
                sessionStorage.setItem('fid',parse_res.EXTDATA.fid);
            };

            // 储存自己的channel
            let os = getDeviceOS();
            let channel = sessionStorage.getItem('channel');
            let fid = sessionStorage.getItem('fid');
            if(channel){
                sessionStorage.setItem('m_channel',`${os}_${channel}`);
            };
            if(fid){
                sessionStorage.setItem('m_channel',`promoter_${os}_organic`);
            };
            if(channel && fid){
                sessionStorage.setItem('m_channel',`promoter_${os}_${channel}`);
            };
            if(channel && !fid){
                sessionStorage.setItem('m_channel',`${os}_${channel}`);
            };
            if(!channel && fid){
                sessionStorage.setItem('m_channel',`promoter_${os}_organic`);
            };
            if(!channel && !fid){
                sessionStorage.setItem('m_channel',`${os}_organic`);
            };
        };
    };
}

// 追踪SDK参数加密
function _encryptText (text: string,key: string, iv: string) {
    try {
        var cipherText = window["CryptoJS"].AES.encrypt(
            window["CryptoJS"].enc.Utf8.parse(text),
            window["CryptoJS"].enc.Utf8.parse(key), {
            iv: window["CryptoJS"].enc.Utf8.parse(iv),
            mode: window["CryptoJS"].mode.CBC,
            padding: window["CryptoJS"].pad.Pkcs7
        });
        return cipherText.toString();
    }catch (e) {
        console.error("追踪SDK参数加密:_encryptText:", e && JSON.stringify(e));
    };
    return "";
}

// 追踪SDK参数解密
function _decryptText (cipherText: string,key: string, iv: string) {
    var decrypted = window["CryptoJS"].AES.decrypt(
        cipherText,
        window["CryptoJS"].enc.Utf8.parse(key),
        {
            iv: window["CryptoJS"].enc.Utf8.parse(iv),
            mode: window["CryptoJS"].mode.CBC,
            padding: window["CryptoJS"].pad.Pkcs7
        }
    );
    return decrypted.toString(window["CryptoJS"].enc.Utf8);
}

// 获取系统
export function getDeviceOS() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1) {
        return 'ios';
    } else if (userAgent.indexOf('android') > -1) {
        return 'android';
    }
    return 'unknown'; // 如果无法判断则返回unknown
}

// 复制
export function copy (data: any) {
    let OrderNumber = data;
    let newInput = document.createElement("input");
    newInput.value = OrderNumber;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("Copy");
    newInput.remove();
}

// 引入本地图片
export function importImage (url: any) {
    return new URL(url, import.meta.url).href
}

// 时间戳转本地时间
export function getLocalTime (nS: any) {
    let timestamp;
    
    // 判断时间戳的位数
    if (nS.toString().length === 13) {
        // 13位时间戳（毫秒）
        timestamp = parseInt(nS);
    } else if (nS.toString().length === 10) {
        // 10位时间戳（秒），转换为毫秒
        timestamp = parseInt(nS) * 1000;
    } else {
        throw new Error('Unsupported timestamp format');
    }

    return new Date(timestamp).toLocaleString().replace(/:\d{1,2}$/, ' ');
}

// 时间戳转月和日并且返回出后七天日期
export function formatDate7day(nS:any):any {
    let timestamp;

    // 判断时间戳的位数
    if (nS.toString().length === 13) {
        // 13位时间戳（毫秒）
        timestamp = parseInt(nS);
    } else if (nS.toString().length === 10) {
        // 10位时间戳（秒），转换为毫秒
        timestamp = parseInt(nS) * 1000;
    } else {
        throw new Error('Unsupported timestamp format');
    }

    // 创建日期对象
    const date = new Date(timestamp);

    // 格式化为 MM-DD
    const formatDate = (date:any) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}/${day}`;
    };

    const start = formatDate(date); // 当前日期
    const endDate = new Date(date);
    endDate.setDate(date.getDate() - 7); // 加七天
    const end = formatDate(endDate); // 七天后的日期

    return {
        start,
        end
    };
}

// ieme
export function getImei() {
    let {country} = getConfig();
    let p_number = base64DecodeUnicode(localStorage.getItem("p_number") as string);
    let imei = base64DecodeUnicode(localStorage.getItem("uniimei") as string);
    if(imei){
        return country + p_number + imei;
    };
    imei = "self" + Date.now() + "_" + Math.random();
    localStorage.setItem("uniimei",base64EncodeUnicode(imei));
    return country + p_number + imei;
};

// 获取配置
export function getConfig() {
    let config = JSON.parse(base64DecodeUnicode(localStorage.getItem('config') as string));
    if(config){
        return {...config};
    }else{
        return undefined;
    }
}

// 设置全局配置
export function saveConfig(value:any){
    // value = 'TH';
    let is_has = false;
    let config_obj:any = config;
    for (let key in config) {
      if (config.hasOwnProperty(key)) {
        if (config[key].country === value || config[key].phoneAreaCode === value) {
            is_has = true;
            config_obj = config[key];
        }
      }
    }
    // 兼容没找到,默认越南
    if(!is_has){
        if(process.env.VUE_APP_CUSTOM_ENV === 'dev' || process.env.VUE_APP_CUSTOM_ENV === 'test'){
            config_obj = config[84];
        }else{
            config_obj = config[255];
        }
    }
    localStorage.setItem('config',base64EncodeUnicode(JSON.stringify(config_obj)));
}

// 获取登录信息
export function getLoginInfo() {
    let loginInfo = JSON.parse(base64DecodeUnicode(localStorage.getItem('login_info') as string));
    if(loginInfo){
        return {...loginInfo};
    }else{
        return {};
    }
}

// 获取区服信息
export function getZoneInfo() {
    let zoneInfo = JSON.parse(base64DecodeUnicode(localStorage.getItem('zone_info') as string));
    if(zoneInfo){
        return {...zoneInfo};
    }else{
        return {};
    }
}

// 获取登录验证信息
export function getLoginChcekInfo() {
    let login_chcek_info = JSON.parse(base64DecodeUnicode(localStorage.getItem('login_chcek_info') as string));
    if(login_chcek_info){
        return {...login_chcek_info};
    }else{
        return {};
    }
}

// json转二进制
export function jsonToArrayBuffer(message:any) {
    let str = JSON.stringify(message);
    let u8 = LobbyCompressUtil.encodeUTF8(str);
    let bytes = LobbyCompressUtil.compressByType(u8, 1)
    let byteArray = new LobbyEgretByte.ByteArray()
    byteArray.writeBytes(bytes, 0, bytes.byteLength);
    byteArray.position = 0;
    return byteArray.buffer;
}

// 二进制转json
export function arrayBufferToJson(meaasge:any): any {
    let _readByte = new LobbyEgretByte.ByteArray();
    _readByte._writeUint8Array(new Uint8Array(meaasge));

    let bytes = new LobbyEgretByte.ByteArray();

    _readByte.position = 0;
    _readByte.readBytes(bytes, 0, 0);
    _readByte.clear();
    let u8 = new Uint8Array(bytes.buffer);
    let retStr = LobbyCompressUtil.uncompressByType(u8, 1);

    let data = JSON.parse(retStr);
    
    return data;
}

// 处理金币展示
export function goldFormat(gold:any){

    if(!gold && gold !== 0){
        return 0;
    };

    // 如果 gold <= 1000，进行不四舍五入保留两位小数的处理
    if (gold <= 1000) {
        const strValue = gold.toString();
        const parts = strValue.split('.');
        if (parts.length === 1) {
            if(gold === 0){
                gold = 0;
            }
            // else{
            //     gold = parts[0] + '.00';
            // };
        }else{
            const integerPart = parts[0];
            const decimalPart = parts[1].slice(0, 2);
            gold = integerPart + '.' + decimalPart;
        };
    }

    let { country } = getConfig();

    const countriesWithoutK = ['TH', 'IN', 'MY', 'KE'];
    const countriesWithTwoDecimals = ['TH', 'IN', 'MY'];
    let formattedBalance = gold;

    if (countriesWithTwoDecimals.includes(country)) {
        const factor = Math.pow(10, 2);
        formattedBalance = Math.floor(formattedBalance * factor) / factor;
        formattedBalance = formattedBalance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        // 更准确地检查小数部分是否为 ".00"
        const formattedStr = formattedBalance.toString();
        if (formattedStr.endsWith('.00')) {
            formattedBalance = formattedStr.slice(0, -3);
        }
    } else {
        formattedBalance = formattedBalance.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    if (gold <= 1000 || countriesWithoutK.includes(country)) {
        return formattedBalance;
    }else{
        if (gold > 1000) {
            formattedBalance = gold / 1000;
            let handle_balance = formattedBalance.toString();
            if (handle_balance.indexOf('.') !== -1) {
                return handle_balance.slice(0, handle_balance.indexOf('.') + 3) + 'k';
            }else {
                return formattedBalance + 'k';
            }
        }
    }
}

// 处理金币展示 不展示千分位
export function goldFormatNoThousand(gold:any){
    let { country } = getConfig();

    const countriesWithTwoDecimals = ['TH', 'IN', 'MY'];

    let formattedBalance = gold;

    if (countriesWithTwoDecimals.includes(country)) {
        const factor = Math.pow(10, 2);
        formattedBalance = Math.floor(formattedBalance * factor) / factor;
    } else {
        formattedBalance = Math.floor(formattedBalance);
    }

    return formattedBalance;
}

// 展示千分位处理
export function thousandFormat(gold:any){
    if(!gold){
        return 0;
    };
    return gold.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

// 联系客服
export function contactService() {
    showLoadingToast({
        message: '',
        forbidClick: true,
        duration: 0
    });

    let { appKey,country } = getConfig();
    let {uid} = getLoginInfo();
    let url:any = undefined;

    if(process.env.VUE_APP_CUSTOM_ENV === 'dev' || process.env.VUE_APP_CUSTOM_ENV === 'test'){
        url = 'https://testgb.notice.facegaclub.com/pay/echo';
    }else{
        url = url_config.EchoUrl.url;
    }

    axios.get(url,{
        params:{
            do: 'getServiceUrl',
            uid: uid ? uid : 0,
            imei: getImei(),
            appKey: uid ? undefined : appKey,
            country: uid ? undefined : country
        }
    }).then((res:any)=>{
        console.log('联系客服',res);
        closeToast();
        let { serviceUrl,error } = res.data;
        if(serviceUrl){
            location.href = serviceUrl;
        }else if(error){
            showToast('error');
        };
    });
}

// 设置最爱玩的3款游戏
export function setFavoriteGame(game_item: any) {
    let favorite_games_str = localStorage.getItem('favorite_games');
    let favorite_games: any[] = favorite_games_str? JSON.parse(base64DecodeUnicode(favorite_games_str)) : [];
  
    const existingGameIndex = favorite_games.findIndex(game => game.game_id === game_item.game_id);
    if (existingGameIndex === -1) {
        favorite_games.unshift(game_item);
        if (favorite_games.length > 3) {
            favorite_games = favorite_games.slice(0, 3);
        }
        localStorage.setItem('favorite_games', base64EncodeUnicode(JSON.stringify(favorite_games)));
    }

    //     let favorite_games:any = base64DecodeUnicode(localStorage.getItem('favorite_games') as string);
    //     console.log(JSON.parse(favorite_games));
    //     if(favorite_games){
    //         favorite_games = JSON.parse(favorite_games);
    //     }else{
    //         favorite_games = [];
    //     };

    //     favorite_games.unshift(game_item);
    //     if(favorite_games.length > 3){
    //         favorite_games = favorite_games.slice(0,3);
    //     };
    //     localStorage.setItem('favorite_games',base64EncodeUnicode(JSON.stringify(favorite_games)));
}

// 获取对应活动数据
export function getActiviteData(activity_id:any) {
    return mstore.state.user.activityinfo.find((item:any) => item.id === activity_id);
}

// 分享社交媒体
export function shareSocialMedia(platform:string,text:string,url:string) {
    if(platform === 'telegram'){
        window.open(`https://t.me/share/url?url=${url}&text=${text}`);
    }else if(platform === 'facebook'){
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`);
    }else if(platform === 'twitter'){
        window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`);
    }
}

// 获取分享的社交媒体的链接
export function getShareSocialMedia(platform:string,text:string,url:string) {
    let web_url = '';
    if(platform === 'telegram'){
        web_url = `https://t.me/share/url?url=${url}&text=${text}`
    }else if(platform === 'facebook'){
        web_url = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`
    }else if(platform === 'twitter'){
        web_url = `https://twitter.com/intent/tweet?url=${url}&text=${text}`
    }

    return web_url;
}

// 是否数字
export function isNumber(data:any): boolean {
    var str = (data + "").trim();
    var myReg = /^[0-9]+\.?[0-9]+?$/;
    if (myReg.test(str)) {
        return true;
    }
    var myReg = /^[0-9]*$/;
    if (myReg.test(str)) {
        return true;
    }
    else {
        return false;
    }
}

// 匹配返回字符串的动态值
export function matchString(str:any) {
    const regex = /\{([^}]+)\}/g;
    const matches = [];
    let match;

    while ((match = regex.exec(str)) !== null) {
        matches.push(match[1]);
    };

    return matches;
}

// 处理货币选择列表图片显示
export const onHandleCurrencyIcon = (country:string) => {
    switch(country){
        case 'VN':
            return new URL('@/assets/image/VN.png',import.meta.url).href;
        case 'TH':
            return new URL('@/assets/image/TH.png',import.meta.url).href;
        case 'PH':
            return new URL('@/assets/image/PH.png',import.meta.url).href;
        case 'KE':
            return new URL('@/assets/image/KE.png',import.meta.url).href;
        case 'TZ':
            return new URL('@/assets/image/TZ.png',import.meta.url).href;
    }
}

// 是否登录
export const isLogin = () => {
    let is_login = computed(() => {
        return mstore.state.user.userinfo?.userInfo?.phonenumber;
    });

    return is_login.value;
}

// 获取用户信息
export const getUserInfo = () => {
    let user_info = computed(() => {
        return mstore.state.user.userinfo;
    });

    return user_info.value;
};

// 以注册时间为准，分别获取是否在不同的时间内
export function checkRegistrationTime(currentTime:number) {
    if(localStorage.getItem('register_time')){
        const registerTime = parseInt(localStorage.getItem('register_time') as string, 10);
        const timeDiff = currentTime - registerTime; // 时间差，单位为毫秒

        const oneDayInMs = 24 * 60 * 60 * 1000; // 24小时的毫秒数

        const result = {
            within24Hours: timeDiff < oneDayInMs,
            secondDay: timeDiff >= oneDayInMs && timeDiff < 2 * oneDayInMs,
            thirdDay: timeDiff >= 2 * oneDayInMs && timeDiff < 3 * oneDayInMs,
            seventhDay: timeDiff >= 3 * oneDayInMs && timeDiff < 7 * oneDayInMs,
            fourteenthDay: timeDiff >= 7 * oneDayInMs && timeDiff < 14 * oneDayInMs,
        };

        return result;
    }else{
        const result = {
            within24Hours: false,
            secondDay: false,
            thirdDay: false,
            seventhDay: false,
            fourteenthDay: false,
        };
        return result;
    }
}

// 数字精度问题
export function HandleNumericalAccuracy(number: number) {
    if(!number){
        return 0;
    };

    let result = number.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });

    return result;
}