interface Config {
    tips: string;
    gameid: number;
    zoneid: number;
    lobbyid: number;
    phoneAreaCode: number;
    country: string;
    language: string;
    appKey: string;
    login_url: string;
    areaService: string;
}



const product_data = {
    84:{
        'tips':'越南',
        'alias': 'VN1',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 84,
        'country': 'VN',
        'language': 'vn',
        'appKey': 'kTYtvJElK4x1KJmmK9URvYjdKYQ3dfF0',
        'login_url': 'https://idgb.login.facegaclub.com/httplogin',
        'areaService': 'idgb',
        'monetaryUnit': "₫",
        'headCoinUnit': "₫",
        'coinUtil': "VND",
        'invite_link': "https://www.7win.game/home"
    },
    255:{
        'tips':'坦桑尼亚',
        'alias': 'TZ1',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 255,
        'country': 'TZ',
        'language': 'en',
        'appKey': 'N2Ft6Cb7R4VreFS9hhL5OA8kAuWMGTtn',
        'login_url': 'https://idgb.login.facegaclub.com/httplogin',
        'areaService': 'idgb',
        'monetaryUnit': "TZS",
        'headCoinUnit': "T",
        'coinUtil': "TZS",
        'invite_link': "https://www.7win.game/home"
    },
    // 63:{
    //     'tips':'菲律宾',
    //     'alias': 'PH1',
    //     'gameid': 8001,
    //     'zoneid': 1001,
    //     'lobbyid': 8001,
    //     'phoneAreaCode': 63,
    //     'country': 'PH',
    //     'language': 'en',
    //     'appKey': 'XxBmpTo0nGQuiqBA6XEEvQyQD1C95CAj',
    //     'login_url': 'https://idgb.login.facegaclub.com/httplogin',
    //     'areaService': 'idgb',
    //     'monetaryUnit': "₱",
    //     'headCoinUnit': "₱",
    //     'coinUtil': "PHP",
    //     'invite_link': "https://www.7win.game/home"
    // },
    66:{
        'tips':'泰国',
        'alias': 'TZ1',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 66,
        'country': 'TH',
        'language': 'thai',
        'appKey': 'olLVIS9nXis2TbzsUAFslUv9LfByDr0q',
        'login_url': 'https://idgb.login.facegaclub.com/httplogin',
        'areaService': 'idgb',
        'monetaryUnit': "฿",
        'headCoinUnit': "฿",
        'coinUtil': "THB",
        'invite_link': "https://www.7win.game/home"
    },
    254:{
        'tips':'肯尼亚',
        'alias': 'KE1',
        'gameid': 8001,
        'zoneid': 1001,
        'lobbyid': 8001,
        'phoneAreaCode': 254,
        'country': 'KE',
        'language': 'en',
        'appKey': 'RIguSIs277hUJglXV9nXxLDVg4S1Bvl9',
        'login_url': 'https://idgb.login.facegaclub.com/httplogin',
        'areaService': 'idgb',
        'monetaryUnit': "KES",
        'headCoinUnit': "K",
        'coinUtil': "KES",
        'invite_link': "https://www.7win.game/home"
    }
}

const test_data = {
    84:{
        'tips':'越南',
        'alias': 'VN1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 84,
        'country': 'VN',
        'language': 'vn',
        'appKey': 'lXcASYJrRuZhaEjqTmVansWmsOGvNdvp',
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "₫",
        'headCoinUnit': "₫",
        'coinUtil': "VND",
        'invite_link': "https://testh5.7win.game/home"
    },
    255:{
        'tips':'坦桑尼亚',
        'alias': 'TZ1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 255,
        'country': 'TZ',
        'language': 'en',
        'appKey': 'TEWcFM6UbVWAj8uCMBtiTTCZuJv3Nq9i',
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "TZS",
        'headCoinUnit': "T",
        'coinUtil': "TZS",
        'invite_link': "https://testh5.7win.game/home"
    },
    // 63:{
    //     'tips':'菲律宾',
    //     'alias': 'PH1',
    //     'gameid': 8001,
    //     'zoneid': 1003,
    //     'lobbyid': 8001,
    //     'phoneAreaCode': 63,
    //     'country': 'PH',
    //     'language': 'en',
    //     'appKey': 'gcyuhHBwbn11Zyvn22gdSFV55WeTstY2',
    //     'login_url': 'https://test.login.facegaclub.com/httplogin',
    //     'areaService': 'flbgb',
    //     'monetaryUnit': "₱",
    //     'headCoinUnit': "₱",
    //     'coinUtil': "PHP",
    //     'invite_link': "https://testh5.7win.game/home"
    // },
    66:{
        'tips':'泰国',
        'alias': 'TH1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 66,
        'country': 'TH',
        'language': 'thai',
        'appKey': 'Z3IpeeKRBFOvJm5niPrwUGsgF3A8QWDR',
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "₱",
        'headCoinUnit': "₱",
        'coinUtil': "THB",
        'invite_link': "https://testh5.7win.game/home"
    },
    254:{
        'tips':'肯尼亚',
        'alias': 'KE1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 254,
        'country': 'KE',
        'language': 'en',
        'appKey': 'PqdUxFcHfreGVyrj9D6BwkyhXGR54AUW',
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "KES",
        'headCoinUnit': "K",
        'coinUtil': "KES",
        'invite_link': "https://testh5.7win.game/home"
    }
}

const dev_data = {
    84:{
        'tips':'越南',
        'alias': 'VN1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 84,
        'country': 'VN',
        'language': 'vn',
        'appKey': 'lXcASYJrRuZhaEjqTmVansWmsOGvNdvp',
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "₫",
        'headCoinUnit': "₫",
        'coinUtil': "VND",
        'invite_link': "http://192.168.0.116:8080/home"
    },
    255:{
        'tips':'坦桑尼亚',
        'alias': 'TZ1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 255,
        'country': 'TZ',
        'language': 'en',
        'appKey': 'TEWcFM6UbVWAj8uCMBtiTTCZuJv3Nq9i',   // lXcASYJrRuZhaEjqTmVansWmsOGvNdvp
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "TZS",
        'headCoinUnit': "T",
        'coinUtil': "TZS",
        'invite_link': "http://192.168.0.116:8080/home"
    },
    // 63:{
    //     'tips':'菲律宾',
    //     'alias': 'PH1',
    //     'gameid': 8001,
    //     'zoneid': 1003,
    //     'lobbyid': 8001,
    //     'phoneAreaCode': 63,
    //     'country': 'PH',
    //     'language': 'en',
    //     'appKey': 'gcyuhHBwbn11Zyvn22gdSFV55WeTstY2',   // lXcASYJrRuZhaEjqTmVansWmsOGvNdvp
    //     'login_url': 'https://test.login.facegaclub.com/httplogin',
    //     'areaService': 'flbgb',
    //     'monetaryUnit': "₱",
    //     'headCoinUnit': "₱",
    //     'coinUtil': "PHP",
    //     'invite_link': "http://192.168.0.116:8080/home"
    // },
    66:{
        'tips':'泰国',
        'alias': 'TH1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 66,
        'country': 'TH',
        'language': 'thai',
        'appKey': 'Z3IpeeKRBFOvJm5niPrwUGsgF3A8QWDR',   // lXcASYJrRuZhaEjqTmVansWmsOGvNdvp
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "฿",
        'headCoinUnit': "฿",
        'coinUtil': "THB",
        'invite_link': "http://192.168.0.116:8080/home"
    },
    254:{
        'tips':'肯尼亚',
        'alias': 'KE1',
        'gameid': 8001,
        'zoneid': 1003,
        'lobbyid': 8001,
        'phoneAreaCode': 254,
        'country': 'KE',
        'language': 'en',
        'appKey': 'PqdUxFcHfreGVyrj9D6BwkyhXGR54AUW',   // lXcASYJrRuZhaEjqTmVansWmsOGvNdvp
        'login_url': 'https://test.login.facegaclub.com/httplogin',
        'areaService': 'flbgb',
        'monetaryUnit': "KES",
        'headCoinUnit': "K",
        'coinUtil': "KES",
        'invite_link': "http://192.168.0.116:8080/home"
    }
}

export const config:any = process.env.VUE_APP_CUSTOM_ENV === 'dev' ? dev_data : process.env.VUE_APP_CUSTOM_ENV === 'test' ? test_data : product_data;
